import {
    JsonHubProtocol,
    HttpTransportType,
    HubConnectionBuilder,
    LogLevel
  } from '@aspnet/signalr';
  import { ActionTypes, Spreads } from '../Constants';
  import { chooseSpread, chooseCard, turnCard, redraw, shuffleCards } from '../Actions'
  
  const sendToServer = (connection, identifier, action) => {
    if (action.silent) return;

    let cmd = null;
    switch (action.type) {
      case ActionTypes.TURN_CARD:
        cmd = {
          $type : "TarotCommands.TurnCard, TarotCommands",
          id: identifier,
          fileName : action.card.card.filename,
          showFront : true
        };
        break;
      
      case ActionTypes.REDRAW:
        cmd = {
          $type : "TarotCommands.Redraw, TarotCommands",
          id: identifier
        };
        break;
      case ActionTypes.SHUFFLE:
        cmd = {
          $type : "TarotCommands.Shuffle, TarotCommands",
          id: identifier      
        };
        break;
      case ActionTypes.CHOOSE_CARD:
        cmd = {
          $type : "TarotCommands.ChooseCard, TarotCommands",
          id: identifier,
          fileName : action.card.card.filename
        };
        break;
      case ActionTypes.CHOOSE_SPREAD:
        cmd = {
          $type : "TarotCommands.ChooseSpread, TarotCommands",
          id: identifier,
          spread : action.spread.id
        };        
        break;
    }
    if (cmd != null) {
      connection.invoke("SendTarotCommand", cmd);
    }
  };

  const receivedAction = (dispatch, state, action) => {
    if (action.id != state.ioReducer.identifier) {
      switch (action.commandType) {
        case ActionTypes.CHOOSE_SPREAD:
          dispatch(chooseSpread(Spreads[action.spread], true));
          break;
          
        case ActionTypes.CHOOSE_CARD:
        {
          //only filename is known, so find the card from state
          let idx = state.cardlayer.hand.findIndex(x => x != null && action.fileName === x.card.filename);
          if (idx > -1) {
            let c = state.cardlayer.hand[idx];
            dispatch(chooseCard(c, true));
          }
          break;
        }

        case ActionTypes.TURN_CARD:
        {
          let c = null;
          let idx = state.cardlayer.hand.findIndex(x => x != null && action.fileName === x.card.filename);
          if (idx > -1) {
            c = state.cardlayer.hand[idx];
          } else {
            idx = state.cardlayer.spreadLayout.cards.findIndex(x => x != null && action.fileName === x.card.filename);
            c = state.cardlayer.spreadLayout.cards[idx];
          }
          if (c != null) {
            dispatch(turnCard(c, true));
          }        
          break;
        }

        case ActionTypes.REDRAW:
          dispatch(redraw(true));
          break;

        case ActionTypes.SHUFFLE:
          dispatch(shuffleCards(true));
          break;
      }
    }
  };

  const SignalRMiddleware = ({ dispatch, getState }) => {
    let nonSend = [];
    let connected = false;
    
    let connection = new HubConnectionBuilder().withUrl("https://api.yourtarotsite.com/tarot").build();
    connection
      .start()
      .then(() => {
        connected = true;
        nonSend.forEach(action => {
          sendToServer(connection, getState().ioReducer.identifier, action);  
        });        
      })
      .catch(err => console.log(['Error while establishing connection :(', err]));
    
    connection.on("tarotCommand", cmd => receivedAction(dispatch, getState(), cmd));
        
    return next => async (action) => {
      if (!connected) {
        nonSend.push(action);
      } else {
        sendToServer(connection, getState().ioReducer.identifier, action);
      }

      return next(action);
    }
  };
/*

import * as signalR from '@aspnet/signalr';

/*

let connection = new signalR.HubConnectionBuilder().withUrl("http://localhost:5000/tarot").build();
    this.setState({ hubConnection : connection }, () => {
      this.state.hubConnection
      

      this.state.hubConnection.on('tarotCommand', (nick, receivedMessage) => {
        const text = `${nick}: ${receivedMessage}`;
        const messages = this.state.messages.concat([text]);
        this.setState({ messages });
      });
    });

*/



    // // register signalR after the user logged in
    // if (action.type === USER_SIGNED_IN) {
    //   const urlRoot = (window.appConfig || {}).URL_ROOT;
    //   const connectionHub = `${urlRoot}/api/service/hub`;
  
    //   const protocol = new JsonHubProtocol();
  
    //   // let transport to fall back to to LongPolling if it needs to
    //   const transport = HttpTransportType.WebSockets | HttpTransportType.LongPolling;
  
    //   const options = {
    //     transport,
    //     logMessageContent: true,
    //     logger: LogLevel.Trace,
    //     accessTokenFactory: () => action.user.access_token
    //   };
  
    //   // create the connection instance
    //   const connection = new HubConnectionBuilder()
    //     .withUrl(connectionHub, options)
    //     .withHubProtocol(protocol)
    //     .build();
  
    //   // event handlers, you can use these to dispatch actions to update your Redux store
    //   connection.on('OperationProgress', onNotifReceived);
    //   connection.on('UploadProgress', onNotifReceived);
    //   connection.on('DownloadProgress', onNotifReceived);
  
    //   // re-establish the connection if connection dropped
    //   connection.onclose(() => setTimeout(startSignalRConnection(connection), 5000));
  
    //   startSignalRConnection(connection);
    // }
  
  //  return next(action);
  //};
  
  export default SignalRMiddleware;