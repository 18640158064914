import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import { faTimes, faRetweet, faBackspace, faSync } from '@fortawesome/free-solid-svg-icons';

library.add(
    faTimes,
    faRetweet,
    faBackspace,
    faSync
);