import React, { Component } from 'react';
import T from 'prop-types';
import './Card.scss';
import { faBackspace } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Card extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMouseOver: false
        }
    }

    onMouseOver = () => { this.setState({ isMouseOver: true }) }
    onMouseOut = () => { this.setState({ isMouseOver: false }) }

    onCardClick = () => {
        if (this.props.canChoose && this.props.onSelectCard) {
            this.props.onSelectCard(this.props.carddetails);
        }
    }
    onRemoveClick = () => {
        if (this.props.onDeselectCard) {
            this.props.onDeselectCard(this.props.carddetails);
        }
    }

    render() {
        const { isMouseOver } = this.state;

        let borderRadius = `${this.props.cardWidth / 25}px`;
        let borderPx = `${this.props.cardWidth / 30}px`;

        return (
            <div className={`card ${this.props.carddetails.faceUp ? '' : 'flipped'}`}
                style={{
                    position: 'absolute',
                    top: (isMouseOver ? this.props.hoverY : this.props.y) - this.props.cardHeight / 2,
                    left: (isMouseOver ? this.props.hoverX : this.props.x) - this.props.cardWidth / 2,
                    width: this.props.cardWidth,
                    height: this.props.cardHeight,
                    transform: `rotate(${this.props.rotation}rad)`,
                    zIndex : this.props.z,
                    transition: '1s'
                }}>
                <div
                    onClick={this.onCardClick}
                    onMouseOver={this.onMouseOver}
                    onMouseOut={this.onMouseOut}

                    className="flipper" style={{ width: this.props.cardWidth, height: this.props.cardHeight }}>
                    <div className="front">
                        <img src={`${this.props.directory}${this.props.carddetails.card.filename}.jpg`} alt={this.props.carddetails.card.nl.name}
                            style={{
                                borderRadius : borderRadius,
                                borderWidth : `${borderPx} ${borderPx} ${this.props.cardHeight / 14}px ${borderPx}`
                            }}
                        />
                        <h2 style={{ fontSize: this.props.cardHeight / 20, transition: '0.6s' }}>{this.props.carddetails.card.nl.name}</h2>
                    </div>
                    <div className="back">
                        <img src={`/Decks/back.jpg`} alt="" style={{borderRadius : borderRadius}} />
                    </div>
                </div>

                <div className="cardbuttons" style={{
                    top : this.props.cardHeight + this.props.iconSize / 3,
                    width : this.props.cardWidth,
                    zIndex : this.props.z,                    
                    opacity: this.props.isChosen ? 1 : 0
                }}>
                    {/* <FontAwesomeIcon className="undo" icon={faBackspace} onClick={this.onRemoveClick} style={{ color: 'red', transition: '0.6s', width : this.props.iconSize, height : 'auto' }} />                     */}
                </div>
            </div>
        );
    }
}

Card.propTypes = {
    carddetails: T.any,
    isChosen: T.bool,

    x: T.number,
    y: T.number,
    z: T.number,

    hoverX: T.number,
    hoverY: T.number,
    iconSize: T.number,

    rotation: T.number,

    cardWidth: T.number,
    cardHeight: T.number,
    canChoose : T.bool,
    directory: T.string,

    onSelectCard : T.func,
    onDeselectCard : T.func
};

export default Card;