import React from 'react';
import ReactDOM from 'react-dom';

import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './Reducers'

import './index.css';
import 'semantic-ui-css/semantic.min.css'

import App from './App';
import * as serviceWorker from './serviceWorker';
import './fontawesome';
import signalRMiddleware from './Middleware/SignalRMiddleware';
import Guid from 'guid';


//get from localdb or create an id

var request = indexedDB.open("YourTarot", 2);
request.onupgradeneeded = function(event) {
    var db = event.target.result;
    db.createObjectStore("ioData", { keyPath: "key" });
};

request.onsuccess = function(event) {
    let db = event.target.result;

    var transaction = db.transaction(["ioData"], "readwrite");
    var objectStore = transaction.objectStore("ioData");
    var idRequest = objectStore.get("identifier");

    idRequest.onsuccess = function(evt) {
        let id;
        if (idRequest.result) {
            id = idRequest.result.value;
        } else {
            id = Guid.create().value;
            objectStore.add({ key : "identifier", value : id });
        }

        const store = createStore(rootReducer, { cardlayer : undefined, ioReducer : { identifier: id } }, applyMiddleware(signalRMiddleware));
        var rootElement = document.getElementById('approot');
        ReactDOM.render(<Provider store={store}><App
            ref={(app) => {window.yourtarotApp = app} }
            spread={rootElement.getAttribute('data-spread')}
            overridefactor={rootElement.getAttribute('data-overridefactor')} /></Provider>, rootElement);
    };    
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
