import { DeckTypes } from './DeckTypes';

export const Decks = {
    vibrant : {
        directory: "Decks/Rider-Waite/",
        extension: "jpg",
        deckType: DeckTypes.TAROT
    },
    comic : {
        directory: "Decks/Rider-Waite-2/",
        extension: "jpg",
        deckType: DeckTypes.TAROT
    },
    fantasy : {
        directory: "Decks/ShadowScapes/",
        extension: "jpg",
        deckType: DeckTypes.TAROT
    }
}