import React, { Component } from 'react';
import { connect } from 'react-redux'
import T from 'prop-types';

import Spread from './Spread';
import Card from '../Display/Card';

import RestartControl from '../Display/RestartControl';
import ShuffleControl from '../Display/ShuffleControl';

import { Spreads, DeckCards, DeckTypes } from '../../Constants';
import { chooseCard, turnCard, returnCard, shuffleCards, redraw, showInformation } from '../../Actions'

import { Header, Button, Icon, Modal, Image, TransitionablePortal } from 'semantic-ui-react';


class Table extends Component {
    center = {};
    innerRadius = 0;

    state = {
        modalOpen : false
    }

    calculateCircleCenter(A,B,C)
    {
        var yDelta_a = B.y - A.y;
        var xDelta_a = B.x - A.x;
        var yDelta_b = C.y - B.y;
        var xDelta_b = C.x - B.x;

        var center = {};

        var aSlope = yDelta_a / xDelta_a;
        var bSlope = yDelta_b / xDelta_b;

        center.x = (aSlope*bSlope*(A.y - C.y) + bSlope*(A.x + B.x) - aSlope*(B.x+C.x) )/(2* (bSlope-aSlope) );
        center.y = -1*(center.x - (A.x+B.x)/2)/aSlope +  (A.y+B.y)/2;
        return center;
    }


    getCards(w, h, directory, cards, canChoose) {
        let swapXY = false;
        if (h > w) {
            var t = h;
            h = w;
            w = t;
            swapXY = true;
        }
        
        //get 3 points
        let leftY = h * 7 / 12;
        let halfW = w / 2;

        let cardHeight = h * 7 / 12; //educated guess
        let cardWidth = cardHeight * 2 / 3;

        let pA = { x: 0, y: leftY };
        let pB = { x: halfW - cardWidth / 2, y: h };
        let pC = { x: w, y: leftY };

        let center = this.calculateCircleCenter(pA, pB, pC);
        this.center = center;

        let cY = center.y;
        let cX = center.x;
        
        let cardcount = cards.length;
        
        let outerRadius = h + -1 * cY;
        let rPi = Math.asin ((halfW - cardWidth / 2) / outerRadius);

        let radius = outerRadius - cardHeight / 2;
        let radius2 = radius + cardHeight / 5;

        this.innerRadius = outerRadius - cardHeight * 1.05;

        let stepPi = rPi * 2 / (cardcount - 1);
        let startPi = Math.PI / 2 + rPi;

        if (swapXY) {
            stepPi *= -1;
            startPi = (1 / 2 - 9 / 80) * Math.PI;
        }

        let halfPi = Math.PI / 2;

        let cardComponents = [];
        for (let i = 0, j = startPi; i < cardcount; i++ , j -= stepPi) {
            let sin1 = Math.floor(Math.sin(j) * radius + cY);
            let cos1 = Math.floor(Math.cos(j) * radius + cX);

            let sin2 = Math.floor(Math.sin(j) * radius2 + cY);
            let cos2 = Math.floor(Math.cos(j) * radius2 + cX);

            if (cards[i] !== null) {
                if (swapXY) {
                    cardComponents.push(<Card key={cards[i].card.filename} directory={directory} isChosen={false} iconSize={cardWidth / 20} onSelectCard={this.props.onSelectCard} canChoose={canChoose} carddetails={cards[i]} z={i} x={sin1} y={cos1} hoverX={sin2} hoverY={cos2} rotation={-j} cardWidth={cardWidth} cardHeight={cardHeight} />)
                } else {
                    cardComponents.push(<Card key={cards[i].card.filename} directory={directory} isChosen={false} iconSize={cardWidth / 20} onSelectCard={this.props.onSelectCard} canChoose={canChoose} carddetails={cards[i]} z={i} x={cos1} y={sin1} hoverX={cos2} hoverY={sin2} rotation={j - halfPi} cardWidth={cardWidth} cardHeight={cardHeight} />)
                }
            }
        }
        return cardComponents;
    }

    getCardsOnSpread(x, y, w, h, directory, spread, spreadLayout) {
        if (spread === null) {
            return [];
        }

        let padding = h > w ? w * 0.1 : h * 0.1;
        let spreadWidth = spread.width;
        let spreadHeight = spread.height;

        //calculate ratios
        let fullWidth = w - 2 * padding;
        let fullHeight = h - 2 * padding;

        let factorX = spreadWidth / fullWidth;
        let factorY = spreadHeight / fullHeight;
        let factor;

        let left = x + padding;
        let top = y + padding;

        if (factorX < factorY) {
            //horizontal bars
            factor = factorY;
            left += (fullWidth - spreadWidth / factor) / 2;
        } else {
            //vertical bars
            factor = factorX;
            top += (fullHeight - spreadHeight / factor) / 2;
        }

        let cardComponents = [];
        let cardWidth = spread.cardWidth / factor;
        let cardHeight = spread.cardHeight / factor;
        spreadLayout.cards.forEach((c, i) => {
            if (c != null) {
                let x = left + spread.positions[i].centerX / factor;
                let y = top + spread.positions[i].centerY / factor;
                let rotation = spread.positions[i].rotation;
                cardComponents.push(<Card
                    key={c.card.filename}
                    onSelectCard={this.props.onTurnCard}
                    onDeselectCard={this.props.onDeselectCard}
                    isChosen={true}
                    iconSize={40 / factor}
                    canChoose={true}
                    directory={directory}
                    carddetails={c}
                    z={i}
                    x={x}
                    y={y}
                    hoverX={x}
                    hoverY={y}
                    rotation={rotation}
                    cardWidth={cardWidth}
                    cardHeight={cardHeight} />)
            }
        });
        return cardComponents;
    }

    getPile(hw, hh, x, y, sw, sh, directory, cards) {
        if (hh > hw) {
            var t = hh;
            hh = hw;
            hw = t;            
        }

        let cardcount = cards.length;
        let cardHeight = hh * 0.7; //educated guess
        let cardWidth = cardHeight * 2 / 3;
        let cardX = sw / 2 + x;
        let cardY = sh / 2 + y;

        let cardComponents = [];
        for (let i = 0; i < cardcount; i++) {
            let cx = cardX + (Math.random() - 0.5) * cardWidth * 0.2;
            let cy = cardY + (Math.random() - 0.5) * cardHeight * 0.2;
            let rot = (Math.random()- 0.5) / 25 * Math.PI * 2;

            cardComponents.push(<Card key={cards[i].card.filename} directory={directory} isChosen={false} iconSize={cardWidth / 20} carddetails={cards[i]} z={i} x={cx} y={cy} hoverX={cx} hoverY={cy} rotation={rot} cardWidth={cardWidth} cardHeight={cardHeight} />)
        }
        return cardComponents;
    }

    render() {
        let handWidth = 0;
        let handHeight = 0;

        let spreadX = 0;
        let spreadY = 0;
        let spreadWidth = 0;
        let spreadHeight = 0;
        
        handHeight = Math.floor(this.props.height / 3);
        if (handHeight < 100) handHeight = 100;
        if (handHeight > 200) handHeight = 200;

        handWidth = this.props.width;

        spreadY = handHeight;
        spreadWidth = handWidth;
        spreadHeight = this.props.height - handHeight;

        let cards = [];
        if (this.props.cardlayerState.spread !== null) {
            let canChoose = this.props.cardlayerState.spreadLayout.currentCard < this.props.cardlayerState.spread.positions.length;
            cards = this.getCards(handWidth, handHeight, this.props.cardlayerState.cardFronts, this.props.cardlayerState.hand, canChoose);
            cards = cards.concat(this.getCardsOnSpread(spreadX, spreadY, spreadWidth, spreadHeight, this.props.cardlayerState.cardFronts, this.props.cardlayerState.spread, this.props.cardlayerState.spreadLayout));
        } else {
            cards = this.getPile(handWidth, handHeight, spreadX, spreadY, spreadWidth, spreadHeight, this.props.cardlayerState.cardFronts, this.props.cardlayerState.hand);
        }
        cards.sort((a,b) => a.key - b.key);

        return (
            <div className="table">
                <TransitionablePortal open={this.props.cardlayerState.currentInfoCard != null}  transition={{ animation:"fade up", duration: 300 }}>
                <Modal
                    open={true}
                    onClose={this.props.onCloseInfo}>
                    {(this.props.cardlayerState.currentInfoCard != null) &&
                    <>
                    <Header content={this.props.cardlayerState.currentInfoCard.nl.name} textAlign='center' />
                    <Modal.Content image scrolling>
                        
                        <Image src={`${this.props.cardlayerState.cardFronts}${this.props.cardlayerState.currentInfoCard.filename}.jpg`} wrapped />

                        <Modal.Description style={{flex : '0 1 100%'}}>                            
                            <div dangerouslySetInnerHTML={{ __html: this.props.cardlayerState.currentInfoCard.nl.description.DEFAULT}}></div>
                        </Modal.Description>

                    </Modal.Content>

                    <Modal.Actions>
                        <Button basic color='red' onClick={this.props.onCloseInfo}>
                            <Icon name='remove' /> Sluiten
                        </Button>                        
                    </Modal.Actions>
                    </>
                    }
                </Modal>
                </TransitionablePortal>
                
                <RestartControl onClick={this.props.onRedraw} width={handWidth} height={handHeight} center={this.center} radius={this.innerRadius} />
                <ShuffleControl onClick={this.props.onShuffle}  width={handWidth} height={handHeight} center={this.center} radius={this.innerRadius} />
                <Spread x={spreadX} y={spreadY} width={spreadWidth} height={spreadHeight} spread={this.props.cardlayerState.spread} />
                {cards}
            </div>
        );
    }
}

Table.propTypes = {
    width: T.number,
    height: T.number,
    cardlayerState: T.any
}

const mapStateToProps = state => {
    return {
        cardlayerState: state.cardlayer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSelectCard : c => {
            dispatch(chooseCard(c));
            if (!c.faceUp) dispatch(turnCard(c));
            
            window.currentCardTimeout = setTimeout(() => dispatch(showInformation(c.card, true)), 1500);            
        },
        onCloseInfo : () => {
            dispatch(showInformation(null));            
        },

        onTurnCard : c => {
            if (!c.faceUp) dispatch(turnCard(c));
            else dispatch(showInformation(c.card, false));
        },

        onDeselectCard : c => {
            dispatch(returnCard(c));
        },
        onRedraw : c => {
            if (window.currentCardTimeout) {
                clearTimeout(window.currentCardTimeout);
            }

            dispatch(showInformation(null));
            dispatch(redraw());
        },
        onShuffle : c => {
            if (window.currentCardTimeout) {
                clearTimeout(window.currentCardTimeout);
            }

            dispatch(showInformation(null));
            dispatch(shuffleCards());
        }        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Table);