import React, { Component } from 'react';
import { connect } from 'react-redux'
import Table from './Components/Controller/Table';
import { chooseSpread, setIdentifier } from './Actions'
import { Spreads } from './Constants';

function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

class App extends Component {
  constructor() {
    super();
    this.state = {
      width: 600,
      height: 400
    }
  }

  resizeFunction = debounce(() => {
    let elWidth = document.getElementById('approot').clientWidth;
    let elHeight = elWidth * 2 / 3;
    if (this.props.overridefactor) {
      elHeight = elWidth * this.props.overridefactor;
    } 

    if (elHeight < 400) elHeight = 400;
    
    this.setState({ width: elWidth, height: elHeight });
  }, 100);

  updateDimensions() {
    this.resizeFunction();
  }

  componentDidMount() {
    this.resizeFunction();

    this.props.onChooseSpread( Spreads[this.props.spread] );
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {
    return (
      <div style={{ minHeight : this.state.height }}>
        <Table
          width={this.state.width}
          height={this.state.height} />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onChooseSpread: spread => {
          dispatch(chooseSpread(spread));
      }
  }
}

export default connect(null, mapDispatchToProps)(App);