import React, { Component } from 'react';
import T from 'prop-types';

import './Controls.scss';
import { faRandom } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ShuffleControl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMouseOver: false
        }
    }

    onMouseOver = () => { this.setState({ isMouseOver: true }) }
    onMouseOut = () => { this.setState({ isMouseOver: false }) }

    onClick = () => {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {
        const { isMouseOver } = this.state;
        let height = (this.props.center.y || 0) + this.props.radius;

        return (
            <>
            <svg 
                    
                    className="hand-controls"
                    style={{ position : 'absolute', left : this.props.width / 2, width : this.props.width / 2, height : height,
                    cursor: this.state.isMouseOver ? 'pointer' : 'auto' }}>
                <defs>
                    <radialGradient id="shuffle-gradient">
                        <stop offset="90%"   stopColor="rgba(255,255,255,0)"/>
                        <stop offset="100%" stopColor="rgba(114,75,156,0.5)"/>
                    </radialGradient>
                    <radialGradient id="shuffle-gradient-mo">
                        <stop offset="95%"   stopColor="rgba(255,255,255,0)"/>
                        <stop offset="100%" stopColor="rgba(114,75,156,0.5)"/>
                    </radialGradient>
                </defs>
                <circle
                onMouseOver={this.onMouseOver}
                onMouseOut={this.onMouseOut}
                onClick={this.onClick}
                cx={0} cy={this.props.center.y} r={this.props.radius} fill={this.state.isMouseOver ? 'url(#shuffle-gradient-mo)' : 'url(#shuffle-gradient)' }  />
            </svg>

            <FontAwesomeIcon className="hand-controls" icon={faRandom} style={{ 
                position: 'absolute',
                top : height * 0.25,
                height : height * 0.5,
                left : this.props.width / 2 + height * 0.5,
                width : 'auto',
                pointerEvents: 'none'
             }} />
            </>
        );
    }
}
//width={handWidth} height={handHeight} center={this.center} radius={this.innerRadius}
ShuffleControl.propTypes = {
    width : T.number,
    height : T.number,
    center : T.object,
    radius : T.number,
    onClick : T.func,
};

export default ShuffleControl;