export const ActionTypes = {
    TURN_CARD: 'TURN_CARD',
    CHOOSE_CARD: 'CHOOSE_CARD',
    RETURN_CARD: 'RETURN_CARD',    
    CHOOSE_SPREAD: 'CHOOSE_SPREAD',
    TURN_CARDS: 'TURN_CARDS',
    SHUFFLE: 'SHUFFLE',
    REDRAW: 'REDRAW',
    SHOWINFO : 'SHOWINFO',
    SETIDENTIFIER : 'SETIDENTIFIER'
}