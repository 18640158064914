import update from 'immutability-helper';
import { Decks, GuidanceFields, DeckTypes, DeckCards, Spreads, ActionTypes } from '../Constants';

function shuffle(array) {
    var m = array.length, t, i;
    while (m) {
        i = Math.floor(Math.random() * m--);
        t = array[m];
        array[m] = array[i];
        array[i] = t;
    }

    return array;
}

function getInitialState(guidanceField, decktype, selectedFronts) {
    //alle kaarten altijd vullen, zodat nextIdx makkelijk opgehaald kan
    return {
        spread: null,
        spreadLayout: null,
        guidanceField: guidanceField,
        decktype : decktype,
        cardDirectories : decktype.cardDirectories,
        cardFronts : decktype.cardDirectories[selectedFronts],
        hand: decktype.cards.map((c, i) => {
            return {
                faceUp: false,
                originalPosition: i,
                card: c
            };
        }),
        currentInfoCard: null
    }
}

function turnCard(state, action) {
    //first get the index of the card
    let idxToFlip = state.spreadLayout.cards.findIndex(x => action.card === x);
    if (idxToFlip > -1) {
        return update(state, {
            spreadLayout: { cards: { [idxToFlip]: { faceUp: { $apply: x => !x } } } }
        });
    } else {
        idxToFlip = state.hand.findIndex(x => action.card == x);
        if (idxToFlip > -1) {
            return update(state, {
                hand: { [idxToFlip]: { faceUp: { $apply: x => !x } } }
            });
        }
    }
    return state;
}

function turnCards(state) {
    //make object
    let l = state.spreadLayout.cards.length;
    let updateObject = { spreadLayout : {cards : { } } };
    for (let i = 0; i < l; i++) {
        if (state.spreadLayout.cards[i] != null) {
            updateObject.spreadLayout.cards[i] = { faceUp: {$set : true }  };
        }
    }
    return update(state, updateObject);
}

function returnCard(state, action) {
    let idxToReturn = state.spreadLayout.cards.findIndex(x => action.card === x);
    if (idxToReturn > -1) {
        var nextIdx = state.spreadLayout.cards.findIndex((x, i) => i < idxToReturn && x === null);
        if (nextIdx == -1) nextIdx = idxToReturn;

        return update(state, {
            spreadLayout: {
                currentCard: { $set: nextIdx },
                cards: { [idxToReturn]: { $set: null } }
            },
            hand: { [action.card.originalPosition]: { $set: action.card } }
        });
    }
    return state;
}

function chooseCard(state, action) {
    let idxToPlace = state.hand.findIndex(x => action.card === x);
    if (idxToPlace > -1) {
        var currentIdx = state.spreadLayout.currentCard;
        var nextIdx = state.spreadLayout.cards.findIndex((x, i) => i > currentIdx && x === null);
        if (nextIdx == -1) nextIdx = state.spreadLayout.cards.length;

        return update(state, {
            spreadLayout: {
                currentCard: { $set: nextIdx },
                cards: { [currentIdx]: { $set: action.card } }
            },
            hand: { [idxToPlace]: { $set: null } }
        });
    }
    return state;
}

function chooseSpread(state, action) {
    let spread = action.spread;
    return update(state, {
        spread: { $set: spread },
        spreadLayout: {
            $set: {
                currentCard: 0,
                cards: spread.positions.map(x => { return null })
            }
        },
        hand: {
            $set: shuffle(state.decktype.cards).map((c, i) => {
                return {
                    faceUp: false,
                    originalPosition: i,
                    card: c
                };
            })
        }
    });
}

function redraw(state, action) {
    var updateObject = {
        spreadLayout: {
            $set: {
                currentCard: 0,
                cards: state.spread.positions.map(x => { return null })
            }
        },
        hand: {  }
    }

    state.spreadLayout.cards.forEach(c => {
        if (c != null) {
            var newC = update(c, { faceUp: { $set :false } });
            updateObject.hand[c.originalPosition] = { $set: newC }
        }
    })

    return update(state, updateObject);
}

function shuffleCards(state, action) {
    return update(state, {
        spreadLayout: {
            $set: {
                currentCard: 0,
                cards: state.spread.positions.map(x => { return null })
            }
        },
        hand: {
            $set: shuffle(state.decktype.cards).map((c, i) => {
                return {
                    faceUp: false,
                    originalPosition: i,
                    card: c
                };
            })
        }
    });    
}

function showCardInformation(state, action) {
    let cardForInfo = action.card;

    if (action.autoShow && state.spread != Spreads.cardoftheday) {
        return state;
    } else {
        return update(state, {
            currentInfoCard: { $set: cardForInfo }
        });
    }
}

export default function cardlayer(
    state = getInitialState(GuidanceFields.DEFAULT, DeckCards[DeckTypes.TAROT], 'Radiant Rider Waite'),
    action
) {
    switch (action.type) {
        case ActionTypes.TURN_CARD:
            return turnCard(state, action);
        case ActionTypes.TURN_CARDS:
            return turnCards(state);
        case ActionTypes.RETURN_CARD:
            return returnCard(state, action);
        case ActionTypes.CHOOSE_CARD:
            return chooseCard(state, action);
        case ActionTypes.CHOOSE_SPREAD:
            return chooseSpread(state, action);
        case ActionTypes.REDRAW:
            return redraw(state, action);  
        case ActionTypes.SHUFFLE:
            return shuffleCards(state, action);
        case ActionTypes.SHOWINFO:
            return showCardInformation(state, action);        
        default:
            return state;
    }
}