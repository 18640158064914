import React, { Component } from 'react';
import T from 'prop-types';

import './Controls.scss';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class RestartControl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMouseOver: false
        }
    }

    onMouseOver = () => { this.setState({ isMouseOver: true }) }
    onMouseOut = () => { this.setState({ isMouseOver: false }) }

    onClick = () => {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {

        const { isMouseOver } = this.state;
        let height = (this.props.center.y || 0) + this.props.radius;

        return (
            <>
            <svg
            className="hand-controls" style={{ cursor: this.state.isMouseOver ? 'pointer' : 'auto', position : 'absolute', width : this.props.width / 2, height : height }}>
                <defs>
                    <radialGradient id="restart-gradient">
                        <stop offset="90%"  stopColor="rgba(255,255,255,0)"/>
                        <stop offset="100%" stopColor="rgba(114,75,156,0.5)"/>
                    </radialGradient>
                    <radialGradient id="restart-gradient-mo">
                        <stop offset="95%"   stopColor="rgba(255,255,255,0)"/>
                        <stop offset="100%" stopColor="rgba(114,75,156,0.5)"/>
                    </radialGradient>
                </defs>
                <circle
                onMouseOver={this.onMouseOver}
                onMouseOut={this.onMouseOut}
                onClick={this.onClick}
                cx={this.props.center.x } cy={this.props.center.y} r={this.props.radius} fill={this.state.isMouseOver ? 'url(#restart-gradient-mo)' : 'url(#restart-gradient)'} />
            </svg>
            
            <FontAwesomeIcon className="hand-controls" icon={faSync} style={{ 
                position: 'absolute',
                top : height * 0.25,
                height : height * 0.5,
                left : this.props.width / 2 - height,
                width : 'auto',
                pointerEvents: 'none'
             }} />
            </>
        );
    }
}

RestartControl.propTypes = {
    width : T.number,
    height : T.number,
    center : T.object,
    radius : T.number,
    onClick : T.func,
};

export default RestartControl;