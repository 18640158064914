import { DeckTypes } from './DeckTypes';

export const Spreads = {
    cardoftheday : {
        id : "cardoftheday",
        text : {
            nl : {
                title: "Dagkaart",
                description: "Welke kaart trek je vandaag."
            }
        },

        width : 400,
        height : 650,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,

        //cardChooser: Grid,
        //priceForConsult = 200,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    nl : "Dagkaart"
                },
                centerX : 200,
                centerY : 350,
                z : 1,
                rotation : 0
            }
        ]        
    },
    pair : {
        id : "pair",
        text : {
            nl : {
                title: "Advies bij twijfel",
                description: "Vind antwoord op je vraag"
            }
        },
        
        width : 900,
        height : 650,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    nl : "Doen"
                },
                centerX : 200,
                centerY : 350,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Laten"
                },
                centerX : 700,
                centerY : 350,
                z : 1,
                rotation : 0
            }
        ]        
    },
    pastpresentfuture : {
        id : "pastpresentfuture",
        text : {
            nl : {
                title: "Heden, verleden en toekomst",
                description: "Vind antwoord op je vraag"
            }
        },
        
        width : 1400,
        height : 650,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: false,
        onHomepage: true,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    nl : "Heden"
                },
                centerX : 700,
                centerY : 350,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Verleden"
                },
                centerX : 200,
                centerY : 350,
                z : 1,
                rotation : 0
            },            
            {
                title: {
                    nl : "Toekomst"
                },
                centerX : 1200,
                centerY : 350,
                z : 1,
                rotation : 0
            }
        ]        
    },
    bridge : {
        id : "bridge",
        text : {
            nl : {
                title: "Blik op de toekomst",
                description: "Vind antwoord op je vraag"
            }
        },
        
        width : 1400,
        height : 1050,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: false,
        onHomepage: true,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    nl : "Heden"
                },
                centerX : 200,
                centerY : 750,
                z : 1,
                rotation : 0
            },          
            {
                title: {
                    nl : "Toekomst"
                },
                centerX : 1200,
                centerY : 750,
                z : 1,
                rotation : 0
            },  
            {
                title: {
                    nl : "Actie"
                },
                centerX : 700,
                centerY : 350,
                z : 1,
                rotation : 0
            }
        ]        
    },
    conscience : {
        id : "conscience",
        text : {
            nl : {
                title: "Advies bij twijfel",
                description: "Vind antwoord op je vraag"
            }
        },
        
        width : 1100,
        height : 2050,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    nl : "Nu"
                },
                centerX : 200,
                centerY : 1050,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Bewuste"
                },
                centerX : 200,
                centerY : 350,
                
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Onbewuste"
                },
                centerX : 200,
                centerY : 1750,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Balansactie"
                },
                centerX : 900,
                centerY : 1050,
                z : 1,
                rotation : 0
            }
        ]        
    },
    square : {
        id : "square",
        text : {
            nl : {
                title: "Het vierkant",
                description: "Vind antwoord op je vraag"
            }
        },
        
        width : 900,
        height : 1350,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    nl : "Inbreng"
                },
                centerX : 200,
                centerY : 350,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Voordeel"
                },
                centerX : 700,
                centerY : 350,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Lastige"
                },
                centerX : 700,
                centerY : 1050,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    nl : "Mogelijkheden"
                },
                centerX : 200,
                centerY : 1050,
                z : 1,
                rotation : 0
            }
        ]        
    },
    celticcross : {
        id : "celticcross",
        text : {
            nl : {
                title: "Het Keltisch Kruis",
                description: "Vind antwoord op je vraag"
            }
        },
        
        width : 2200,
        height : 2750,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    
                },
                centerX : 800,
                centerY : 1050,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    
                },
                centerX : 800,
                centerY : 1200,
                z : 2,
                rotation : Math.PI * 0.5
            },
            {
                title: {
                    
                },
                centerX : 800,
                centerY : 1750,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    
                },
                centerX : 200,
                centerY : 1050,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    
                },
                centerX : 800,
                centerY : 350,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    
                },
                centerX : 1400,
                centerY : 1050,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    
                },
                centerX : 2000,
                centerY : 2450,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    
                },
                centerX : 2000,
                centerY : 1750,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    
                },
                centerX : 2000,
                centerY : 1050,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    
                },
                centerX : 2000,
                centerY : 350,
                z : 1,
                rotation : 0
            }
        ]        
    },
    director : {
        id : "director",
        text : {
            nl : {
                title: "Het Keltisch Kruis",
                description: "Vind antwoord op je vraag"
            }
        },
        
        width : 2900,
        height : 2050,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {
                    
                },
                centerX : 700,
                centerY : 1050,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    
                },
                centerX : 200,
                centerY : 350,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    
                },
                centerX : 700,
                centerY : 350,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    
                },
                centerX : 1200,
                centerY : 350,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    
                },
                centerX : 200,
                centerY : 1750,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    
                },
                centerX : 700,
                centerY : 1750,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    
                },
                centerX : 1200,
                centerY : 1750,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    
                },
                centerX : 1700,
                centerY : 1050,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    
                },
                centerX : 2200,
                centerY : 1050,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    
                },
                centerX : 2700,
                centerY : 1050,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    
                },
                centerX : 2700,
                centerY : 350,
                z : 1,
                rotation : 0
            }
        ]        
    },
    meintheworld : {
        id : "meintheworld",
        text : {
            nl : {
                title: "Ik, in Mijn Wereld",
                description: "Vind antwoord op je vraag"
            }
        },
        
        width : 2200,
        height : 3200,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: { },
                centerX : 1100,
                centerY : 1600,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 650,
                centerY : 1600,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 1100,
                centerY : 950,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 1550,
                centerY : 1600,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    
                },
                centerX : 1100,
                centerY : 2250,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    
                },
                centerX : 200,
                centerY : 1600,
                z : 1,
                rotation : 0
            },
            {
                title: {                    
                },
                centerX : 1100,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 2000,
                centerY : 1600,
                z : 1,
                rotation : 0
            },
            {
                title: {
                    
                },
                centerX : 1100,
                centerY : 2900,
                z : 1,
                rotation : 0
            }
        ]        
    },
    relations : {
        id : "relations",
        text : {
            nl : {
                title: "Zicht op relaties",
                description: "Vind antwoord op je vraag"
            }
        },
        
        width : 1600,
        height : 2600,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: { },
                centerX : 800,
                centerY : 1900,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 800,
                centerY : 1100,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 800,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 200,
                centerY : 2300,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 1400,
                centerY : 2300,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 200,
                centerY : 1500,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 1400,
                centerY : 1500,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 200,
                centerY : 700,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 1400,
                centerY : 700,
                z : 1,
                rotation : 0
            }
        ]        
    },
    meandrelations : {
        id : "meandrelations",
        text : {
            nl : {
                title: "Het vierkant",
                description: "Vind antwoord op je vraag"
            }
        },
        
        width : 2100,
        height : 2000,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: {  },
                centerX : 200,
                centerY : 1000,
                z : 1,
                rotation : 0
            },
            {
                title: {  },
                centerX : 800,
                centerY : 1700,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 800,
                centerY : 1000,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 800,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {  },
                centerX : 1300,
                centerY : 1700,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 1300,
                centerY : 1000,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 1300,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: {  },
                centerX : 1900,
                centerY : 1000,
                z : 1,
                rotation : 0
            }            
        ]        
    },
    rowboat : {
        id : "rowboat",
        text : {
            nl : {
                title: "Roeien met de riemen die je hebt",
                description: "Vind antwoord op je vraag"
            }
        },
        
        width : 2200,
        height : 2800,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: { },
                centerX : 1100,
                centerY : 2000,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 1100,
                centerY : 1350,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 650,
                centerY : 1600,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 1550,
                centerY : 1600,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 200,
                centerY : 2500,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 2000,
                centerY : 2500,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 1100,
                centerY : 300,
                z : 1,
                rotation : 0
            }
        ]        
    },
    theroadahead : {
        id : "theroadahead",
        text : {
            nl : {
                title: "Roeien met de riemen die je hebt",
                description: "Vind antwoord op je vraag"
            }
        },
        
        width : 2200,
        height : 2800,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: { },
                centerX : 1100,
                centerY : 2500,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 1100,
                centerY : 1850,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 1100,
                centerY : 1200,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 650,
                centerY : 750,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 200,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 1550,
                centerY : 750,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 2000,
                centerY : 300,
                z : 1,
                rotation : 0
            }
        ]        
    },
    thestar : {
        id : "thestar",
        text : {
            nl : {
                title: "Roeien met de riemen die je hebt",
                description: "Vind antwoord op je vraag"
            }
        },
        
        width : 1800,
        height : 2400,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: { },
                centerX : 900,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 1600,
                centerY : 1800,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 200,
                centerY : 1800,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 900,
                centerY : 2100,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 200,
                centerY : 600,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 1600,
                centerY : 600,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 900,
                centerY : 1200,
                z : 1,
                rotation : 0
            }
        ]        
    },
    thearch : {
        id : "thearch",
        text : {
            nl : {
                title: "Roeien met de riemen die je hebt",
                description: "Vind antwoord op je vraag"
            }
        },
        
        width : 2950,
        height : 1500,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: { },
                centerX : 200,
                centerY : 1200,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 625,
                centerY : 700,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 1050,
                centerY : 400,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 1475,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 1900,
                centerY : 400,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 2325,
                centerY : 700,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 2750,
                centerY : 1200,
                z : 1,
                rotation : 0
            }
        ]        
    },
    newrelation : {
        id : "newrelation",
        text : {
            nl : {
                title: "Zicht op een nieuwe relatie",
                description: "Vind antwoord op je vraag"
            }
        },
        
        width : 1900,
        height : 2000,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: { },
                centerX : 200,
                centerY : 1000,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 700,
                centerY : 1700,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 700,
                centerY : 1000,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 700,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 1200,
                centerY : 1000,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 1700,
                centerY : 1000,
                z : 1,
                rotation : 0
            }
        ]        
    },
    causeandeffect : {
        id : "causeandeffect",
        text : {
            nl : {
                title: "Probleem en oplossing, Oorzaak en Gevolg",
                description: "Vind antwoord op je vraag"
            }
        },
        
        width : 2400,
        height : 1300,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: { },
                centerX : 700,
                centerY : 650,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 200,
                centerY : 650,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 1200,
                centerY : 650,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 1700,
                centerY : 1000,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 1700,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 2200,
                centerY : 650,
                z : 1,
                rotation : 0
            }
        ]        
    },
    wheeloffortune : {
        id : "wheeloffortune",
        text : {
            nl : {
                title: "Het rad van Fortuin",
                description: "Vind antwoord op je vraag"
            }
        },
        
        width : 2600,
        height : 1800,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: { },
                centerX : 200,
                centerY : 900,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 1300,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 880,
                centerY : 900,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 1300,
                centerY : 1500,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 1720,
                centerY : 900,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 2400,
                centerY : 900,
                z : 1,
                rotation : 0
            }
        ]        
    },
    bedrijfsplan : {
        id : "bedrijfsplan",
        text : {
            nl : {
                title: "Mijn (bedrijfs) Plan",
                description: "Vind antwoord op je vraag"
            }
        },
        
        width : 1400,
        height : 2350,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: { },
                centerX : 700,
                centerY : 1350,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 200,
                centerY : 750,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 1200,
                centerY : 750,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 700,
                centerY : 2050,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 700,
                centerY : 300,
                z : 1,
                rotation : 0
            }
        ]        
    },
    startbedrijf : {
        id : "startbedrijf",
        text : {
            nl : {
                title: "Ik wil een eigen bedrijf starten",
                description: "Vind antwoord op je vraag"
            }
        },
        
        width : 1400,
        height : 2000,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: { },
                centerX : 700,
                centerY : 1000,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 200,
                centerY : 1000,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 700,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 700,
                centerY : 1700,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 1200,
                centerY : 1000,
                z : 1,
                rotation : 0
            }
        ]        
    },
    chrystalball : {
        id : "chrystalball",
        text : {
            nl : {
                title: "De Glazen Bol",
                description: "Vind antwoord op je vraag"
            }
        },
        
        width : 1400,
        height : 2000,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: { },
                centerX : 700,
                centerY : 1700,
                z : 1,
                rotation : 0
            },


            
            {
                title: { },
                centerX : 200,
                centerY : 1000,
                z : 1,
                rotation : 0
            },
            
            {
                title: { },
                centerX : 1200,
                centerY : 1000,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 700,
                centerY : 300,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 700,
                centerY : 1000,
                z : 1,
                rotation : 0
            }
        ]        
    },
    developing : {
        id : "developing",
        text : {
            nl : {
                title: "Op Weg, In Ontwikkeling",
                description: "Vind antwoord op je vraag"
            }
        },
        
        width : 1400,
        height : 2000,
        showOnSplashScreen: true,
        cardWidth : 400,
        cardHeight : 600,
        allowRedraw: true,
        onHomepage: false,

        decktypes: [ DeckTypes.TAROT ],
        positions : [
            {
                title: { },
                centerX : 200,
                centerY : 1000,
                z : 1,
                rotation : 0
            },           
            
            
            {
                title: { },
                centerX : 1200,
                centerY : 1000,
                z : 1,
                rotation : 0
            },
            {
                title: { },
                centerX : 700,
                centerY : 300,
                z : 1,
                rotation : 0
            },

            {
                title: { },
                centerX : 700,
                centerY : 1700,
                z : 1,
                rotation : 0
            }
        ]        
    }    
}