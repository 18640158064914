export const GuidanceFields = {
    DEFAULT : "DEFAULT",
    RELATIONSHIP : "RELATIONSHIP",
    WORK: "WORK",
    SPIRITUAL: "SPIRITUAL"    
}

export const DeckTypes = {
    TAROT: "TAROT",
    TAROT_MAYOR_ARCANA: "TAROT-MAYOR-ARCANA"
}

let _deckCards = {
    [DeckTypes.TAROT] : {
        cardDirectories : {
            'Radiant Rider Waite' : '/Decks/Rider-Waite/',
            'Cartoon Rider Waite' : '/Decks/Rider-Waite-2/',
            'Classic Rider Waite' : '/Decks/Rider-Waite-Classic/',
            'Fantasy' : '/Decks/Shadow-Scapes/'
        },
        cards: [
            {
                filename: "00",
                nl : {
                    name: "De Dwaas",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>De Dwaas leidt zijn leven vrij van zorgen, piekeren en vooroordelen. Hij onderzoekt de wereld met een open geest, hij vraagt zich niet af waar hij terecht zal komen en maakt zich er ook niet druk om. Hij heeft een kinderlijke naïviteit en loopt over van verwondering en levensvreugde. Hij staat altijd open voor verandering, vernieuwing en groei. Mogelijk herken je jezelf hierin, of misschien kan je wat meer van deze eigenschappen gebruiken in je leven.</p><p>Let toch op dat je niet té optimistisch of naïef bent. Soms is het nu eenmaal verstandiger om te kijken waar je loopt dan zomaar in het diepe te springen. Als je deze kaart trekt dan wijst dit er vaak op dat het voor jou nu een goed moment is voor een nieuw begin, om een nieuwe start te nemen, een nieuw project op te zetten.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Dwaas vraagt je om op een andere manier naar je relaties te kijken, vanuit een ander perspectief. Dit kan je kijk op de dingen totaal veranderen. Als het over de liefde gaat, kan je je afvragen of je er echt klaar voor bent om je te binden, wil je je wel voor langere tijd vastleggen? Maak je niet ongerust als dit niet het geval is, zolang je eerlijk bent zit je op het goede spoor. Het is ook mogelijk dat een reeds bestaande relatie betekenisvoller en diepgaander kan worden, als je daar klaar voor bent tenminste.</p><p>De Dwaas kan ook betekenen dat er leuke tijden aankomen, hij nodigt je uit om ervan te genieten. Maar overdrijf hier niet mee, zorg dat je er klaar voor bent als de ware liefde op je pad komt.</p>",
                        [GuidanceFields.WORK] : "<p>Kijk met een open en onderzoekende geest naar je werk en/of carrière, vraag je af of er ruimte en gelegenheid is voor bijleren en verbetering. Misschien is de tijd nu rijp voor verandering, voor nieuwe ervaringen. Het is wel mogelijk dat niet iedereen openstaat voor jouw nieuwe ideeën en voorstellen, dat ze er geen begrip voor hebben en je niet steunen. Probeer zo duidelijk en open mogelijk te zijn, vertel ze hoe je tot je nieuwe conclusies gekomen bent.</p><p>Soms vertelt De Dwaas ons dat het tijd is om op zoek te gaan naar een andere baan, een nieuwe carrière. Misschien kan je nu zelfs gaan denken aan het opzetten van je eigen bedrijf!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op dit tijdstip in je leven begin je interesse te krijgen voor spiritualiteit. Je raakt soms wat overweldigd door de veelheid aan onderwerpen en mogelijke richtingen die je kan kiezen. Misschien ontdek je op een gegeven moment ook krachten of talenten bij jezelf waarvan je nog niet wist dat je ze in je had. Het komt erop neer dat je echt nog niet weet welke kant je op wil. Maak je niet ongerust, neem gewoon de tijd om net dat pad te kiezen dat het beste bij je past en aanvaard dat de mensen om je heen mogelijk niet altijd zullen begrijpen waarom je nood hebt aan spiritualiteit in je leven.</p>",
                    }
                }
            },
            {
                filename: "01",
                nl : {
                    name: "De Magiër",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>De Magiër nodigt je uit om naar alle mogelijkheden, kansen en richtingen te kijken waar je op dit moment uit kan kiezen. Denk goed na over alle vragen die het leven je nu stelt.</p><p>Deze kaart nodigt je ook uit om beter gebruik te maken van je spirituele, emotionele en energetische krachten. Ze vraagt je om alert en actief te zijn, om te beseffen dat jij al de nodige inspiratie, kennis en kracht in je hebt om de uitdagingen aan te gaan waarmee je geconfronteerd wordt.</p><p>Ze vertelt je ook dat je van betekenis kan zijn zowel in je eigen leven als in dat van anderen, misschien zelfs in de wereld als geheel. Je kan er een betere plek van maken, al begin je met één enkele stap.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Magiër vertelt je dat de bestaande relaties in je leven steeds sterker en diepgaander worden. Je zal nieuwe hoogten bereiken wat betreft vreugde, intimiteit en betrokkenheid. Als je op zoek bent naar een liefdesrelatie dan is het erg waarschijnlijk dat je nu iemand zal ontmoeten. Als je wil werken aan je sociale leven dan is het daarvoor nu de juiste tijd. Je zal merken dat mensen zich tot jou aangetrokken voelen, ze willen je leren kennen.</p><p>Toch is het belangrijk dat je erop let niet te egoïstisch of egocentrisch te worden want ook al ben je populair, de wereld draait niet rond jou.</p>",
                        [GuidanceFields.WORK] : "<p>Volgens De Magiër is het erg waarschijnlijk dat er binnenkort verbetering komt in je job of je carrière. Als je al lang een promotie wilde vragen of als je een nieuwe job wil vinden dan is nu het geschikte moment. Let wel, jij zal de eerste stap moeten zetten, jij zal initiatief moeten nemen. Zorg dat je de juiste doelen voor ogen houdt (opslag, promotie, ...) en dat je hierover praat met de juiste mensen. Geloof in jezelf, geloof in je talent om te slagen! Het is aan jou om de anderen te overtuigen van jouw visie en toekomstplannen door hen te vertellen over je ervaringen en inzichten. Wees niet te kwistig met je emoties terwijl je deze gesprekken voert, houd het rationeel.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Het is waarschijnlijk dat je op dit moment een grote behoefte hebt aan spirituele groei en ontwikkeling. Je kijkt ernaar uit om te studeren, te evolueren en bepaalde onderwerpen grondig te onderzoeken. De Magiër vertelt je dat het perfecte moment hiervoor aangebroken is. Omring jezelf met gelijkgestemde zielen die je kunnen helpen en leiden, praat met hen, vergelijk de verschillende opinies. Misschien vind je nu zelfs een mentor die je de komende jaren kan ondersteunen bij je evolutie en groei.</p>",
                    }
                }
            },
            {
                filename: "02",
                nl : {
                    name: "De Hogepriesteres",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>De Hogepriesteres symboliseert vrouwelijke waarden en krachten: intuïtie, gevoeligheid, goedheid, vergeving, geloof. Ze spoort je aan om in stilte na te denken over je gedachten en gevoelens, om ruimte te maken voor inzichten, visioenen en intuïtieve bewustwording.</p><p>Deze kaart wijst je op de onbewuste krachten die in je diepste kern leven, de donkere zijde die we allemaal hebben. Als je de kracht en de moed hebt om de confrontatie aan te gaan met deze minder bekende kant, dan zal je er niet langer onbewust door overheerst worden. Je zal de vrijheid verwerven om deze vrouwelijke kant vrijuit te onderzoeken en te gebruiken.</p><p>Besteed in deze periode van je leven extra aandacht aan zogenaamde toevalligheden en aan zaken die gelijktijdig gebeuren (bvb. je denkt aan iemand en die belt je).</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Het is goed mogelijk dat je je in deze fase van je leven aangetrokken voelt tot iemand die mysterieus overkomt, iemand die je niet gemakkelijk kan doorgronden. Misschien lijkt deze persoon zelfs afstandelijk en ongeïnteresseerd. Neem de tijd om die persoon te benaderen, gebruik je intuïtie, misschien is hij/zij wel de liefde van je leven.</p><p>Aan de andere kant is het ook mogelijk dat de mensen in je omgeving zich erg tot je aangetrokken voelen zonder dat jij daar enige inspanning voor hoeft te doen. Wees wijs in het omgaan hiermee, trap niet in de val van oppervlakkigheid maar toon mededogen en begrip voor die ander, behandel de mensen die je benaderen met openheid en gevoel.</p>",
                        [GuidanceFields.WORK] : "<p>De Hogepriesteres wil je duidelijk maken dat je nu in een periode zit waarin je je werk moet benaderen met sereniteit en hoger bewustzijn, het is belangrijk om in het Hier en Nu te leven.</p><p>Luister in de eerste plaats naar je innerlijke stem, naar je hart, en zorg dat niets je daarvan kan afleiden. Het is belangrijk om nu eerst rustig te observeren en dan pas beslissingen te nemen. Je observaties kunnen je helpen om manieren van werken te vinden die minder stresserend zullen zijn.</p><p>Een tip: als collega’s kritiek hebben op anderen terwijl je erbij bent, doe er dan niet aan mee.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Waarschijnlijk ben je je er nu al enige tijd van bewust dat je spirituele vaardigheden en inzichten hebt. Nu komt het erop aan om te ontdekken waar je grootste kracht zit, welk aspect je het beste ligt en van daaruit verder te gaan onderzoeken, studeren en groeien.</p><p>Als je het moeilijk vindt om je eigen spiritualiteit te aanvaarden, dan is het nog belangrijker dat je gaat onderzoeken en groeien want dat is de manier om vrede te vinden met het spirituele aspect dat in je schuilt.</p>",
                    }
                }
            },
            {
                filename: "03",
                nl : {
                    name: "De Keizerin",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>De Keizerin staat voor vruchtbaarheid. Ze toont je de steeds weerkerende cirkel van creatie van nieuw leven en staat dus voor lichamelijke en spirituele vernieuwing. Ze vertelt je dat er voor groei altijd nood zal zijn aan geduld en koesteren, liefde en aandacht.</p><p> Als je deze kaart trekt dan is het waarschijnlijk zo dat je een grote behoefte hebt om naar je emoties en passies te luisteren, om je intuïtie te volgen. Het is goed mogelijk dat deze instelling je extra interessant maakt voor de mensen om je heen. Je zal merken dat mensen met je willen praten, je om goede raad vragen. Daarom is het nu het ideale moment om de levenslessen die je vergaard hebt ook weer door te geven, levenslessen die je soms behoorlijk wat gekost hebben voor je ze echt doorhad. Laat anderen meegenieten van je wijsheid!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Keizerin wijst op verandering en vernieuwing maar ook op levendigheid, overvloed en positieve ontwikkelingen.</p><p>Het is goed mogelijk dat je op dit moment erg populair bent, mensen vinden het fijn om in je gezelschap te zijn, ze willen met je praten, je advies inwinnen. Als je op dit ogenblik geen relatie hebt, dan is dit een goede periode om de ware liefde te vinden.</p><p>Als je een vaste relatie hebt dan zal deze relatie alsmaar vruchtbaarder worden (in figuurlijke zin maar mogelijk ook letterlijk).</p>",
                        [GuidanceFields.WORK] : "<p>Op dit ogenblik in je leven ervaar je een eindeloze stroom van creativiteit en vindingrijkheid. Je inspireert de mensen om je heen door je aanpak, je ideeën, je energie. Luister heel goed naar je diepste zelf want het risico bestaat dat deze creatieve energie zou omslaan in zenuwachtigheid en onzekerheid. Financieel verloopt alles heel vlot, zorg ervoor dat je je eigen overvloed ook deelt met anderen zodat alles blijft stromen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op dit moment heb je waarschijnlijk een heel sterke intuïtie die zelfs nog aan het groeien en ontwikkelen is. Sluit dit geschenk in je armen, geniet ervan en luister ernaar, want het is dé manier om tekenen en boodschappen door te krijgen. Let heel goed op, mis niets, want het universum heeft je een aantal belangrijke dingen te vertellen.</p><p>Ook al ben je eraan gewend dat andere mensen je nodig hebben en op je steunen, op dit moment moet je in de eerste plaats voor jezelf zorgen en dan pas aan anderen denken.</p>",
                    }
                }
            },
            {
                filename: "04",
                nl : {
                    name: "De Keizer",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>De Keizer laat geen ruimte voor toeval.</p><p> Stabiliteit, nuchterheid, structuur, ernst en perfectionisme zijn de waarden waar hij voor staat. Geef jij deze eigenschappen voldoende ruimte in je leven? De Keizer symboliseert ook de strijd voor onafhankelijkheid, het doel dat de mens nastreeft om boven de wetten van de natuur uit te stijgen. Misschien overdrijft hij hier zelfs een beetje in. Toch heeft De Keizer waardevolle eigenschappen te bieden zoals verantwoordelijkheid en rationaliteit.</p><p>Als je deze kaart kiest dan is de tijd waarschijnlijk rijp om eerder verstandelijk dan emotioneel te werk te gaan. Als je nu zware beslissingen moet nemen, moeilijke keuzes moet maken, dan is het verstandig om rationeel en gefocust te zijn, om je hersenen te gebruiken.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In persoonlijke relaties ben je een stabiel, zelfzeker en betrouwbaar iemand. Probeer wel te onthouden dat echt goede relaties ook onderhouden nodig hebben, ze blijven niet vanzelf goed. In een relatie moet je investeren maar ook meegroeien, evolueren. Aanvaard veranderingen en probeer soepel en geduldig te zijn, anders loop je het risico dat je te dominant en streng wordt.</p>",
                        [GuidanceFields.WORK] : "<p>De favoriete bezigheid van De Keizer is om orde te scheppen vanuit chaos. Hij is standvastig, logisch en vastberaden. Als je op zoek bent naar werk dan is het erg belangrijk dat je nu aandacht besteedt aan de manier waarop anderen naar je kijken, je ervaren. Analyseer de redenen waarom ze voor jou zouden moeten kiezen in verband met een bepaalde job. Het is nu aan jou om hen te overtuigen van je unieke kwaliteiten.</p><p>Het maakt niet uit welk werk je doet, om nu succes te hebben, heb je discipline en toewijding nodig. Let er ook op dat je gestructureerd te werk gaat.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Je hebt de neiging om het dagelijkse leven aan te pakken met logica en redeneren, maar je hebt ook een sterke behoefte aan spirituele ontwikkeling en groei en die behoefte verwaarloos je soms. Onthoud goed dat je spiritualiteit ook op een logische manier kan benaderen, logica en “spirit” zijn niet met mekaar in tegenspraak. Integendeel, ze zijn beide een belangrijk onderdeel van wie jij bent en je kan ze gecombineerd gebruiken om spiritueel te groeien.</p>",
                    }
                }
            },
            {
                filename: "05",
                nl : {
                    name: "De Hogepriester",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart wordt soms ook De Hiërophant genoemd. Ze staat voor geloof en diep vertrouwen. Geloof speelt zich af in je hart en je ziel, je hebt geen bewijzen nodig. Geloof heeft een diep innerlijk weten als basis, en je krijgt er intens vertrouwen en innerlijke vrede door.</p><p>De Hogepriester staat voor vertrouwen, zelfvertrouwen en geloof in de toekomst. Hij staat ook voor een duidelijk en scherp bewustzijn van wat goed en fout is.</p><p>Als je hiermee geconfronteerd wordt in jouw leven, sta er dan toch even bij stil of datgene wat in het algemeen als goed beschouwd wordt, ook het juiste is voor jou. Tenslotte zijn alle situaties, alle te nemen beslissingen uniek.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Hogepriester leert je dat het vertrouwen en de genegenheid die je voelt voor anderen geleidelijk aan het vermeerderen zijn. Dit betekent dat je relaties nu groeien en meer diepgang krijgen. Als je op het ogenblik een liefdesrelatie hebt dan is het erg waarschijnlijk dat de relatie aan het evolueren is, intenser aan het worden is. Je wordt je ook steeds meer bewust van je idealen en principes en je persoonlijke deugden en waarden zullen je gedrag steeds meer gaan bepalen.</p>",
                        [GuidanceFields.WORK] : "<p>Je bent in je werk op zoek naar diepgang. Je bent aan het uitzoeken of de baan die je nu hebt echt wel datgene is wat je heel diep vanbinnen wil doen. Toch kan De Hogepriester je ook confronteren met een dilemma: is het tijd om een andere baan of carrière te overwegen of is het beter om loyaal te zijn en te blijven waar je nu bent? Vertrouw op jezelf èn op het universum, dan komt de juiste beslissing vanzelf in je op.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Zelfs als je geen religieus persoon bent, je zal toch merken dat je op het ogenblik nogal gefocust bent op het spirituele. Ook is het beste mogelijk dat het beeld dat jij hebt van spiritualiteit indruist tegen de meningen en visies van anderen. Om innerlijk evenwicht te vinden is het belangrijk dat je jezelf de tijd gunt om te mediteren en nieuwe rituelen voor jezelf te creëren. Als je dit doet dan zal je merken dat je je leven structureel en op een harmonieuze manier verrijkt.</p>",
                    }
                }
            },
            {
                filename: "06",
                nl : {
                    name: "De Geliefden",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart, De Geliefden, gaat bijna altijd over een intieme, sterke relatie tussen twee personen en gaat dus vaak over een liefdesrelatie. De kaart symboliseert dan deze liefde als een belangrijk doel. Aantrekkingskracht, liefde, vriendschap, het verenigen van tegengestelden ... als twee mensen mekaar spiegelen dan kunnen deze gevoelens hun bewustzijn (en daardoor ook hun groei en inzichten) sterk verhogen.</p><p>Soms staat deze kaart ook voor de twijfels die je kunnen overvallen als je geconfronteerd wordt met belangrijke keuzes. Als je moet kiezen dan is het vaak moeilijk om te beslissen of je naar je hart of naar je hoofd moet luisteren. Beide hebben immers hun eigen unieke standpunten en kwaliteiten.</p><p>Als je De Geliefden trekt, dan belooft deze kaart je de wijsheid en moed om met volle overtuiging je keuze te maken. Daarom noemt men deze kaart ook soms “De Keuze”.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Geliefden confronteert je met de realiteit van een grote, diepe liefde in je leven. Je wordt intens geraakt door deze liefde en je zal een keuze moeten maken. Als je besluit om voor deze liefde te kiezen, zorg er dan voor dat je het ook echt doet met hart en ziel. Je moet er helemaal van overtuigd zijn dat je de juiste keuze maakt. De Geliefden wil je motiveren om echt eerlijk te zijn met jezelf, om te beseffen dat sprookjes niet bestaan, net zomin als prinsen op een wit paard. Als je voor de liefde kiest, dan aanvaard je de ander zoals hij/zij is, zonder voorbehoud.</p>",
                        [GuidanceFields.WORK] : "<p>De Geliefden wil dat je je ervan bewust wordt dat het tijd is om een keuze te maken. Misschien is de tijd gekomen om van baan of van carrière te veranderen, of misschien zijn er bepaalde aspecten of onderdelen van je huidige baan waar je verandering in wil brengen. Voordat je een keuze maakt, is het belangrijk om alle aspecten van je huidige baan grondig te analyseren, na observatie kan iets wat negatief lijkt best in iets positiefs veranderen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Deze kaart vertelt je dat het waarschijnlijk een goed moment is in je leven om een spirituele relatie te gaan opbouwen met iemand. Het is best mogelijk dat de juiste persoon zelfs al in je omgeving is. Als je met deze persoon een gesprek aangaat, neem dan ruim de tijd om de wijsheid die hij/zij met jou deelt te bestuderen, maar onthoud tegelijkertijd dat het goed is om alles in vraag te stellen als je hart je dat aangeeft.</p>",
                    }
                }
            },
            {
                filename: "07",
                nl : {
                    name: "De Zegewagen",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>De Zegewagen staat voor doelgerichte energie die als resultaat zal hebben dat je een grote stap vooruit zet. Dit is een heel positieve kaart die je aanspoort om buiten je eigen comfortzone te treden en nieuwe, voorheen onbekende richtingen te kiezen. Je belangrijkste motieven zullen waarschijnlijk ambitie, vastberadenheid en een verlangen naar vrijheid zijn.</p><p>Dit is een sterk energetische kaart die als betekenis heeft dat ze je energie stuurt in de richting van een specifiek, zorgvuldig uitgekozen doel.</p><p>Er schuilt slechts één waarschuwing in deze kaart: wees niet té zelfzeker want dan zou je tekort kunnen schieten.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Je staat mogelijk op het punt om aan een nieuwe relatie te beginnen. Het is erg belangrijk dat je je vorige relatie op een zuivere en liefdevolle manier afsluit voor je aan dit nieuwe avontuur begint.</p><p>De Zegewagen kan je er soms op wijzen dat je met je relatie een beetje in een sleur zit en dat het tijd wordt dat je oude patronen gaat loslaten.</p><p>De Zegewagen kan ook betekenen dat iemand in jou geïnteresseerd is maar dat jij niet hetzelfde voelt. Als je je in deze situatie bevindt, wees dan liefdevol maar kordaat in het afwijzen van die persoon.</p>",
                        [GuidanceFields.WORK] : "<p>De Zegewagen staat symbool voor triomf, vaardigheid, volbrenging. Je hebt een grote stap vooruit gezet op je weg naar succes. Dit betekent niet dat je al je doelen al bereikt hebt maar je zit absoluut in de goede richting. Toch is het belangrijk om voorzichtig te zijn en jezelf niet te overschatten. Je weet eigenlijk heel goed wat je wil bereiken en tot welke opofferingen je bereid bent om tot bij je doel te geraken.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In verband met je spiritualiteit is het nu een goede tijd om te reizen, en dat niet alleen letterlijk. Onderzoek nieuwe onderwerpen, bekijk andere denkwijzen, vergaar informatie over materie die je nog niet bekeken had. Je hebt de energie en het uithoudingsvermogen om veel werk te verzetten. Misschien is nu wel het goede moment om eindelijk die cursus te volgen waar je al zo lang over aan het praten en nadenken bent, of om met die meditatie te beginnen waar je over gelezen hebt. Je kan je nu nog niet voorstellen hoe goed je je zal voelen als je nieuwe dingen bijleert en uitprobeert. Ga ervoor!</p>",
                    }
                }
            },
            {
                filename: "08",
                nl : {
                    name: "De Kracht",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart gaat over de fundamentele primaire kracht die elk mens bezit, een kracht die voortkomt uit diepgaande innerlijke harmonie. De Kracht stimuleert je ook om deze energie te gebruiken in positieve zin: kijk naar wat je wil in plaats van naar wat je niet wil.</p><p>Als je geleerd hebt om je gevoelens in de hand te houden en je hebt de poorten naar een hoger bewustzijn geopend, dan komt alle onbewuste kracht, passie en moed die je in je hebt tevoorschijn. Deze kaart vertelt je dat je eerst doorheen je fundamentele dierlijke instincten heen moet gaan, je moet ze erkennen en beleven.</p><p>Pas daarna kan je aan de slag om bewust je eigen morele waarden en kracht vormen. Daarom kan je de betekenis van deze kaart samenvatten als: de kracht van de geest die het materiële, het fysieke overwint.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Kracht benadrukt dat vuur en fysieke passie belangrijk zijn in alle soorten relaties. Deze kaart kan bijvoorbeeld een relatie beschrijven die in eerste instantie intens en gepassioneerd is, maar die uiteindelijk kort en inhoudsloos blijkt te zijn op het moment dat de passie uitdooft. Je voelt je als persoon sterk zolang deze relatie duurt, maar als ze dan eindigt, verlies je al die kracht heel snel en blijkt het zo te zijn dat er eigenlijk nooit sprake was van echte innerlijke sterkte. Aan de andere kant: als je relatie sterk is dan is ze op dit moment ook echt onbreekbaar!</p><p>Als je deze kaart trekt dan is het voor jou een goede tijd om erop uit te trekken en nieuwe mensen te ontmoeten, want je hebt erg veel zelfvertrouwen.</p>",
                        [GuidanceFields.WORK] : "<p>Je bent op dit moment erg gepassioneerd en energiek. Daarom is het waarschijnlijk dat je slaagt in alle doelen die je voor jezelf vooropstelt. Toch is het zo dat je deze energie enkel voelt als je echt gemotiveerd bent. Als je er niet in slaagt om gemotiveerd te blijven dan merk je dat je ook je energie stilaan kwijtraakt.</p><p>Als je een nieuwe baan wil of je wil promotie of opslag vragen, dan is het nu het juiste moment. Onderschat jezelf niet, er liggen veel wegen open voor jou, je hebt meer opties dan je op het eerste zicht zou vermoeden!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Deze kaart herinnert je aan jouw eigen innerlijke kracht, je bent waarschijnlijk veel sterker dan je zelf denkt of beseft. Het is op dit moment vooral belangrijk dat je evenveel aandacht besteedt aan alle aspecten van je zelf: spiritueel, fysiek, intellectueel, ... Als je dat doet dan zal je in jezelf een diepe bron van innerlijke kracht vinden die je zal helpen om dingen aan te pakken waar je eerder tegenop zag. Wees geduldig en houd vol, ga door je angsten heen en je zal doelen bereiken die je voorheen onbereikbaar achtte!</p>",
                    }
                }
            },
            {
                filename: "09",
                nl : {
                    name: "De Kluizenaar",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart toont de positieve kracht van eenzaamheid, van je terugtrekken uit het sociale leven om tot inkeer te komen. Het gaat hier om een leven gericht op het innerlijke, over de introspectie die je nodig hebt om dichter bij je Ware Zelf te komen. Om dit te bereiken is het belangrijk dat je je afsluit voor prikkels en indrukken, dat je de buitenwereld even loslaat. Als je dit stadium in je leven bereikt hebt, dan kan je met twee ervaringen geconfronteerd worden: je krijgt een intense confrontatie met je diepste gedachten en gevoelens, en net daardoor bereiken de inzichten die je hierdoor krijgt een ongekend hoog niveau.</p><p>Het is erg belangrijk dat je jezelf de tijd gunt om alleen te zijn, om je hoofd leeg te maken, om niet na te denken. Het is nu niet de juiste tijd om te piekeren maar wel om te observeren, gewoon bekijken en aanvaarden wat op je afkomt.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Kluizenaar vertelt je dat het goed mogelijk is dat je je op het moment eenzaam voelt, ook al heb je een liefdesrelatie. Dit kan een teken zijn dat de eenzaamheid vanbinnen zit, vanuit jezelf komt en dat je dringend weer in contact moet komen met je Innerlijke Zelf. Als je een relatie hebt, is het mogelijk dat je je vragen aan het stellen bent over de ware betekenis van deze relatie en de veiligheid en geborgenheid die je erin vindt. Je bent op dit moment echt op zoek naar inzichten. Het is mogelijk dat je in deze periode een vorige partner ontmoet die contact zoekt met jou en de oude relatie weer nieuw leven wil inblazen. Stel jezelf de vraag of dit echt is wat je wil of dat het enkel een gemakkelijkheidsoplossing zou zijn. Misschien is het zinvoller om te werken aan je huidige relatie.</p>",
                        [GuidanceFields.WORK] : "<p>Volgens deze kaart is het belangrijk om op het ogenblik diep na te denken over je baan of carrière. Wil je deze baan echt wel hebben? Ben je gelukkig? Past deze baan bij jou? Je hebt er behoefte aan om dit te bespreken met de mensen om je heen maar let er wel op dat de meningen van anderen je enkel inspireren, laat je niet in een bepaalde richting duwen.</p><p>Als je alle nodig informatie verzameld hebt, is het aan jou om je terug te trekken op je eigen plek en dan te beslissen of je huidige baan de juiste is of dat je op zoek moet gaan naar een andere, eentje die echt bij je past.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Als je eerlijk bent met jezelf dan weet je dat je in een periode zit waarin je er de voorkeur aan geeft om alleen te zijn, om je terug te trekken uit het dagelijkse leven en je te focussen op jezelf en je spirituele energie. Je bent op zoek naar manieren om dichter bij je Ware Zelf te komen. Misschien wil je dit doel bereiken door te mediteren, door bespiegeling, spirituele oefeningen of misschien zelfs door vasten. Het is waarschijnlijk dat er nu iemand in je leven komt die je kan gidsen, coachen op deze spirituele reis.</p>",
                    }
                }
            },
            {
                filename: "10",
                nl : {
                    name: "Het Rad van Fortuin",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Het Rad van Fortuin is een opvallend spirituele kaart. Ze staat vooral voor verandering en maakt je duidelijk dat je je mogelijk hulpeloos voelt, geconfronteerd wordt met situaties waarop je nauwelijks invloed hebt. Mogelijk is het zelfs de verandering zelf die je als beangstigend ervaart. En het klopt natuurlijk dat verandering vaak erg traumatisch kan zijn. Toch staat ons hele leven in het teken ervan want groei kan enkel ontstaan vanuit verandering.</p><p>Soms gaat deze kaart over positieve veranderingen in je leven waarvan je in eerste instantie dacht dat je er geen invloed op had. En toch is het mogelijk dat je op een gegeven moment beseft dat je voor een stuk toch zelf je lot bepaald hebt. Of het Rad van Fortuin nu links- of rechtsom draait, jij hebt impact op de plaats waar het stopt.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart wijst erop dat je je op dit moment niet goed voelt in je liefdesleven. Misschien ben je single en verlang je naar een relatie, of misschien is de relatie waar je op het moment deel van uitmaakt erg problematisch, mogelijk zelfs onbevredigend. In elk geval zal je de tijd moeten nemen om na te denken over wat je echt wil en dan beslissen wat je eraan zal doen om dat te bereiken.</p><p>Houd jezelf voor ogen dat verandering een onmisbaar deel van het leven is, dat we nu eenmaal moeten veranderen als we willen groeien en evolueren. Probeer tegelijk ook in het Nu te leven en stel je open voor wat er op je af komt.</p>",
                        [GuidanceFields.WORK] : "<p>Je bent waarschijnlijk niet echt tevreden met je werksituatie, je voelt je een stuk machteloos, alsof je geen enkele invloed hebt op de richting die je aan het uitgaan bent. Mogelijk heb je het gevoel dat je in een sleur zit, dat je gewoon maar oude patronen aan het volgen bent. Je doet je werk niet meer met hart en ziel. Misschien moet je wel op zoek gaan om erachter te komen wat je droom is en daarna plannen maken om die droom waar te maken. Praat hierover met zoveel mogelijk mensen, ga op onderzoek uit en je zal zien dat je uiteindelijk zal vinden waarnaar je op zoek bent.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Het Rad van Fortuin wijst je erop dat je onmogelijk alle aspecten van je leven onder controle kan hebben, maar natuurlijk heb je in elke situatie en gebeurtenis wel een stukje verantwoordelijkheid. Meestal is het niet zo dat het leven je zomaar overkomt. Gelijk wat er op dit moment aan de hand is, aanvaard de impact die jij op de situatie hebt, neem die verantwoordelijkheid en probeer ervan te leren. Neem nu ook de tijd om naar vorige ervaringen te kijken, ze te analyseren, er de nodige lessen uit te trekken en dan alles los te laten.</p>",
                    }
                }
            },
            {
                filename: "11",
                nl : {
                    name: "Gerechtigheid",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Gerechtigheid staat voor eerlijk en oprecht zijn. Kan jij iedereen om je heen recht in de ogen kijken?</p><p>Bij deze kaart gaat het ook om heldere en objectieve inzichten, weldoordachte beslissingen, evenwicht en integriteit.</p><p>Wat het leven van alledag betreft, vraagt deze kaart je om je bewust te worden van oorzaak en gevolg als het om gedrag gaat. Immers, als je zelf eerlijk en oprecht bent, dan kan je over het algemeen hetzelfde gedrag verwachten van de mensen om je heen. Als je zelf oneerlijk en onoprecht bent, dan weet je dat je vroeg of laat terecht zal komen in moeilijke en bedrieglijke situaties. Het is erg waarschijnlijk dat mensen je uiteindelijk door zullen hebben, dat ze je zullen ontmaskeren. Deze kaart vraagt je om echte innerlijke integriteit te tonen en je volle verantwoordelijkheid te nemen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Bij deze kaart gaat het erom evenwichtig te zijn en te blijven in al je relaties. Zelfs als je op het ogenblik geen liefdesrelatie in je leven hebt en er mogelijk wel naar verlangt, je voelt je toch in harmonie met je leven en je situatie. Je weet nu duidelijk wat je wil en verwacht van een relatie en net daarom wordt het makkelijker om het ook te vinden.</p><p>Gerechtigheid vertegenwoordigt ook evenwicht en wederkerigheid: als je de mensen waar je om geeft volkomen eerlijk benadert en behandelt, dan zullen zij dat meestal ook met jou doen.</p>",
                        [GuidanceFields.WORK] : "<p>Wat je werk betreft kan je er op dit moment waarschijnlijk op rekenen dat je op een rechtvaardige en evenwichtige manier behandeld wordt. Je bent eerlijk, je komt openlijk voor je mening uit en je bent goed in wat je doet. Toch is het mogelijk dat je bij momenten een stuk passie mist, dat je het moeilijk vindt om echt op te gaan in je werk. Ook is het mogelijk dat het evenwicht tussen werk en privé niet helemaal goed zit. Daarin het juiste evenwicht bereiken is absoluut nodig om geluk en harmonie te kunnen vinden.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De betekenis van Gerechtigheid heeft vooral te maken met evenwicht en gegrond zijn. Voel je je echt verbonden met de aarde, met de wereld waarin je leeft en met de mensen om je heen? Geeft deze verbondenheid je een natuurlijk, vloeiend en dynamisch gevoel? Of zit er een zekere stroefheid in je gedrag en je gevoelens? Gematigdheid en evenwicht zijn onmisbare voorwaarden om een verstandig en spiritueel leven te leiden.</p>",
                    }
                }
            },
            {
                filename: "12",
                nl : {
                    name: "De Gehangene",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>De Gehangene heeft geen speelruimte, hij kan nergens heen, zoveel is duidelijk. Volgens deze kaart word je mogelijk geconfronteerd met een onaangename situatie of omstandigheden. Deze maken het je onmogelijk om je leven van alledag te leiden, zoals je het tot nu toe deed. Misschien ben je depressief of zit je om andere redenen niet goed in je vel. Mogelijk zijn het nog andere dingen die je tegenhouden. Hier zal echter verandering in komen. Denk hieraan op de momenten dat je je slecht voelt in je huidige situatie. Je moet het nu kalm aan doen, maar die “vertraging” geeft je ook de kans om je leven te observeren. Nu kan je gaan begrijpen wie je bent en hoe je in deze situatie terechtgekomen bent. Neem de tijd om jezelf en je situatie grondig te onderzoeken, je zal tot verrijkende en diepgaande inzichten komen!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op het ogenblik een liefdesrelatie hebt dan vertelt De Gehangene je dat je in een situatie zit die je als onaangenaam ervaart. Er hangen spanningen in de lucht, die niet duidelijk genoeg zijn verwoord of die niet door beiden even belangrijk gevonden worden. Misschien vind je de frustraties van je geliefde niet belangrijk genoeg of omgekeerd.</p><p>Indien je geen relatie hebt, is het mogelijk dat je te krampachtig op zoek bent naar een geliefde. De situatie vraagt je om naar binnen te keren en jezelf in de ogen te kijken. Vraag je eerlijk af wat je werkelijk wil en verwacht van een relatie, dat is de eerste stap.</p>",
                        [GuidanceFields.WORK] : "<p>De Gehangene confronteert je met het feit dat je vastzit. Misschien vind je je baan niet meer leuk, misschien is er een taak of project waarin je maar niet tot resultaten komt. Probeer je situatie objectief te bekijken en beslis dan: misschien wil je afwachten of de dingen vanzelf veranderen, of wil je actief voor die verandering gaan. Deze beslissing ligt helemaal in jouw handen. En uiteindelijk zullen de dingen veranderen, ook al gaat dat misschien niet zo snel als je zou willen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Het is goed mogelijk dat je je op een kruispunt bevindt. Nu is het ideale moment om te ontspannen, achterover te leunen en zowel je situatie als je visie op het leven te observeren. Wil je blijven zoals je bent of wil je veranderen? Bedenk dat alles wat je voelt en doet van invloed is op de realiteit. Misschien wil je sommige negatieve of beperkende gedachten en denkwijzen wel loslaten. Probeer je beeld van jezelf en je leven zoals jij het echt wil te visualiseren. Als je dit geregeld doet, zal je merken dat je echt actief impact hebt op de vorm die jouw toekomst aanneemt.</p>",
                    }
                }
            },
            {
                filename: "13",
                nl : {
                    name: "De Dood",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Opdat er iets nieuws zou kunnen ontstaan, moet er eerst iets ouds afsterven. Een mooi voorbeeld hiervan vind je in de natuur: in de herfst vallen de verkleurde stervende bladeren van de bomen af. Ze maken zo plaats voor de frisse groene blaadjes die in de lente zullen groeien.</p><p>Dit is wat deze kaart betekent: een belangrijk aspect van het leven is afscheid nemen, loslaten wat je niet langer nodig hebt. Soms kunnen zo’n veranderingen je enorm verdriet doen omdat je mensen en dingen die je vertrouwd zijn, moet laten gaan. Zonder afscheid kan er echter geen verandering zijn. Daarom is het belangrijk om het verdriet te aanvaarden. De pijn zal uiteindelijk vervagen en plaats maken voor mooie nieuwe ontmoetingen en ervaringen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In een liefdesrelatie kan De Dood het einde van een hoofdstuk of een fase inluiden, een evolutie waar je samen doorheen moet. Soms kan deze kaart ook het einde van een relatie betekenen, maar meestal staat ze voor het waarmaken van gezamenlijke groei en evolutie, waardoor je samen je relatie naar het volgende niveau tilt. Als dit toch niet lukt en je stevent af op een breuk, sta er dan niet te lang bij stil, luister naar je intuïtie als die je vertelt om los te laten.</p><p>De Dood kan ook betekenen dat vrienden die je al erg lang kent en waar je altijd op gerekend hebt, langzaam uit je leven zullen verdwijnen om plaats te maken voor nieuwe kennismakingen.</p>",
                        [GuidanceFields.WORK] : "<p>Misschien vind je je baan geen uitdaging meer, of mogelijk ben je al een tijdje aan het overwegen om er weg te gaan. Bekijk al je opties want misschien is vandaag wel de juiste dag om dingen te veranderen. Je levenskwaliteit kan er enorm op vooruitgaan dankzij een nieuwe start!</p><p>De Dood kan ook het einde inluiden van een bepaalde taak of project, waardoor er ruimte komt om aan iets nieuws te beginnen. Zelfs al heb je het gevoel dat je \"er bijna vanaf bent\", probeer toch om geconcentreerd te blijven op je taak of project tot het echt helemaal afgewerkt is.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Verandering gaat niet alleen over het voelen van de pijn van een afscheid maar ook over loslaten, je emotioneel en spiritueel vrijmaken van wat voorbij is. Let erop dat je niet verdrinkt in verdriet en droefheid, en als je toch het gevoel hebt dat je erin blijft vastzitten, zoek dan hulp. Er zijn echt wel voldoende mensen in je omgeving die voor je klaarstaan om je uit je negatieve stemming te trekken. Als je te lang blijft hangen in je verdriet dan heb je eigenlijk enkel een nieuwe manier gevonden om je te verzetten tegen verandering.</p>",
                    }
                }
            },
            {
                filename: "14",
                nl : {
                    name: "Gematigdheid",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart concentreert zich op evenwicht, gematigdheid, harmonie, aanvaarding en innerlijke rust, niet enkel in jezelf maar ook in je relaties. Als je innerlijk in evenwicht bent en goed voor jezelf zorgt dan kan je op de best mogelijke manier genieten van vrede en vreugde.</p><p>Wanneer je deze kaart trekt dan is het goed om even stil te staan bij je relaties: zijn er mogelijk problemen of spanningen die in de weg staan van harmonie? Wat kan je eraan doen om dit te veranderen? Vraag je ook af wat je kan doen om evenwicht terug te brengen in elk aspect van je leven.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart symboliseert dat je op dit ogenblik waarschijnlijk aan het genieten bent van een vredige, ontspannen en harmonieuze tijd in je relatie(s). Waarschijnlijk is deze fijne periode voorafgegaan door een drukke, verwarrende tijd waarin je erg veel emoties moest verwerken. En misschien heb je dankzij het verwerken van deze gevoelens een aantal nieuwe inzichten verworven. Neem er nu ruim de tijd voor om deze inzichten te laten rijpen en doordringen.</p><p>Gematigdheid kan ook een aanwijzing zijn voor het feit dat je klaar bent om een stap verder te gaan in je relatie, vooral wat engagement en toewijding betreft.</p>",
                        [GuidanceFields.WORK] : "<p>Volgens Gematigdheid is het zo dat je op dit moment de taken op je werk uitvoert met een zekere berusting. Dit kan er enerzijds op wijzen dat je aan het werken bent in een rustige en harmonieuze atmosfeer, maar anderzijds kan het ook betekenen dat je een beetje apathisch aan het worden bent, misschien zelfs wat moedeloos. Als dat laatste het geval is dan is het best mogelijk dat je de verbinding met jezelf en je werk een beetje kwijt bent, en dat zowel de kwaliteit van je werk als je creativiteit daaronder te lijden hebben.</p><p>Deze kaart kan ook betekenen dat, zelfs al duurt het nog even voor men je dat laat weten, je goede werk niet onopgemerkt blijft. Er hangen complimentjes in de lucht, geef de moed niet op!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Gematigdheid spoort je aan om te mediteren, naar je innerlijke kern te gaan. Het is best mogelijk dat alles wat je leven en je relaties betreft van een leien dakje loopt. Toch kan het nodig zijn om diepere persoonlijke problemen en vragen te bekijken. Op die manier kan je er achter komen of er iets in de weg staat van je ontwikkeling en groei. Als je problemen ontdekt en ze aanpakt en verwerkt, zal je merken dat je de inzichten die je hieruit haalt gemakkelijk kan integreren en gebruiken. En daardoor zal je je evenwicht vinden, zowel binnenin jezelf als in je relaties en andere aspecten van je leven.</p>",
                    }
                }
            },
            {
                filename: "15",
                nl : {
                    name: "De Duivel",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Van alle Tarotkaarten is deze het moeilijkst om te interpreteren. De Duivel betekent immers voor iedereen iets anders en ziet er daarom voor iedereen ook anders uit. Toch zijn er wel een aantal algemene kenmerken van “duivelse” invloed: lusteloosheid, afhankelijkheid, geen initiatief kunnen nemen. Vaak vertelt deze kaart je dat je met vuur aan het spelen bent en dus goed moet opletten dat je je niet brandt. Mogelijk heb je ook het gevoel dat je vastzit in een bepaalde situatie. En toch, misschien zonder het te weten, draag je de oplossing diep in jezelf.</p><p>Hoe ernstig en moeilijk je situatie ook is, je hebt altijd opties, mogelijkheden. Zelfs als de mensen om je heen je vertellen dat er maar één uitweg is, dan nog is het belangrijk dat je grondig nagaat of je niet toch nog een andere optie kan vinden. Probeer om niet in beperkingen te denken maar in mogelijkheden, dat is dé manier om De Duivel te verschalken!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De verleidingen van De Duivel zijn opwindend en vol passie, en dat kan natuurlijk erg leuk zijn. Toch is er altijd een negatieve kant aan. Met vuur spelen kan heel verleidelijk zijn maar dat vuur kan alles verslindend worden, vernietigend. De Duivel vertelt je dat het altijd goed is om eens objectief te kijken naar wat je aan het doen bent.</p><p>Als je een langdurige relatie hebt dan is het best mogelijk dat jij zelf of je partner (of mogelijk allebei) je gevangen voelt, verveeld, moe. Probeer hierover te praten, zo kan je de situatie veranderen en hopelijk de relatie redden.</p><p>Als je single bent en op zoek naar liefde dan is het best mogelijk dat je wanhopig aan het worden bent. Denk eraan dat wanhoop erg onaantrekkelijk is en doe er alles aan om je anders te voelen zodat je ook anders overkomt.</p>",
                        [GuidanceFields.WORK] : "<p>De Duivel wil dat je inzicht krijgt in je motieven. Ben je misbruik aan het maken van je collega's goedgelovigheid of goede wil? Ben je je collega's of zakenpartners aan het misleiden? Observeer jezelf, wees eerlijk, oordeel of veroordeel niet en als je inderdaad fout zit, beslis dan om het in de toekomst anders te doen. Je wil jezelf tenslotte elke dag in de spiegel in de ogen kunnen kijken.</p><p>Het is ook mogelijk dat De Duivel je erop wijst dat je je voelt vastzitten in je job, dat je wat je doet misschien zelfs haat. Kijk open en objectief naar je situatie: is de zekerheid van deze job voldoende belangrijk voor je om in een situatie te blijven waar je echt een hekel aan hebt?</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Kijk eens om je heen, analyseer de mensen waar je je mee omringt, zowel privé als op het werk. Zijn het overwegend positieve, energieke, stimulerende mensen? Of heb je vooral negatieve, cynische, bekritiserende mensen om je heen die dus ook een negatieve invloed hebben op jou en je zelfbeeld?</p><p>Zorg ervoor dat je vooral omgaat met positieve mensen, die je energie en inspiratie geven, je een goed gevoel bezorgen. Gebruik affirmatietechnieken, meditatie, ... alles wat kan helpen om een positieve instelling te krijgen en te behouden.</p>",
                    }
                }
            },
            {
                filename: "16",
                nl : {
                    name: "De Toren",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Je kan de betekenis van De Toren op vele manieren omschrijven en vaak lijken de termen die dan gebruikt worden met mekaar in tegenspraak. Deze kaart kan bijvoorbeeld staan voor een plots inzicht dat je krijgt maar ook voor een totaal gebrek aan inzicht. Het hangt er gewoon van af in welke situatie je je bevindt, waar je in De Toren staat (bovenaan, onderaan of misschien halfweg?).</p><p>De kaart kan ook een pijnlijke ervaring symboliseren, een schok, een gevoel alsof je hard wakker geschud wordt.</p><p>Waar het op neerkomt is dat De Toren je inzicht wil bieden in je eigen illusies. Je kan wel denken dat er een aantal zekerheden in je leven zijn, maar soms word je ermee geconfronteerd dat die zekerheden plots van je weggenomen worden. Je houdt of hield je eigenlijk vast aan een illusie van veiligheid, dàt wil De Toren je vertellen. Ook al is het schokkend om te beseffen dat niets eigenlijk zeker is, toch kan het ook behoorlijk bevrijdend zijn om je illusies los te laten. Je opent immers de weg voor een boel nieuwe mogelijkheden!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Toren kan wijzen op het einde van een relatie al is dat niet altijd zo. Als je er volkomen zeker van bent dat je bij je partner wil blijven, dan is het nu het juiste moment om alle problemen en obstakels te bestuderen en aan te pakken. Neem ook de tijd om er achter te komen wat je partner echt wil en beslis dan of je met die wensen en verlangens wil en kan leven.</p><p>De Toren symboliseert soms een verandering van denken, handelen of gedrag in je relatie. Misschien heb je je vastgeklampt aan je partner of hem/haar geclaimd. Dat kan betekenen dat je niet in volle vrijheid beslist hebt om je te engageren in de relatie omdat je bang was om je partner te verliezen, om verlaten te worden. Soms vertelt De Toren je dat je er klaar voor bent om je voorzichtig te gaan openstellen voor een nieuwe relatie, ook al ben je intens gekwetst geweest in een vorige verbintenis.</p>",
                        [GuidanceFields.WORK] : "<p>Maak gebruik van de hoogte van De Toren om naar de top te klimmen en van daaruit een overzicht te krijgen over je carrière. Het is best mogelijk dat de dingen er helemaal anders uitzien en niet datgene blijken wat je eerst dacht.</p><p>Deze kaart wijst erop dat je mogelijk risico loopt op ontslag, dat je verwachtingen niet tegemoetgekomen worden of dat je carrière echt \"een ruïne\" is. Neem de tijd om uit te zoeken wat je zou doen als je je huidige baan zou verliezen, tot wie zou je je wenden, welke bronnen zou je gebruiken om een andere baan te vinden? Tenslotte is een gewaarschuwd mens er twee waard.</p><p>Mogelijk word je op het moment op het werk geconfronteerd met conflicten. Probeer om niet te gevoelig te zijn voor wat mensen tegen je zeggen en doe niet mee met discussies, of ze nu verhit zijn of niet.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Volgens De Toren is het tijd voor verandering, om illusies en fantasieën los te laten. Leven is tenslotte bewegen, transformeren en dat proces kan je niet stoppen, zelfs al zou je het proberen. Wees verstandig en ga mee met de stroming, kijk waar je terechtkomt en zorgt dat je instelling positief is. Het is best mogelijk dat sommige van je overtuigingen vernietigd moeten worden om ruimte te maken voor frisse, nieuwe inzichten. Probeer om te genieten van dit gebeuren!</p>",
                    }
                }
            },
            {
                filename: "17",
                nl : {
                    name: "De Ster",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart staat voor hoop, inspiratie, wijsheid en diepe inzichten. De Ster ligt er om je te vertellen dat je harde werk vruchten zal afwerpen tot ver in de toekomst. Je werkt niet enkel voor jezelf maar ook voor het welzijn van alle betrokkenen. De Ster is, samen met De Hogepriesteres en De Hogepriester, een kaart die bescherming belooft. Voel je vrij om hoop te hebben voor de toekomst en geloof erin dat je datgene zal krijgen wat je nodig hebt, niet enkel op materieel vlak maar ook spiritueel en emotioneel. Denk ruim, denk positief!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In verband met relaties staat De Ster voor ontmoetingen. Misschien komt er een nieuw iemand op je weg, maar het is ook mogelijk dat je iemand die je al kent in een totaal ander licht zal gaan zien. Deze ontmoeting waarvan sprake is niet zomaar oppervlakkig en voorbijgaand maar zal je tot ver in de toekomst bijblijven. Relaties die in verband staan met De Ster ontwikkelen zich vaak onder een goed gesternte. Of je nu een nieuwe ontmoeting hebt of je vindt nieuwe dimensies in je huidige partner, het zijn fantastische tijden op relatievlak.</p>",
                        [GuidanceFields.WORK] : "<p>Volgens De Ster begin je binnenkort aan een nieuwe taak of zelfs aan een nieuwe baan. Het is ook mogelijk dat projecten of plannen die jij voorgesteld hebt, nu eindelijk uitgevoerd worden.</p><p>Als je niet tevreden bent met je huidige job dan is het nu de perfecte tijd om op zoek te gaan naar een andere. Het is wel belangrijk dat je open staat en klaar bent voor nieuwe kansen. Zorg dat je zin hebt in de uitdaging, poets je CV op, bestudeer sollicitatietechnieken...</p><p>Als je De Ster trekt dan zijn je vooruitzichten over het algemeen erg positief.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De Ster vertelt je dat je oprecht en diepgaand contact hebt met je spirituele kant. Mede daarom is het een perfecte tijd om de hand te reiken naar de mensen om je heen en ook echt contact te maken. Je hebt veel wijsheid, gelijkmoedigheid, vreugde, warmte te delen. En terwijl je dat allemaal aan het uitdelen en delen bent, zal je merken dat je telkens weer beloond wordt voor je vrijgevigheid.</p><p>Volgens De Ster ben je er klaar voor om je innerlijke licht te laten schijnen op de mensen om je heen.</p>",
                    }
                }
            },
            {
                filename: "18",
                nl : {
                    name: "De Maan",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>De Maan spoort je aan om de geheimzinnige wereld van je geest en je intuïtie te onderzoeken. De heldere kant van De Maan staat voor romantische dromen, levendige fantasieën en diepgaande emoties. De donkere kant staat voor krachten binnenin je zoals angsten, onzekerheid, huiverigheid om onbekende dingen te onderzoeken. Die laatste zijn allemaal zaken die jou, misschien zelfs onbewust, beperken en tegenhouden. Geconfronteerd worden met je lichte èn donkere kant kan je uit evenwicht brengen, je verwarren. Op dit moment hebt je het misschien moeilijk om niet alleen jezelf te begrijpen maar ook de mensen om je heen. Zet jezelf en anderen niet teveel onder druk maar neem de tijd om terug in evenwicht te komen en de nodige inzichten te verwerven.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wat je relaties betreft vertelt De Maan je dat je op het ogenblik de neiging hebt om jezelf te verliezen in romantische dromen en het bouwen van luchtkastelen. Je dwaalt nogal ver af van de realiteit, misschien omdat je diep vanbinnen onzeker en angstig bent.</p><p>De Maan kan ook staan voor relaties die bepaald of gekleurd worden door jaloezie en verlatingsangst. Het is nu geen goede tijd om belangrijke beslissingen te nemen in verband met je relatie(s), of het er nu om gaat een relatie te beëindigen of om er één te beginnen. Probeer in elk geval je partner in eerste instantie te behandelen als vriend(in), met het volste vertrouwen in zijn/haar goede bedoelingen.</p>",
                        [GuidanceFields.WORK] : "<p>De Maan vertelt je dat er misschien een reden is om angstig of onzeker, ongedurig te zijn. Het is mogelijk dat je er niet zeker van bent dat je een bepaalde taak wel kan afwerken, je bent bang om doorzien te worden, of je begint te beseffen dat je niet zo goed bent als je dacht te zijn. Eén ding is een feit: je kan niet weglopen van deze angsten. Haal eens diep adem, (h)erken je angsten en pak ze aan. Analyseer de situatie en probeer te ontdekken of er echt dingen zijn waar je bang voor hoort te zijn of dat je die angsten misschien helemaal zelf gecreëerd hebt.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Volgens De Maan maak je momenteel een heel spirituele fase door. Het is nu de juiste tijd om je kaarten te laten leggen, een Reikihealing te krijgen, een Chakrabehandeling, ... Op spiritueel vlak ben je open en alert, je neemt de tijd om aandacht te besteden aan je gevoelens, gedachten en ideeën. Je bent ook erg gevoelig voor boodschappen die je doorkrijgt vanuit spirituele of zelfs onbekende bron. Als je beslissingen moet nemen of mensen en relaties moet evalueren, ga dan mee met je buikgevoel, vertrouw je intuïtie en je instinct.</p>",
                    }
                }
            },
            {
                filename: "19",
                nl : {
                    name: "De Zon",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Koester je in De Zon en je voelt warmte, energie, optimisme en vreugde. De Zon laat haar licht schijnen op jouw donkere kanten zodat jij meer duidelijkheid krijgt over je zorgen en frustraties. En zo krijg je meer ruimte om de positieve kanten van het leven te ervaren. De Zon motiveert je, ze moedigt je aan om haar licht in je leven te laten schijnen, om nieuwe dingen uit te proberen, even stil te staan en te genieten, om je te ontspannen. Denk even wat minder aan materiële zaken maar laat je vooral leiden door het niet tastbare, door vriendschap, liefde, positieve energie. Geniet van De Zon en je zal merken dat je je vrijer voelt, levendiger en energieker. Nu is het juiste moment om het leven van de zonnige kant te bekijken. Laat het zonlicht binnen!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Zon symboliseert de warmte en vreugde die het je geeft als je in een toegewijde relatie zit. De kaart staat voor onvoorwaardelijke liefde, we geven en blijven geven zonder dat we er iets voor in de plaats willen. Nu is het echt waarschijnlijk dat je de diepste wensen en verlangens van je partner vervult.</p><p>Maar let erop dat je niet te aanhankelijk of behoeftig wordt, want je partner heeft op dit ogenblik echt veel ruimte nodig. En als je eerlijk bent met jezelf, dan weet je dat jij af en toe ook wel wat ruimte kan gebruiken.</p>",
                        [GuidanceFields.WORK] : "<p>De Zon vertelt je dat je op het ogenblik echt kan genieten van je werk. En er is meer dan dat, je bent waarschijnlijk ook erg succesvol. Je straalt positieve energie uit en je vriendelijkheid helpt je om je doelen te bereiken op een natuurlijke en spontane manier. Zorg ervoor dat je erg dicht bij je gevoelens en instincten blijft, want anders zou je een beetje hypocriet, onoprecht kunnen worden. En gelijk hoe fantastisch de dingen voor jou aan het lopen zijn, houd je ego onder controle door je succes te delen met je collega's.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De Zon nodigt je uit om spiritueel \"met vakantie\" te gaan, om rond te kijken en allerlei verschillende benaderingen en interpretaties uit te proberen, en dit in verband met alles dat te maken heeft met de ware zin van het leven. Het is ook mogelijk dat De Zon je vertelt dat je op dit moment echt doorhebt hoeveel schoonheid en vreugde het leven je kan brengen, het enige wat een mens daarvoor moet doen is echt rondkijken en zo deze dingen ontdekken.</p><p>Deel je ontdekking met de mensen om je heen, dan kunnen zij ook een fantastische tijd hebben. Zorg ervoor dat je oog blijft hebben voor die dingen die echt kwaliteit toevoegen aan je leven.</p>",
                    }
                }
            },
            {
                filename: "20",
                nl : {
                    name: "Het Oordeel",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Denk bij deze kaart niet aan veroordeeld worden en straf krijgen, daar gaat het hier niet om. In tegendeel, Het Oordeel vertelt je over heropstanding. Je zet die belangrijke stap naar zelfrealisatie en transformatie, je groeit van lage naar hogere delen van je bewustzijn. Je zal je steeds bewuster worden van deze verandering en merken dat je minder angsten en zorgen hebt. Je gaat de dagelijkse uitdagingen nu aan met moed, creativiteit en vastberadenheid.</p><p>Maar er is ook een andere kant aan Het Oordeel: misschien ben je te snel met oordelen en conclusies trekken. Als dat zo is, doe het dan iets rustiger aan en geef jezelf en anderen meer dan slechts die ene kans.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je al een relatie hebt dan vertelt Het Oordeel je dat een allesbepalend moment aangebroken is. Wees eerlijk en open over alles wat je wil van de relatie en probeer om je verlangens waar te maken.</p><p>Als je op zoek bent naar een relatie dan vertelt het Oordeel je om voorzichtig te zijn bij het beoordelen van een potentiële partner, of het nu negatief of positief is. Over het algemeen zal je merken dat je aan het veranderen bent in de relatie, je bent je angsten aan het overwinnen en dichter bij je Ware Zelf aan het komen. Dit helpt je ook om toenadering te vinden tot je geliefde(n) en brengt je intense vreugde.</p>",
                        [GuidanceFields.WORK] : "<p>Het Oordeel staat voor een erg belangrijke fase als het over werk en carrière gaat. Verandering komt alsmaar dichterbij, of deze nu veroorzaakt wordt door jou of door je meerderen. Als je hard werkt zal dat zeker zijn vruchten afwerpen, maar als je niet hard werkt, begin er dan onmiddellijk mee.</p><p>Bij deze kaart gaat het heel vaak over keuzes en de gevolgen van de keuzes die je maakt, of je dat nu bewust of onbewust doet.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Het Oordeel herinnert je eraan dat het belangrijk is om open te staan voor nieuwe denkwijzen, nieuwe ervaringen. Het leven draait om verandering en dat geldt ook voor het spirituele leven.</p><p>Dit betekent niet dat je onmiddellijk je overtuigingen of interessegebieden moet veranderen, maar een beetje rondkijken kan echt geen kwaad. Het kan je zelfs helpen om waarheden te ontdekken waarvan je niet eens wist dat ze bestonden. Sta open voor verandering, voor het ongewone, het zal je helpen groeien.</p>",
                    }
                }
            },
            {
                filename: "21",
                nl : {
                    name: "De Wereld",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Als je deze kaart krijgt dan heb je misschien het gevoel dat je het gewicht van De Wereld op je schouders draagt. Denk eraan dat je niet alleen bent, er zijn mensen om je heen die je graag willen helpen met het dragen van je last. En zoals je eigenlijk wel weet: gedeelde last is halve last!</p><p>Vaak betekent deze kaart ook dat je je plek gevonden hebt, waar dat ook is. Je hebt een gevoel van harmonie, je voelt de vreugde van het succesrijk afronden van een taak, een activiteit, een opdracht. Je hebt een belangrijke stap gezet in de richting van “echtheid”, je voelt je \"geheel\".</p><p>Deze kaart vertegenwoordigt bijna altijd mooie en vreugdevolle tijden.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Volgens deze kaart voel je je helemaal thuis in je huidige relatie. Je partner is niet zomaar een voorbijganger, in tegendeel! Zij/hij is ontzettend belangrijk voor je levenskwaliteit en je ontwikkeling. De manier waarop jullie mekaar spiegelen, helpt om je bewustzijn te laten groeien.</p><p>Als je op zoek bent naar een partner dan is het goed mogelijk dat je iemand zal vinden als je tamelijk ver weg van huis bent, bijvoorbeeld als je met vakantie gaat.</p>",
                        [GuidanceFields.WORK] : "<p>De Wereld vertelt je dat je je goed thuis voelt op je werkplek. Je vindt je job echt leuk en je bent er ook goed in.</p><p>Toch is het misschien tijd om je af te vragen of je talenten en capaciteiten echt wel erkend en beloond worden. Als je beseft dat dit niet het geval is, vraag dan hulp van je omgeving om een andere baan te vinden.</p><p>Als je tevreden bent en op dezelfde plek wil blijven, is het waarschijnlijk dat de tijd rijp is om je verder te ontwikkelen, te groeien. Misschien kan je een cursus volgen of andere of bijkomende taken vragen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Als je De Wereld krijgt dan kan dit betekenen dat je dichterbij aan het komen bent bij wie je echt bent. Je kan geregeld nieuwe inzichten en realisaties krijgen. Toch is het mogelijk dat deze ervaringen uitdagend en uitputtend zijn, dus misschien moet je wat vrije tijd nemen om je nieuwe inzichten te verwerken.</p><p>Probeer niet om dit alleen te doen maar deel je ervaringen met anderen, praat erover, maak deze reis niet in je eentje. Deze reis is tenslotte even belangrijk als het bereiken van je doel, dus bewandel dit (levens)pad in goed gezelschap!</p>",
                    }
                }
            },
            {
                filename: "22",
                nl : {
                    name: "Staven Aas",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>In de Tarot is een Aas meestal een symbool voor kansen en mogelijkheden en heeft de kaart dus vaak een positieve betekenis.</p><p>De Staven zijn een Vuurteken wat betekent dat de mogelijkheden waar de Aas op wijst over het algemeen te maken hebben met persoonlijke groei, vreugde en vastberadenheid. Het Vuurelement draait om wat jij wil.</p><p>De Aas van Staven vertelt je dat deze periode ideaal is om je doelen te bereiken. Je voelt je sterk en hebt ook veel overtuigingskracht. Op dit moment ben je waarschijnlijk doelgericht, positief en energiek en voel je je ook vervuld van hoop.</p><p>Groot gelijk dat je je zo voelt! Er is nu bijna niets dat je kan tegenhouden dus grijp alle kansen die op je weg komen!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Aas van Staven is symbolisch voor levendigheid in je liefdesrelatie maar ook voor het enthousiasme dat je deelt met de mensen die je dierbaar zijn. Je voelt je echt goed en je straalt daardoor positieve levenskracht uit. Je bent je ten volle bewust van de oude wijsheid: het gevoel dat jij uitstraalt zal driedubbel naar je terugkomen. Als je op dit moment single bent dan is het heel waarschijnlijk dat je binnenkort een potentiële partner zal ontmoeten. Zorg ervoor dat je door je gedrag en uitstraling duidelijk maakt dat je beschikbaar bent.</p><p>Over het algemeen is dit een goede periode om je te uiten, zichtbaar te zijn, of je nu een relatie hebt of niet.</p>",
                        [GuidanceFields.WORK] : "<p>Wat werk betreft ben je op dit moment echt bereid om risico’s te nemen. Je passie en ambitie zijn je leidraad en je prestaties worden gekenmerkt door vuur en energie. Als je behoefte hebt aan groei, grijp dan nu de kans om je meerdere aan te spreken, hij/zij zal onder de indruk zijn van je uitstraling.</p><p>Als je op zoek bent naar werk dan zal je merken dat er grote kansen en mogelijkheden op je weg komen. Aarzel niet om te streven naar wat je echt wil. Er is een grote kans dat je succesvoller zal zijn in je streven dan je ooit verwacht had.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Herinner jezelf er geregeld aan dat de verbinding tussen lichaam en geest intenser en intiemer is dan je eigenlijk denkt. Er is sprake van een constante interactie tussen de twee, je lichaam voelt wat je geest denkt en omgekeerd. Besteed hier voldoende aandacht aan.</p><p>Als je deze kaart krijgt dan betekent het dat er op spiritueel vlak binnenkort een nieuw iets of iemand in je leven zal komen. Heb je spirituele “helden”, mensen naar wie je opkijkt? Als dat niet het geval is, dan is het nu een goed moment om ernaar op zoek te gaan. Probeer iemand te vinden die je leiding kan geven, je kan gidsen en helpen in je groei en verandering. Stel je open voor nieuwe mogelijkheden, je zal aangenaam verrast worden door wat er allemaal op je weg komt.</p>",
                    }
                }
            },
            {
                filename: "23",
                nl : {
                    name: "Staven Twee",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Het is best mogelijk dat je het op dit moment moeilijk vindt om in evenwicht te blijven. Misschien heb je een tamelijk onverschillige houding aangenomen, keer je je af van het leven, geef je weinig of niets om mensen en dingen. Je ervaart alles alsof het maar “zo zo” is en zo stel je je ook op naar de mensen om je heen en de rest van de wereld. Het kan erop lijken dat je neutraal bent, dat je je niet laat meeslepen door je emoties, maar is dat wel echt het geval? Misschien is het eerder zo dat je je bijna verlamd voelt, dat het onmogelijk voor je lijkt om echt geraakt te worden door iets of iemand.</p><p>Best mogelijk dat je het veilig en comfortabel vindt om je onverschillig en neutraal op te stellen, maar misschien is nu de tijd gekomen om een standpunt in te nemen en om alles wat er door je heen gaat ook weer echt te gaan voelen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart vertelt je dat je je afschermt van de mensen om je heen. Het lijkt alsof niets je echt kan raken, je bent afstandelijk. Zelfs de liefde interesseert je nu even niet, alsof gevoelens en emoties nu geen belang hebben. Ga eens voor de Spiegel staan en kijk naar jezelf, neem er de tijd voor. Stel jezelf de vraag wat echt belangrijk voor je is in een liefdevolle relatie: is het begrip, (h)erkenning, warme armen om je heen? Als het antwoord ja is, laat dan je afweer los.</p><p>Als je single bent is het mogelijk dat er iemand in je omgeving interesse voor je heeft als potentiële partner. Je eerste reactie is om die persoon af te wijzen, de optie weg te wuiven. Maar misschien neem je beter even de tijd om deze persoon wat beter te leren kennen. Je zou kunnen ontdekken dat wat zij/hij te bieden heeft veel beter bij je past dan je gedacht had.</p>",
                        [GuidanceFields.WORK] : "<p>Op een bepaalde manier is het misschien moeilijk voor de mensen om je heen om te begrijpen of je nu onverschillig bent over je werk of dat je juist heel hard probeert om neutraal te blijven. Ben jij tevreden met de positie die je inneemt? Doe je een beetje alsof of toon je echt wie je bent en wat je voelt?</p><p>Als je op dit moment op zoek bent naar werk dan zit je waarschijnlijk in een positieve flow en hoef je niet veel energie te investeren in je zoektocht. Onderschat jezelf niet, wat werk zoeken betreft zit je op dit moment heel goed in evenwicht!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Deze kaart heeft het over spiritueel evenwicht. De meest intense en unieke spirituele relatie die je ooit in je leven zal hebben is de relatie met jezelf. Net daarom is het belangrijk dat je er altijd aan denkt om voldoende tijd en energie te besteden aan het goed voor jezelf zorgen en dat niet enkel op spiritueel vlak maar ook emotioneel. Waarschijnlijk komt er binnenkort iemand op je pad waarmee je een positief en opbouwend spiritueel contact zal hebben dat je enorm veel vreugde en verrijking zal brengen. Wees aandachtig, observeer iedereen die je ontmoet zodat je deze persoon niet over het hoofd ziet.</p>",
                    }
                }
            },
            {
                filename: "24",
                nl : {
                    name: "Staven Drie",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Het was een lange en soms zware reis maar op dit moment kan je erop terugkijken met een tevreden gevoel van voleinding, het is volbracht! Je bevindt je exact op de plek waar je wil zijn en je voelt je sterk, je hebt alles onder controle.</p><p>Neem de gelegenheid te baat om uit te rusten en naar je omgeving te kijken met nieuwe ogen. Dit is een goede tijd om na te denken over je toekomst en realistisch te gaan plannen wat je wil bereiken.</p><p>Je bent nu perfect in staat om alle juiste beslissingen te nemen. Dit betekent ook dat je geen grote obstakels op je weg hoeft te verwachten. Je hebt voor jezelf een stevige basis gelegd en je kan nu beginnen met bouwen aan je toekomst.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Waarschijnlijk gaat het op dit ogenblik erg goed met je liefdesrelatie. Neem toch de tijd om te bekijken of je privéleven en je carrière wel goed in evenwicht zijn. Het is immers mogelijk dat je tegenwoordig de neiging hebt om wat teveel tijd in je werk te stoppen. En door (misschien kleine) aanpassingen door te voeren kan je ervoor zorgen dat je een gezonde en harmonieuze omgeving creëert waarin jij en je partner zorgeloos samen kunnen groeien.</p><p>Als je op dit moment alleen bent, is het heel waarschijnlijk dat je binnenkort iemand zal ontmoeten. Zorg ervoor dat je voldoende aandacht besteedt aan deze ontmoeting, spring er niet te zorgeloos mee om.</p>",
                        [GuidanceFields.WORK] : "<p>Als je op zoek bent naar werk dan is dit de perfecte periode om een baan te vinden. Wees niet te bescheiden, benader interviews met een gezonde dosis zelfvertrouwen en zorg ervoor dat je je ten volle bewust bent van je kwaliteiten en prestaties. Het zit er dik in dat je een job vindt die stukken beter is dan je verwacht had.</p><p>Als je op dit moment aan het werk bent dan heb je waarschijnlijk een belangrijk doel bereikt of een moeilijke taak goed volbracht. Toch is het best mogelijk dat het je oversten nog wat tijd zal kosten om dit te beseffen en te belonen, maak je daar niet druk om. Concentreer je nu op plannen voor de langere termijn.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Je hebt al een duidelijke en uitgebreide visie op spiritualiteit maar toch is het nu het goede moment om wat rond te kijken en op onderzoek uit te gaan. Je spirituele evolutie is immers een voortdurende reis, de weg draait om groei en verandering.</p><p>Aarzel niet als je merkt dat je opvattingen en overtuigingen aan het veranderen zijn, omarm de nieuwe ontdekkingen. Verandering is noodzakelijk en natuurlijk. Stel jezelf de vraag waar je op spiritueel niveau binnen een paar jaar wil zijn. Ben je ertoe bereid om jezelf de ruimte te geven die doelen ook te bereiken?</p>",
                    }
                }
            },
            {
                filename: "25",
                nl : {
                    name: "Staven Vier",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart draait om gezelligheid, vreugde, geluk, genieten en openheid. Eigenlijk is de essentie gewoon om een fijne tijd te hebben. Zorg ervoor dat je in het gezelschap bent van al je geliefden als je deze mooie tijden meemaakt, doe het niet alleen. Op dit moment zijn er eigenlijk geen zorgen, is er geen verdriet dat je in de weg staat, je kan een vrolijke en positieve kijk op de wereld hebben.</p><p>Misschien liggen er moeilijkere tijden in het verschiet, dat is best mogelijk. Maar op dit moment heb je ruim voldoende kracht en energie om alles wat op je weg komt aan te pakken op een positieve en opbouwende manier. Maar ga nu niet piekeren over wat de toekomst misschien zal brengen, eerst is het tijd voor genieten!</p><p>Soms staat deze kaart ook symbool voor verhuizen of de aankoop van een woning.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op gebied van relaties ben je onderweg naar zonnige tijden. Je voelt je veilig en comfortabel en helemaal thuis met en bij je partner. Het is goed mogelijk dat je een volgende stap aan het overwegen bent, dat je misschien zelfs een verloving of een huwelijk overweegt.</p><p>Als je op zoek bent naar een partner dan is het nu de juiste tijd om alle mogelijke feestelijke gelegenheden op te zoeken. Het is heel waarschijnlijk dat je daar iemand zal ontmoeten die boeiend en aantrekkelijk is en waar je fijne tijden mee kan beleven.</p><p>De Vier van Staven vertelt je dat het op dit moment goed is om je betrokken en toch ontspannen op te stellen naar je geliefden toe. Oefen geen druk uit, wees open.</p>",
                        [GuidanceFields.WORK] : "<p>Wat je werk betreft zou op dit moment alles behoorlijk goed moeten verlopen. Je geniet van je werk en je bent echt gemotiveerd om van elke uitdaging een succes te maken.</p><p>Misschien heb je onlangs een promotie gekregen of ben je aan een nieuwe baan begonnen maar het is ook mogelijk dat zoiets binnenkort zal gebeuren. Wat ook je nieuwe pad moge zijn, je zal er vol enthousiasme aan beginnen.</p><p>Wees toch wat voorzichtig, als je aan de top staat dan betekent dit ook dat je de nodige moeite zal moeten doen om daar te blijven. Geniet van elk moment van elke dag maar zorg ervoor dat je genoeg energie overhoudt om ook morgen de dag door te komen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De keuzes die je maakt om spiritueel te groeien zijn een heel persoonlijke zaak. Het is best mogelijk dat de mensen om je heen jouw spirituele keuzes en zoektochten niet begrijpen of ze zelfs niet goedkeuren. Als je dit vervelend vindt of als je er zelfs onzeker van wordt, denk er dan aan dat er altijd een dieperliggende reden is voor alle keuzes die je maakt, ook al zijn die redenen je op dit moment niet duidelijk. Het is altijd verstandig om naar de mening van anderen te luisteren, maar uiteindelijk is het jouw verantwoordelijkheid om te kiezen en te beslissen. Het gaat erom dat <strong>jij</strong> doet wat goed voelt voor <strong>jou</strong>.</p>",
                    }
                }
            },
            {
                filename: "26",
                nl : {
                    name: "Staven Vijf",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart vertelt je dat het op dit moment in je leven erg belangrijk is dat je oprecht gelooft in jezelf en je mogelijkheden. Toon jouw geloof ook duidelijk aan anderen, gelijk waarover je het hebt.</p><p>Staven Vijf is ook symbolisch voor concurrentie, het kan zelfs om een echte wedstrijd gaan of over een speelse soort competitie, in je privéleven of op het werk. De ware uitdaging ligt niet in het beter zijn dan anderen maar in het beter worden dan je nu bent, je eigen groei, je evolutie. En ook al gaat het hier niet om een negatieve soort van wedijver, bereid je er toch op voor dat je tegenstand kan krijgen. En let erop dat je te allen tijde eerlijk en rechtvaardig blijft.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Bij de Vijf van Staven gaat het vaak over kiezen en het kenbaar maken van je keuze. Het is best mogelijk dat er op dit moment meer dan één persoon geïnteresseerd is in jou als potentiële partner. De keuze ligt nu in jouw handen.</p><p>Het kan ook zijn dat je diepe gevoelens hebt voor iemand maar dat er nog andere geïnteresseerden zijn, je hebt concurrentie. Of misschien is je partner onder de indruk van iemand anders, is er twijfel in zijn/haar hart. Als dit het geval is, dan is het belangrijk dat je vastberaden blijft, wees duidelijk over wie je bent en wat je voor de ander voelt, ga ervoor. Zorg ervoor dat je alle mogelijke kansen grijpt en uitbuit. Staven Vijf is weliswaar een positieve kaart maar dat betekent niet dat het hier enkel om romantische gebaren gaat zoals etentjes bij kaarslicht. Je hebt hier te maken met concurrentie, competitie. Je zal strijd moeten leveren om het volgende stadium van je relatie te bereiken.</p>",
                        [GuidanceFields.WORK] : "<p>Op je werk word je geconfronteerd met een uitdaging en het wordt niet gemakkelijk om er een succes van te maken. Zorg ervoor dat je grondig nadenkt over de weg en de tactieken die je wil gaan gebruiken en je zal merken dat je toch je doel kan bereiken. Bekijk deze uitdaging alsof het een wedstrijd is of een spelletje, iets wat je voor jezelf doet. Kijk er niet naar alsof het iets is waarover andere mensen je zullen beoordelen of veroordelen. Luister wel even naar wat anderen erover te zeggen hebben. Ook al heb je al beslist welke weg je zal volgen, je kan altijd handige tips gebruiken.</p><p>Deze kaart kan wijzen op tijdelijke financiële spanningen. Maak je hier niet te druk om, je situatie zal binnenkort weer veranderen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Het lijkt erop alsof alles in je leven om competitie draait op dit moment. Misschien word je daar overijverig van, soms zelfs een beetje chaotisch omdat je niet weet waar eerst aan te beginnen. Je hebt geen rust in je hoofd en je hart, geen evenwicht. Daarom is het nu erg belangrijk dat je uit alle macht probeert om tot rust te komen, wat te ontspannen. Kijk eens kalm en traag om je heen en probeer te genieten van wat je ziet. Wees eens gewoon lui en laat de oneindige kracht en macht van het universum op je inwerken. En vraag je dan af of je eigenlijk wel weet wat je wil. Praat je met anderen over de doelen die je wil bereiken? Luister je ook naar hun mening? Of vind je het op dit moment erg belangrijk om vast te houden aan je eigen opinies? Spiritueel groeien is geen wedstrijd, we zijn immers allemaal uniek en hebben elk een unieke spirituele weg te gaan.</p>",
                    }
                }
            },
            {
                filename: "27",
                nl : {
                    name: "Staven Zes",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Als je de Zes van Staven trekt dan is dit een heel positief teken. Deze kaart duidt op succes, erkenning en welslagen. Ze symboliseert de tevredenheid die je ervaart als je iets tot een goed einde gebracht hebt. Ze vertelt je dat je moeilijkheden, obstakels en uitdagingen op je weg gekregen hebt en dat je uiteindelijk de top bereikt hebt.</p><p>Deze kaart gaat ook over groeien, evolueren. Ze vertelt je dat je positieve gebeurtenissen mag verwachten, privé of wat je werk betreft. Als je jezelf er bewust voor openstelt dan zullen er aangename veranderingen jouw kant op komen.</p><p>Tenslotte, als je in jezelf en je overtuigingen blijft geloven, dan zal je uiteindelijk succes hebben. En op het moment dat je dat succes ook echt beleeft, is het belangrijk dat je jezelf eens goed in de bloemetjes zet, je hebt het verdiend!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Zes van Staven symboliseert welslagen en eindelijk oogsten wat je gezaaid hebt. Op relationeel vlak betekent dit dat je goed op weg bent om een mooie diepgaande liefdesrelatie te krijgen, mogelijk met iemand die je al een tijdje op het oog hebt. Als je al een lange periode moeite gedaan hebt voor iemand, geef het dan nu niet op want succes is nabij!</p><p>Als je al een relatie hebt, dan komt er binnenkort een intens positieve periode waarin je je dichter bij je partner voelt dan je ooit ervaren hebt. Het is ook mogelijk dat jullie eindelijk dat moeilijke probleem of die langdurige onenigheid opgelost hebben. In elk geval is nu de tijd gekomen om te genieten van wat de liefde en jouw volhouden je gebracht heeft, neem je tijd om van dit succes te proeven!</p>",
                        [GuidanceFields.WORK] : "<p>Als je op dit moment een baan hebt die je absoluut wil houden dan zal je binnenkort merken dat je positie een stuk beter geworden is dan je zelf dacht. Alle energie en inspanningen die je geïnvesteerd hebt in je baan blijken nu succesvol. Je krijgt erkenning en waardering van alle kanten. Misschien heb je ontzettend hard moeten werken om dit punt te bereiken, misschien werd het je soms teveel en dacht je het niet vol te kunnen houden. Maar je wordt er nu ook uitbundig en intens voor beloond, geniet ervan!</p><p>Als je op dit moment op zoek bent naar een baan dan wijst deze kaart erop dat je een job zal vinden die een stuk fijner en passender voor je is dan je ooit had kunnen denken.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op het gebied van spiritualiteit vertelt deze kaart je dat je op de goede weg bent om binnenkort een intense liefde voor het leven en de wereld te ervaren. Erken deze diepe liefde, maak er contact mee en deel ze met iedereen om je heen. Je hebt op dit moment heel veel te bieden.</p><p>Neem nu ook eens de tijd om na te denken over de weg die je diep vanbinnen wil gaan kiezen. Voel je je vrij en sterk genoeg om helemaal op jezelf te vertrouwen? Ben je er klaar voor om jouw eigen unieke pad te gaan volgen?</p>",
                    }
                }
            },
            {
                filename: "28",
                nl : {
                    name: "Staven Zeven",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>De Zeven van Staven wijst je erop dat je misschien een tegenstander hebt en dat je het gevoel kan hebben dat die tegenstander sterker en groter is dan jij bent. Het is mogelijk dat die vijand effectief iemand in je omgeving is maar het kan ook dat hij eigenlijk diep in jezelf verborgen zit, dat je je eigen grootste vijand bent op dit moment. En ook al lijkt die (innerlijke) vijand sterker dan jij, geef het toch niet op, verdedig jezelf. Je strijdlustige geest zal je helpen om ver te komen, mogelijk behaal je zelfs een overwinning. Nu is niet het moment om je te laten beïnvloeden door twijfel aan jezelf, want dat is namelijk je grootste innerlijke vijand, trap er niet in! Het gaat hier niet enkel om macht en controle, maar vooral over de kracht van liefde, inzicht en wijsheid.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart vertelt je dat het mogelijk is dat je een moeilijke periode doormaakt in je relatie. Misschien is er zelfs een derde persoon bij betrokken, aan jouw kant of bij je partner. Praat met je geliefde, vertel hem/haar open en liefdevol wat je voelt en denkt. Dit is de enige manier om de problemen die jullie hebben op te lossen. Vraag je ook even af of je misschien wat teveel in de verdediging zit. Zou het kunnen dat je op dit moment problemen ziet die er eigenlijk niet zijn?</p><p>Als je single bent is het nu het goede moment om op zoek te gaan naar liefde op nieuwe en ongewone plekken. Je huidige “jachtgebied” wordt namelijk alsmaar minder interessant.</p>",
                        [GuidanceFields.WORK] : "<p>Deze kaart geeft aan dat je op het ogenblik mogelijk in een moeilijke situatie zit op het werk die ook te maken heeft met concurrentie. Misschien is er een collega die een oogje heeft op jouw functie, misschien gaat het om iemand die wat verder van je af staat. Het kan ook zijn dat je enkel vermoedt dat je job in gevaar is en dat je het nodig vindt om jezelf te beschermen nog voordat je ook echt “aangevallen” wordt. Het is belangrijk dat je jezelf nu een stuk in vraag stelt, vraag je af wat er echt gaande is. En eens je een duidelijk en realistisch beeld hebt, pak het probleem dan aan in alle eerlijkheid en integriteit.</p><p>Droom je er soms van om zelfstandig te zijn? Dan is het nu de juiste tijd om echt te overwegen die stap naar onafhankelijkheid te zetten. Denk er wel aan dat je een massa energie en tijd zal moeten investeren om je droom waar te maken. Ben je daartoe bereid, kan je dat aan?</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Volgens deze kaart hebt je op dit moment mogelijk last van nogal wat angsten die je belemmeren in je dagelijkse functioneren. De tijd is gekomen om de confrontatie met je angsten aan te gaan en ze te transformeren in moed. Je zal hier sterker door worden en elke uitdaging aankunnen die op je weg komt. Bedenk dat zonder angst het begrip moed niet zou bestaan. Toch is het belangrijk dat angst je niet overheerst.</p><p>Als er een probleem op je afkomt, laat dan de angst los en grijp de moed die vlak daaronder verborgen zit. Zet je twijfels en onzekerheden opzij en doe net datgene waar je enkel van durfde te dromen.</p><p>Deze kaart zegt ook dat het mogelijk is dat mensen je om spiritueel advies vragen. Aangezien je geest op dit moment helder en scherp is, kan je hen vrijelijk laten delen in je wijsheid.</p>",
                    }
                }
            },
            {
                filename: "29",
                nl : {
                    name: "Staven Acht",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Van alle 78 Tarotkaarten is Staven Acht de enige kaart die je vertelt dat er op korte termijn iets te gebeuren staat of zelfs al aan het gebeuren is. Als je het wachten moe bent, als je gefrustreerd bent, houd het dan nog even vol. Verandering is onderweg naar jou!</p><p>Je hebt alles gedaan wat nodig was om de boel in gang te trekken, nu kan je enkel nog afwachten tot de gevolgen van jouw daden zichtbaar worden. Als je jezelf en je handelingen nog eens onderzoekt, zal je waarschijnlijk beseffen dat je alles gedaan hebt wat nodig was.</p><p>De diepere betekenis van deze kaart is dat er iets gaande is in je leven dat een grondige positieve invloed op je zal hebben.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je een relatie hebt en je bent er klaar voor om de volgende stap te zetten, is het mogelijk dat je partner (nog) niet op dezelfde golflengte zit. Hij/zij heeft mogelijk meer tijd nodig om tot het punt te komen waar jij nu bent. Zorg ervoor dat je niet ongeduldig wordt want liefde en engagement hebben nu eenmaal hun eigen tempo. Je partner zal de volgende stap zetten als zij/hij er klaar voor is.</p><p>Als je op zoek bent naar een relatie en je hebt gevoelens voor iemand dan is het best mogelijk dat die persoon je gevoelens (nog) niet beantwoordt. Geef die ander alle tijd en ruimte om je te leren kennen en je zal merken dat de diepere gevoelens dan ook zullen komen.</p>",
                        [GuidanceFields.WORK] : "<p>Je zit op het werk met een aantal vragen. Zwaarden Acht vertelt je dat je binnenkort de nodige antwoorden zal krijgen. Je kan op dit moment niets anders doen dan afwachten en geduld hebben, ook al is dat niet je sterkste kant. Deze kaart wijst er soms ook op dat je al een hele tijd op veranderingen aan het wachten was en dat deze nu zullen plaatsvinden.</p><p>In elk geval wijst deze kaart er duidelijk op dat de resultaten positief zullen zijn voor jou.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>We leven in hectische en veeleisende tijden waardoor iedereen af en toe wel eens geconfronteerd wordt met het concept “geduld” in allerlei vormen. Het is niet gemakkelijk om geduldig te zijn, om het geduld als vanzelfsprekend in je leven te verwerken.</p><p>Deze kaart wijst je erop dat het moment nu gekomen is om naar je diepe innerlijke zelf te gaan en daar rust en sereniteit te vinden. Daardoor zal je het ware geduld ontdekken en kunnen toepassen. Gebruik hiervoor alle mogelijke middelen zoals meditatie, affirmatie, projectie of een mengeling ervan. Misschien zal je er de eerste keer niet in slagen om dichter bij je geduld te komen maar geef het niet op. Het is op dit moment in je leven heel belangrijk voor je om intens geduld binnenin jezelf te ontdekken.</p>",
                    }
                }
            },
            {
                filename: "30",
                nl : {
                    name: "Staven Negen",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Het centrale thema van deze kaart is koppigheid. Je bent niet van plan om toe te geven, je bent vastberaden om je te blijven vasthouden aan jouw visie, jouw mening, jouw overtuiging.</p><p>Misschien is het wel een goed idee om je eens af te vragen waarom je zo koppig bent. Is er iets waar je bang voor bent? Ervaar je toegeven als een soort bedreiging? Verbind je toegeven met een onaangename ervaring uit het verleden? Of misschien vind je het op dit moment te moeilijk om je ego los te laten. Onderzoek jouw antwoorden op deze vragen grondig en denk nog eens na over je beslissing(en). Misschien is het nu echt wel de juiste tijd om je koppigheid los te laten.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit moment ben je bang dat je partner je zal kwetsen. Misschien denk je dat hij/zij gevoelens heeft voor iemand anders of heb je het gevoel dat je partner je niet meer echt “ziet”. Om te vermijden dat je gekwetst zou worden, heb je een stevige muur om je heen gebouwd, je draagt als het ware een emotioneel harnas. Nu is het heel belangrijk dat je echt gaat beseffen dat je jezelf hierdoor helemaal afsluit, niet enkel voor negatieve gevoelens en ervaringen, maar ook voor de positieve zoals liefde en tederheid. Kijk diep in je hart: is dit echt wat je wil?</p><p>Als je op dit moment alleen bent dan is het verstandig om eerst de nodige tijd te besteden aan het genezen van de wonden die een vorige relatie je toegebracht heeft. Pas als dit gebeurd is, kan je op zoek gaan naar een nieuwe liefde.</p>",
                        [GuidanceFields.WORK] : "<p>Staven Negen vertelt je dat je je mogelijk bedreigd voelt op het werk en het kan best zijn dat daar ook een aanleiding voor is. Misschien is er een collega die een rekening met jou te vereffenen heeft of mogelijk wordt er nagedacht over jouw functie. Probeer in de eerste plaats te ontdekken welke de reële bedreigingen zijn en welke enkel in je fantasie bestaan. Eens dit duidelijk is, kan je stoppen met piekeren over de vermeende problemen en enkel de reële aanpakken. En dan is het tijd om je geweten te raadplegen en te bekijken welke rol jij gespeeld hebt in het ontstaan van de huidige situatie. De eerste stap is je fouten onder ogen zien, pas daarna kan je gaan kijken of je de boel nog kan rechttrekken.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op dit moment ben je behoorlijk gefocust op jezelf, misschien heb je zelfs medelijden met jezelf vanwege de situatie waarin je je bevindt. Maar het is echt geen goede tijd om je onder te dompelen in zelfmedelijden! Integendeel, net nu is het belangrijk dat je wat strenger bent voor jezelf en dat je gestructureerd te werk gaat om je spirituele doelen te bereiken. Concentreer je op kennis verwerven, maak een plan op, stel jezelf uitdagingen ... Doe alles wat je maar kan bedenken om een hoger niveau van spirituele toewijding te bereiken. Omring jezelf met mensen die een positieve en proactieve instelling hebben, mensen die je kunnen helpen om dat hogere plan te bereiken.</p>",
                    }
                }
            },
            {
                filename: "31",
                nl : {
                    name: "Staven Tien",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Staven Tien symboliseert een situatie waarin je je triest voelt, gedeprimeerd, overbelast. Je hebt het idee dat het volle gewicht van de wereld op je schouders rust. Misschien heb je jezelf al een tijd overbelast, zowel privé als op het werk. Het is best mogelijk dat je nu het gevoel hebt dat de toekomst enkel nog meer werk, meer problemen, meer conflicten zal brengen. Misschien zit je op het randje van de wanhoop.</p><p>Een eerste en heel belangrijke stap is dat je jezelf toestaat om uit de slachtofferrol te komen en je af te vragen of een deel van de last die je draagt misschien van iemand anders is. Heb je de verantwoordelijkheid voor een aantal problemen in je omgeving misschien overgenomen? Als dat het geval is, geef die verantwoordelijkheid dan terug aan de persoon of personen waarover het gaat, het zijn niet jouw problemen maar die van hen, geef hen dan ook de ruimte om ze zelf op te lossen. De tweede stap is de tijd nemen om stil te staan, tot rust te komen, je te richten op je innerlijk. Ga voorbij je emoties en focus op je kern, je essentie. Zoek uit welke rol je gespeeld hebt in al die problemen die je overweldigen en verander daarna die rol, zoek manieren om de dingen anders aan te pakken. Als je op dit moment niet de moed hebt om deze uitdaging alleen aan te gaan, zoek dan hulp. Heling en verandering willen is helemaal niet fout of beschamend, integendeel!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Je hebt op dit moment nogal wat medelijden met jezelf. Je hebt het idee dat de problemen in je relatie door anderen veroorzaakt worden, door je partner, vrienden, invloeden van buitenaf. Ook al is dat deels de waarheid, toch zal deze houding je niet verder helpen, de problemen geraken er niet door opgelost. De enige manier om dat te doen is de situatie vanuit een andere hoek te bekijken, zoek een nieuwe visie op het geheel. Door het zo aan te pakken zal je je eigen aandeel beter kunnen zien en ook erkennen. Eens je zover bent, zal je een positieve en constructieve manier vinden om de dingen op te lossen.</p><p>Soms wijst deze kaart er ook op dat je partner problemen heeft die niets met jou te maken hebben, waar je dus geen aandeel in hebt. Ook al is dat misschien een opluchting voor je, denk eraan dat zij/hij je steun en hulp nodig heeft. Het is aan jou om deel uit te maken van de oplossing, anders vorm je toch een probleem.</p>",
                        [GuidanceFields.WORK] : "<p>Op het werk heb je op dit moment nogal wat stress en verantwoordelijkheid. Misschien is je takenpakket te groot en nog steeds aan het groeien. Als dat het geval is, kaart dit dan eerlijk aan met je verantwoordelijke, vertel dat je een aantal taken (tijdelijk) zal moeten laten vallen om de dringende dingen af te kunnen werken. Niemand heeft er iets aan als jij in stilte aan het lijden bent, integendeel, dat is contraproductief.</p><p>Misschien ben je wat huiverig om hierover te praten. Vraag je dan af waar je bang voor bent, wat is het ergste dat zou kunnen gebeuren?</p><p>Als je op zoek bent naar werk is het best mogelijk dat die zoektocht niet zo vlot verloopt. Wanhoop niet, dit is een tijdelijke situatie die binnenkort een positievere kant opgaat.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Staven Tien wijst erop dat je spiritueel gezien in een nogal behoeftige periode zit. Misschien ben je op een punt gekomen dat je niet verder kan groeien zonder hulp en ondersteuning van anderen. Als dit het geval is, aarzel dan niet en vraag steun aan de mensen om je heen. Misschien is het ook een goed idee om nieuwe spirituele bronnen aan te boren, onderwerpen en richtingen die je nog niet onderzocht hebt.</p><p>Deze kaart kan je ook vertellen dat je gewoon heel moe bent, dat je wat teveel hooi op je vork genomen hebt, dat je over je grenzen heen gegaan bent. Als dat het geval is, dan is nu een goed moment om te rusten en te ontspannen, geef je geest en ziel de tijd om tot zichzelf te komen, terug naar de basis.</p>",
                    }
                }
            },
            {
                filename: "32",
                nl : {
                    name: "Page van Staven",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>In de Tarot is de Page vaak het symbool voor nieuwe kansen en mogelijkheden die op je weg komen. De Page van Staven heeft een heel sterke positieve energie. Toch blijft het belangrijk dat je zelf actief aan de slag gaat met die energie. Als je dat niet doet en het gewoon over je heen laat komen dan zal je gemakkelijk afgeleid en verstrooid zijn en daardoor niet enkel je focus verliezen, maar ook het mogelijke positieve resultaat.</p><p>Als je deze kaart trekt dan is het heel waarschijnlijk dat je binnenkort een aanbod krijgt. Als je dit aanbod aanvaardt dan zal dit leiden tot verschillende mogelijkheden om te groeien en bewuster te worden. Daar bovenop kan het aanbod ook leiden tot leuke en fijne ervaringen!</p><p>Misschien zal je wat moed en durf nodig hebben om op dit aanbod in te gaan maar laat je dat niet afschrikken. Elke stap die we in dit leven zetten leidt ons immers naar nieuwe ervaringen en onbekende situaties. Je kan nu eenmaal niet groeien als je gewoon blijft waar je bent.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit moment ben je misschien niet echt gelukkig in je relatie. Misschien heb je er zelfs twijfels over of je er wel mee wil doorgaan. Voor je actie onderneemt is het verstandig om je in de eerste plaats af te vragen wat de problemen juist zijn èn of jullie allebei bereid zijn om te proberen werken aan een oplossing ervoor, om te veranderen. Enkel als jullie dit samen aanpakken, zal je succes hebben. Als je partner niet bereid is om zich te engageren bij het zoeken van een oplossing, dan is het tijd om afscheid te nemen en verder te gaan met je leven. Een relatie kan enkel werken als er sprake is van tweerichtingsverkeer.</p><p>Als je op zoek bent naar een partner dan is het goed mogelijk dat je iemand zal ontmoeten die je in vuur en vlam zet. Daardoor wordt je leven ineens een verleidelijk avontuur, een spannende reis. Grijp deze nieuwe kans aan en geniet er ten volle van!</p>",
                        [GuidanceFields.WORK] : "<p>Het zit er dik in dat je op dit moment in je werkomgeving nogal wat kansen en mogelijkheden krijgt. Probeer ze zonder aarzelen aan te pakken, ook al komen ze onverwacht. Zoals het klimaat nu is zal je waarschijnlijk succes hebben en zullen alle nieuwe initiatieven vruchten afwerpen. Je maakt zelfs kans op opslag of een promotie.</p><p>Als je een baan zoekt dan zal je merken dat de mensen er echt voor openstaan om je daarin te ondersteunen, je te helpen bij je zoektocht. Er komen veel goede vooruitzichten en de kans is groot dat je een baan vindt die je verwachtingen overtreft.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De Page van Staven vertelt je dat je op dit ogenblik je spirituele leven niet al te ernstig moet nemen, til niet te zwaar aan de dingen en laat je innerlijke kind naar buiten komen! Speel, geniet en vraag je daarbij niet af of je wel bepaalde doelen zal bereiken of lessen zal leren. De bedoeling van deze (fijne) periode is niet om te studeren en jezelf uitdagingen op te leggen. Neen, het gaat hier om ontspannen, genieten en dromen zonder meer. Het enige doel wat je jezelf kan stellen is om je levenskwaliteit te verrijken en te verbeteren. Geniet ervan!</p>",
                    }
                }
            },
            {
                filename: "33",
                nl : {
                    name: "Ridder van Staven",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>De Ridder van Staven is een kaart met vele gezichten. Ze kan je vertellen dat je op dit ogenblik in je leven met grote passie en enthousiasme bezig bent met een bepaalde taak of project. Maar ze kan je ook waarschuwen om niet overijverig te zijn, niet teveel hooi op je vork te nemen. Laat je niet meeslepen door dat enthousiasme maar erken en respecteer bepaalde grenzen van anderen. Je motieven zijn weliswaar nobel genoeg maar het is toch belangrijk om af en toe naar het grotere geheel te kijken. Je bent niet alleen op de wereld.</p><p>In deze periode kan je ook positieve en opbouwende berichten krijgen en meestal is het eerder in verband met je werk dan met privézaken. Deze berichten vertellen je dat je succes hebt en gewaardeerd wordt, dat je je gestelde doelen bereikt, over het algemeen eerder en beter dan verwacht werd.</p><p>Deze kaart kan je ook vertellen dat het nu een goed moment is om op reis te gaan of een uitstap te plannen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit moment voel je je heel dicht bij je partner en ben je echt positief over je relatie. Neem voldoende tijd om geregeld te communiceren, om diepgaande gesprekken aan te gaan over hoe je je voelt en wat je verwachtingen zijn. Als je dat geregeld doet, dan zal je merken dat jij en je partner totaal op dezelfde golflengte zitten.</p><p>Misschien ben je op dit moment energetisch vol overtuiging op zoek naar een nieuwe liefde. Vraag je eens af of je eigenlijk niet enthousiaster bent over het zoeken zelf dan over het vinden van iemand. Gaat je interesse op dit moment niet meer naar de jacht dan naar het resultaat ervan? Is het mogelijk dat je jezelf wat aan het voorbijlopen bent? Probeer je eens te focussen op het doel van je zoektocht: wie wil je vinden aan het einde van de rit?</p>",
                        [GuidanceFields.WORK] : "<p>Als je op dit moment op zoek bent naar werk dan vertelt deze kaart je dat er binnenkort goed nieuws komt. Er is een grote kans dat je de baan krijgt waar je op hoopt en eens aan de slag zal je tevreden en succesvol zijn in je werk. Als je al aan het werk bent dan ben je op dit moment heel ijverig bezig. Misschien zou je het iets kalmer aan moeten doen? Je kent jezelf goed, je weet dat je temperamentvol en energiek bent, maar je kan hetzelfde niet verwachten van iedereen om je heen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Als je de Ridder van Staven trekt dan betekent dit dat je op zoek bent naar nieuwe uitdagingen op spiritueel niveau, je wil onbekende onderwerpen bestuderen, niet vertrouwde wegen inslaan en onderzoeken. Je hebt echt zin om je tanden te zetten in iets nieuws, iets anders. En je zal dat andere onderwerp, die andere aanpak ook vinden, je zal er vol energie induiken en er intens van genieten.</p><p>Ook al is het zinvol en positief om geregeld van focus te veranderen, geef toch ook nog even aandacht aan de interessevelden die je achterlaat. Tijd, energie, soms zelfs een schenking geven aan spirituele doelen kan een heel sterke vorm van meditatie zijn.</p>",
                    }
                }
            },
            {
                filename: "34",
                nl : {
                    name: "Koningin van Staven",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart symboliseert de vrouwelijke kracht van het element Vuur. Het gaat hier om vruchtbaarheid, zowel letterlijk als figuurlijk. De Koningin van Staven staat voor levensenergie en vindingrijkheid, voor initiatief nemen. Deze kaart vraagt je om trots en vol vertrouwen naar de wereld te kijken.</p><p>Ook al is deze kaart energetisch heel positief, toch kan er ook een waarschuwing in schuilen: mogelijk ga je op dit moment een beetje teveel in jezelf op. Neem even de tijd om erover na te denken hoe anderen je zien, hoe je overkomt en houd daar dan rekening mee. In een legging geeft deze kaart altijd positieve energie en betekenis aan het geheel.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je deze kaart trekt dan betekent het dat je op dit moment een stormachtige en opwindende tijd beleeft in je relatie. Je hoeft eigenlijk niet meer te doen dan volop genieten van de ervaring. Let wel op dat je niet teveel afgeleid raakt hierdoor; er zijn nog andere dingen in je leven dan enkel je relatie.</p><p>Als je single bent dan is dit voor jou eigenlijk echt geen goede tijd om alleen te zijn. Trek erop uit, ga de wereld verkennen en geniet van alle ervaringen die op je afkomen. Het maakt niet uit waar, het maakt niet uit hoe, het zit er dik in dat je iemand zal ontmoeten en daardoor zal vinden waar je naar op zoek was.</p>",
                        [GuidanceFields.WORK] : "<p>Op werkvlak verlang je tegenwoordig heel sterk naar meer verantwoordelijkheid en onafhankelijkheid. Grijp alle kansen die zich voordoen dan ook met beide handen aan. Gelijk wat je doet, je zal er succes mee boeken en je zal bereiken waar je naar verlangt. Proactief en actief zijn zal ertoe leiden dat je groeit en je volop ontwikkelt in je baan.</p><p>Als je op dit moment op zoek bent naar werk dan zal je binnenkort waarschijnlijk iemand ontmoeten die je daarbij kan helpen. Deze persoon kan je de ideale gelegenheid bieden om jezelf te bewijzen en daardoor een fijne job te vinden.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De Koningin van Staven wijst je erop dat je op dit moment een soort spirituele honger ervaart. Je bent op zoek naar nieuwe kennis, nieuwe lessen en nieuwe inzichten. Je bent zo leergierig dat je niet weet waar het eerst te gaan zoeken. Je zal natuurlijk veel interessante en stimulerende dingen vinden, de wereld is er vol van. Maar let op voor de valse profeten die je op je weg zal vinden. Als er een moment komt waarop je denkt eindelijk de ware leer gevonden te hebben, ga er dan over praten met je vrienden. Denk erover na, voer er gesprekken over, analyseer alle gegevens. Pas als je dan nog altijd helemaal overtuigd bent, kan dit je nieuwe spirituele overtuiging worden.</p>",
                    }
                }
            },
            {
                filename: "35",
                nl : {
                    name: "Koning van Staven",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart symboliseert de mannelijke kracht van het element Vuur. Dit staat voor zelfvertrouwen, kracht en grootsheid. Op dit moment voel je waarschijnlijk dat je veel vertrouwen hebt in je eigen waarde, mogelijkheden en kracht, en terecht. Wees toch voorzichtig en loop er niet teveel mee te koop. Het enige wat telt is dat jij zelf weet wat je waard bent, je hoeft anderen er niet van te overtuigen en hen zeker niet de indruk te geven dat je je mogelijk beter of sterker voelt dan zij. Immers, naarmate je ego groter wordt, word jij kleiner als mens.</p><p>Het enige wat telt is dat je op dit moment alle nodige kracht en energie hebt om alles te bereiken wat je maar wil.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart vertelt over de warmte en verbondenheid die jij uitstraalt naar de mensen waar je van houdt, en dat zij hetzelfde voor jou voelen. Je voelt je heel dicht bij hen en je wil hen helpen en ondersteunen zonder dat je daar iets voor in de plaats wil. Je voelt op dit moment dat je echt onvoorwaardelijk wil geven, er hoeft niets terug te komen. En net omdat je dit voelt, zal je op een kosmische manier datgene krijgen wat je nodig hebt. Als je geeft met een eerlijk en open hart dan wordt dit altijd beloond op een of andere manier, al is het niet altijd op de manier die jij het liefste zou willen.</p><p>Je ervaart op dit moment een rijkdom aan positieve energie en je gaat er verstandig mee om.</p>",
                        [GuidanceFields.WORK] : "<p>Op het werk voel je je zelfzeker, doelgericht en overtuigd van je eigen koers. Het is best mogelijk dat je binnenkort iemand zal ontmoeten die je mentor wordt, waar je veel aan zal hebben. Kijk en leer, deze persoon heeft een schat aan wijsheid waar jij je voordeel mee kan doen.</p><p>Nu is ook een goede tijd om volop aan de slag te gaan met je organisatietalent en sociale vaardigheden. Wees dynamisch, toon lef en initiatief. Maar let er toch ook op dat je niet overdrijft, dat je niet te hevig overkomt. Je staat niet op het toneel, het blijft gewoon een werkplek, ook al barst jij van de energie.</p><p>Als je op dit moment op zoek bent naar werk dan zal je binnenkort waarschijnlijk een geschikte baan vinden. Als iemand aanbiedt om je daarbij te helpen, aanvaard die hulp dan zeker, je zal er veel baat bij hebben.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Je hebt de laatste tijd een behoorlijk aantal spirituele doelen bereikt die je jezelf gesteld had. Je bent ook gretig om er nog meer te bereiken, je wil energiek en actief blijven vooruitgaan.</p><p>Maar op dit moment is het beter voor jou om gewoon even stil te staan en dat bewust te ervaren. Luister eens naar de stilte, focus je daarop. Ook zo’n ervaring maakt deel uit van het spirituele groeiproces. Spiritualiteit gaat niet enkel om hard werken en doelen bereiken, het gaat er ook om dat je jezelf naar je eigen kern toekeert en even niets doet. Probeer dit eens uit, doe niets en bekijk welke invloed het op je heeft. Waarschijnlijk zal je merken dat je je geheeld voelt.</p>",
                    }
                }
            },
            {
                filename: "36",                
                nl : {
                    name: "Beker Aas",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>De Aas van Bekers is een van de meest positieve kaarten van de hele Tarot. Als je deze kaart trekt dan is het een duidelijk teken dat je onderweg bent naar een heel positieve fase in je leven. Deze Aas vertelt je dat je veel potentieel hebt. Het is nu zaak om je daar steeds bewuster van te worden en dat potentieel ook ten volle te gaan gebruiken. Je zal merken dat dit een grote verbetering zal betekenen voor je levenskwaliteit!</p><p>De Aas van Bekers zal je vreugde brengen, dankbaarheid en een gevoel van eenheid, van samenzijn. De positiviteit die deze kaart inhoudt zal vooral voelbaar zijn op het gebied van relaties, gevoelens en emoties. Vaak zal je merken dat je een nieuwe start krijgt, een nieuw begin. En je zal ook veel goede wil en een positieve instelling ervaren van de mensen om je heen. Het wordt nu echt gemakkelijk om nieuwe mensen te leren kennen, nieuwe vrienden te maken.</p><p>Als je graag zwanger wil worden dan is deze kaart een heel positief teken.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart is een positief voorteken op liefdesvlak. Als je een liefdesrelatie hebt dan ervaar je nu waarschijnlijk diepe en onvoorwaardelijke liefde. Het is ook best mogelijk dat je je relatie naar het volgende niveau wil brengen en dat je je echt gaat engageren door je te verloven, te gaan samenwonen, te trouwen of aan kinderen te beginnen. Nu is er geen ruimte voor twijfel en onzekerheid: de gevoelens van jou en je partner zijn oprecht en intens, handel ernaar.</p><p>Als je op zoek bent naar liefde dan heb je nu alle kansen om die ook te vinden. Je zal merken dat het je gemakkelijk valt om verliefd te worden en dat je het ook intens beleeft: vlinders in de buik, euforie, intense vreugde en harmonie, diepe passie ... ga ervoor en geniet van elk moment!</p>",
                        [GuidanceFields.WORK] : "<p>Als je nu de Aas van Bekers trekt dan zit het waarschijnlijk behoorlijk goed wat je werk betreft. Je haalt een intens gevoel van tevredenheid en vervulling uit je baan en het is duidelijk dat je collega’s en bazen je enorm waarderen voor wat je doet en wie je bent. Het is ook best mogelijk dat je binnenkort opslag krijgt of zelfs een promotie, of je krijgt een nieuw takenpakket dat je nog fijner zal vinden dan het huidige. Je communicatieve vaardigheden zijn op dit ogenblik ook heel sterk, maak er ten volle gebruik van!</p><p>Als je op zoek bent naar werk dan mag je nu vol vertrouwen zijn en je ten volle bewust zijn van je kwaliteiten. Je zal heel goed tot je recht komen tijdens interviews en je vindt het geen enkel probleem om mensen te overtuigen van je vaardigheden.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Deze kaart heeft het over een positieve instelling, energie en je goed voelen. Op spiritueel niveau is het een goed idee om al deze positiviteit te delen met anderen. Gebruik je energie om nader te komen tot de mensen om je heen en zelfs tot het universum als geheel. Deel je vreugde en je goede gevoel en deel zeker wat van dat positieve uit aan iedereen die op je weg komt. Op dit moment is het zo dat hoe meer je geeft, hoe meer je zal ontvangen, het is gelijk oversteken. En net door al dat geven zal je merken dat het alsmaar gemakkelijker wordt om verbonden te zijn en te blijven met de universele energie waar we allemaal deel van uitmaken. Mediteer op je gevoel van liefde, eenheid met de kosmos, baden in het energetische licht. Hierdoor zal je je helemaal stralend en opgeladen voelen!</p>",
                    }
                }
            },
            {
                filename: "37",
                nl : {
                    name: "Bekers Twee",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart heeft als onderwerp evenwicht, liefde, passie, sympathie en spontane connecties. Als je Bekers Twee trekt kan het betekenen dat je liefdesrelatie heel soepel verloopt op dit moment. Het kan ook betekenen dat er op je werk een vrolijke, positieve sfeer hangt en dat je een goed contact hebt met je collega’s. Op dit moment is het absoluut een goede tijd om nieuwe mensen te leren kennen. Wees gewoon jezelf en je zal merken dat je met open armen verwelkomd wordt.</p><p>Aan de andere kant kan deze kaart er soms ook op wijzen dat je tegenstrijdige gevoelens hebt in verband met mensen en/of situaties in je leven, gevoelens die je niet met mekaar kan verzoenen. Deze gevoelens zorgen voor problemen en om die op te lossen is het belangrijk dat je luistert naar je diepste kern, naar je intuïtie. Dan zal je weten welke kant je moet kiezen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit moment lijkt het erop dat je (liefdes)relatie gebouwd is op stevige fundamenten en dat jullie allebei totaal betrokken en geëngageerd zijn. Het voelt alsof jullie het perfecte koppel zijn. Toch betekent dit niet dat je zomaar alles kan doen waar je zin in hebt. Elke relatie, zelfs een bijna perfecte, vraagt werk en onderhoud. Ook al is jullie basis heel stevig, je moet toch elke dag aan de slag gaan om verder te bouwen en alles te onderhouden.</p><p>Als je op dit moment alleen bent dan vertelt deze kaart je dat er echt wel liefde in de lucht hangt. Ga op stap, doe leuke dingen, ontspan je en geniet en je zal merken dat er al gauw een potentiële geliefde op je weg komt!</p>",
                        [GuidanceFields.WORK] : "<p>Misschien houd je heel veel van je baan en ben je er totaal aan toegewijd. Of misschien aanvaard je het werken gewoon als iets dat je nu eenmaal moet doen om je leven te bekostigen. Hoe je ook in je job staat, op dit moment is de sfeer op het werk heel fijn. Je kan goed opschieten met je collega’s en je meerderen en je merkt dat ze alles wat je doet ook echt waarderen. Je voelt je positief en ontspannen.</p><p>Als je aan het overwegen bent om van baan te veranderen, dan is het er nu het juiste moment voor. Trek erop uit en ga op zoek naar andere mogelijkheden, je zal niet teleurgesteld worden!</p><p>Als je nu werkloos bent en op zoek naar een baan, dan vertelt deze kaart je dat je binnenkort succesvol zal zijn in je zoektocht. Houd het nog even vol, je zal binnenkort een aantal mooie kansen krijgen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op dit moment ben je spiritueel volop aan het groeien. Je merkt dat je emoties en inzichten diepgaander aan het worden zijn en je voelt je algemeen meer gegrond. Je geniet er nu ook intens van om in contact te komen met anderen, om met hen jouw liefde voor het universum (en voor alles en iedereen die er deel van uitmaakt) te delen. Je voelt je open en positief tegenover alles wat bestaat. Nu is het perfecte tijdstip om deze liefde en vreugde met anderen te delen. Door dit te doen zal je niet enkel een diepe en blijvende indruk maken op iedereen die je ontmoet, je zal ook je spirituele kern voelen groeien.</p>",
                    }
                }
            },
            {
                filename: "38",
                nl : {
                    name: "Bekers Drie",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Dit is een feestelijke kaart die vaak betrekking heeft op vieringen. Het kan gaan om een weerzien of hereniging die gevierd wordt, maar het kan ook een huwelijk, verjaardag, geboorte of gelijk welke andere heuglijke gebeurtenis zijn.</p><p>Het is ook mogelijk dat er opnieuw contact komt met iemand die je al lang hebt moeten missen. Of misschien heb je een taak of opdracht afgewerkt, heb je iets volbracht waar je trots op bent, iets dat je vervult met tevredenheid. Als je deze kaart trekt dan is dat over het algemeen een teken dat je je onbekommerd en dankbaar zal voelen en dat je leven harmonieus en vol vreugde zal zijn. Soms betekent deze kaart ook dat er op financieel en materieel gebied een merkbare verbetering in de maak is.</p><p>In elk geval duidt deze kaart erop dat het nu tijd is om te ontspannen en te genieten en om je goede gevoel ook te delen met de mensen om je heen!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit moment heb je de behoefte om anderen te helpen en te ondersteunen. Dit gevoel is wederzijds want je zal merken dat anderen jou ook hulp en warmte willen bieden. Je hebt behoefte aan sociale activiteit, je wil genieten van het gezelschap van je geliefden en vrienden. Je staat op dit ogenblik in je leven echt open voor anderen, je hebt oog voor wat ze je te bieden hebben op menselijk vlak. Je voelt je gelukkig en bevredigd in je relatie en dit goede gevoel wil je met iedereen delen. Een ideaal moment om een feestje te bouwen!</p><p>Als je alleen bent is het heel waarschijnlijk dat je nu tegen iemand op loopt die helemaal is waar je naar op zoek bent. Maar let op: als je een ‘oude’ liefde opnieuw ontmoet, dan is het echt onwaarschijnlijk dat dit de goede partner voor je zal zijn, let op voor het herhalen van vergissingen, leg de nadruk eerder op ‘nieuwe’ ontmoetingen. Want ook al duidt deze kaart vaak op herenigingen, de juiste partner voor jou is niet in je verleden te vinden.</p>",
                        [GuidanceFields.WORK] : "<p>Deze kaart vertelt je dat je gevoel in verband met je werk en werkomgeving op dit moment grotendeels positief is. Het is ook best mogelijk dat je goed nieuws krijgt in verband met je job, een positieve evaluatie, een aantal complimenten van je meerderen. En dat kan dan weer leiden tot een promotie of opslag. Als je overweegt om van baan te veranderen dan is het nu het juiste moment, je straalt immers zelfvertrouwen uit!</p><p>Als je nu werkloos bent dan is het zaak om te blijven zoeken, geef het niet op. Er komen binnenkort een aantal mooie kansen je kant op. Schenk ook voldoende aandacht aan mensen uit het verleden die op je pad komen want zij zouden de sleutel kunnen zijn naar de perfecte baan voor jou.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Het is best mogelijk dat jij iemand bent die vooral van spirituele ervaringen geniet in je eentje. Misschien mediteer je graag alleen, werk je met visualisaties en affirmaties,gewoon alleen met jezelf, daar voel je je goed bij.</p><p>Toch is het op dit moment in je leven verstandig om eens op zoek te gaan naar het gezelschap van anderen, mensen die je kunnen inspireren, die je op weg kunnen zetten naar totaal andere spirituele paden en uitdagingen. Het zijn net die anderen die je vormen van spiritueel beleven kunnen aanreiken waar je zelf nooit zou zijn opgekomen. En net door die ongewone ervaringen kan er voor jou een totaal nieuwe spirituele wereld opengaan. De mensen die je hiervoor aanspreekt hoeven geen goeroes of leermeesters te zijn, in tegendeel. Een gesprekje met je buurman of het bezoeken van een nieuwe website kan al een totaal andere zienswijze bij jou teweegbrengen. Stel jezelf open voor anderen en laat je verrassen!</p>",
                    }
                }
            },
            {
                filename: "39",
                nl : {
                    name: "Bekers Vier",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>De Vier van Bekers heeft het over gemengde gevoelens. Aan de ene kant ben je ontevreden over bepaalde aspecten van je leven, het lijkt alsof het gras elke keer weer groener is aan de overkant. En aan de andere kant weet je eigenlijk best dat je een heleboel dingen hebt om dankbaar voor te zijn. Het lijkt alsof je glas halfvol is maar jij ervaart het als halfleeg. Je hebt op dit moment de neiging om je te focussen op het negatieve in plaats van het positieve. Je laat jezelf overspoelen met ontevredenheid en ongenoegen. Eigenlijk is het best mogelijk dat er een simpele oplossing is voor dit probleem: kijk naar wat er <strong>is</strong> en waardeer het, kijk niet naar wat jij <strong>zou willen</strong> dat er is. Je mag best een beetje streng zijn voor jezelf op dit moment: dwing jezelf om die dingen te tellen waarmee het leven je gezegend heeft, geniet ervan, koester ze. Je zal merken dat deze houding je vervult met positieve energie, en dat maakt het dan weer gemakkelijker om over die zaken heen te stappen die je op dit moment niet hebt. De kracht van positief denken verbetert altijd je levenskwaliteit!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je de Vier van Bekers trekt dan wijst dit er over het algemeen op dat je situatie niet optimaal is. Misschien verveelt je relatie je een beetje, vind je het maar een saaie boel. Of misschien voel je je leeg, lijkt je gevoel voor je partner opgebruikt te zijn. Misschien kan je je zelfs niet meer herinneren waarom je ooit voor deze persoon gekozen hebt, wat je zo aantrekkelijk vond aan hem of haar. Het lijkt alsof je denkt: dit kan mijn leven niet zijn, ik weet zeker dat ik iets beters verdien! Je bent behoorlijk ontevreden en je gaat helemaal op in dit gevoel. Probeer je eens te focussen op wat wel goed zit in je relatie. Je zal merken dat je daar meer kracht uit put dan uit de houding die je nu aanneemt. En als blijkt dat je er niet in slaagt om ook maar één positief aspect te vinden in je partner, trek dan je conclusies en ga alleen verder. Als je single ben en op zoek, let er dan op dat je niet al je energie investeert in het veroveren van iemand die geen interesse heeft of die te hoog gegrepen is voor jou. Denk er goed over na of je doelen wel realistisch zijn, anders heb je het falen al ingebouwd in je poging.</p>",
                        [GuidanceFields.WORK] : "<p>je vindt het tegenwoordig moeilijk om gemotiveerd te blijven in je werksituatie. Het lijkt allemaal zo saai en herkenbaar, zo leeg. Je ziet geen uitdagingen, je vindt geen nieuwe of opwindende aspecten in je job, er zijn geen nieuwe mensen die de boel wat kunnen opvrolijken. Je hebt het gevoel alsof je daar al een eeuwigheid zit en telkens weer, elke dag, dezelfde saaie dingen moet doen. En misschien is het ook zo dat dit niet je droomjob is, misschien zit je wel wat in een sleur. Maar toch is er ook een positieve kant: je <strong>hebt</strong> tenminste een baan! Probeer hierin toch iets van vreugde te vinden. Laat dat ‘arme ik’ los en ga op zoek naar dingen die je wel wil doen, waar je je wel goed bij zal voelen.</p><p>Als je op zoek bent naar werk dan is het mogelijk zinvol om je in de eerste plaats eens af te vragen of je echt wel werk wil vinden. Als dat niet het geval is, is het verstandiger om je energie eerst te investeren in het ontdekken welke job je graag zou doen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Je gedachten en gevoelens zijn van grote invloed op je spirituele leven. Op dit moment lijkt het erop dat je jezelf verliest in emoties die heen en weer gaan tussen zelfmedelijden en ontevredenheid. Je ingesteldheid is voornamelijk passief en negatief. Je blijft maar dingen denken als ‘Als X maar anders zou zijn dan zou ik me vast beter voelen’. Als je hierin blijft hangen dan zal er niets veranderen en zal je alsmaar ongelukkiger worden. Vergeet nu zowel het verleden als de toekomst en concentreer je op het heden, het Nu. Ga er even bij zitten en denk aan de dingen die je wel fijn vindt in je leven, zelfs al zijn het er niet veel. Wat maakt je gelukkig? Waar voel je je tevreden bij? Wie vind je leuk, sympathiek? Schuif niets opzij, als het je een goed gevoel geeft dat je hond blij is als je ’s avonds thuiskomt dan is dat iets positiefs in je leven. Eens je een (misschien kort) lijstje gemaakt hebt, kan je op die dingen gaan focussen en daardoor je algemene gevoel veranderen. Je zal merken dat dit een weg is om je uit die negatieve sleur te halen waar je in zit. Jij bent de architect van wat je voelt!</p>",
                    }
                }
            },
            {
                filename: "40",
                nl : {
                    name: "Bekers Vijf",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart heeft als onderwerp gevoelens van verlies, verdriet en teleurstelling. Toch wijst de Vijf van Bekers er ook op dat je zelf een invloed hebt op de grootte en intensiteit van je verdriet. Jij beslist waarop je je wil concentreren; de personen/dingen die je kwijt bent of de personen/dingen die je nog hebt. Als jij besluit om je te focussen op je verdriet en om het ook nog alleen te dragen, dan zal blijken dat ermee omgaan een zware taak wordt. Maar als je inziet en aanvaardt dat er ook nog positieve aspecten aan je leven zijn èn dat je mensen om je heen hebt die je willen steunen en helpen, dan zal je merken dat het verwerken van je verdriet iets makkelijker wordt.</p><p>Als je geconfronteerd wordt met verlies en teleurstelling dan is het zinvol om mee te gaan met de stroom, de gevoelens over je heen te laten komen. En terwijl je ze ten volle ervaart, kan je ook kijken naar de goede kanten van je leven. Als je probeert om te vechten tegen het verdriet zal het je daarna twee keer zo hard raken. En als je er teveel in opgaat, loop je het risico om erin te verdrinken. Jij bent de enige die door deze zware periode heen moet geraken, maar je hoeft het niet alleen te doen. Laat je meevoeren met de stroom van het verdriet en nodig iemand uit om je hand vast te houden.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je de Vijf van Bekers trekt dan kan dit erop wijzen dat je relatie op het punt staat verbroken te worden of dat je net een breuk achter de rug hebt. Op dit moment vind je het vast onmogelijk om te aanvaarden dat er ook iets positief is aan deze situatie. Je wordt overweldigd door verdriet, teleurstelling en mogelijk zelfs frustratie. Je begrijpt niet of wil niet begrijpen waarom deze relatie moest eindigen. Je wil gewoon dat alles zo blijft als het was, je vindt het verschrikkelijk dat de dingen nu gaan veranderen. Probeer jezelf wel de tijd te gunnen om deze negatieve gevoelens te ervaren en te verwerken, maar jezelf er niet in te gaan onderdompelen. Er is nog een hele grote wereld daarbuiten met massa’s mensen die je kan ontmoeten en leren kennen. En daarbuiten loopt er ook iemand rond die jouw nieuwe partner zal worden, laat hem/haar niet te lang op je wachten en maak werk van je verwerkingsproces!</p><p>Als je op dit moment geen relatie hebt, sta jezelf dan niet toe om helemaal op te gaan in je eenzaamheid. Het is veel productiever om je te concentreren op de mogelijkheden en opties die je hebt. Ga naar buiten, treed de wereld tegemoet en vind een partner!</p>",
                        [GuidanceFields.WORK] : "<p>Het is best mogelijk dat je wat problemen hebt op je werk. Misschien zijn je meerderen niet tevreden met het werk dat je levert, misschien zijn ze zelfs nogal grof in hun mededelingen. Of misschien kan je niet zo goed opschieten met een aantal collega’s en vind je dat erg moeilijk. Gun jezelf de tijd om eens goed na te denken over de vraag of je echt wel zeker bent dat je deze job wil blijven uitoefenen, dat je op deze werkplek wil blijven. Misschien is het nu wel de goede tijd om uit te k naar nieuwe mogelijkheden, andere opties. Misschien kan je een baan vinden die beter bij je past, waar je je ontspannener en gelukkiger zal voelen. De teleurstelling en rusteloosheid die je voelt kan immers ook betekenen dat je behoefte hebt aan een nieuwe uitdaging.</p><p>Als je op zoek bent naar werk is het mogelijk dat je geconfronteerd wordt met nogal wat tegenvallers en teleurstellingen. Het feit dat de dingen op dit moment niet gaan zoals je het graag wil, zegt niets over jou als persoon of als mogelijke werknemer. Ga gewoon rustig verder zoals je bezig bent, je zal merken dat de omstandigheden geleidelijk veranderen en dat je weer kansen en mogelijkheden op je weg zal krijgen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Je voelt je mogelijk nogal teleurgesteld en gefrustreerd tegenwoordig, misschien zelfs verdrietig. Er zijn immers mensen in je omgeving die zich niet gedragen hebben zoals jij het van hen verwachtte en hoopte. Je hebt het gevoel dat ze je in de steek gelaten hebben en misschien is dat ook echt zo. Toch is het nu niet de juiste tijd om te gaan focussen op het foute gedrag van anderen of op hun tekortkomingen.</p><p>Jouw belangrijkste taak is nu om door je gevoelens van verdriet en teleurstelling heen te gaan, ze een plaats te geven en te gaan bekijken wat jij kan leren van deze ervaring(en). Op het moment dat je de geboden levenslessen ontdekt in deze situatie zal je merken dat het een stuk gemakkelijker wordt om die andere(n) het slechte gedrag te vergeven en vrede te sluiten met hen en met jezelf. En dan heb je alweer een stap gezet op je persoonlijke pad van spirituele groei!</p>",
                    }
                }
            },
            {
                filename: "41",
                nl : {
                    name: "Bekers Zes",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>De Zes van Bekers heeft het over mijmeren, je herinneren hoe de dingen vroeger waren, hoe jij vroeger was. Je maakt tijd om terug te denken aan fijne vriendschaps- en liefdesrelaties die je gehad hebt, aan mensen die je gekend en bemind hebt. Misschien keer je wel nog verder terug in de tijd en haal je een paar fijne jeugdherinneringen op. Het is positief en zinvol om je het verleden te herinneren, terug te denken aan mooie (en soms minder mooie) tijden. Toch is het ook belangrijk om je te herinneren wat je indertijd geleerd hebt en er even bij stil te staan of je nu nog iets kan leren van dat verleden. Sommige inzichten die je vroeger verworven hebt, kunnen je misschien een betere kijk geven op dingen die je nu meemaakt. Wat ook verrijkend is, dat is om erbij stil te staan dat pijnlijke en trieste herinneringen geen macht meer hebben over jou, ze kunnen je niet meer kwetsen omdat je gegroeid en veranderd bent.</p><p>Let er wel op dat je niet te lang in het verleden blijft hangen, je beste jaren zijn niet voorbij, ze komen er nu pas aan!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Zes van Bekers kan erop wijzen dat het nu tijd is om eens te gaan bekijken hoe het zit met je relatie en jullie plannen voor de toekomst. Hebben jullie duidelijke doelen, zaken die jullie samen willen bereiken? Of ga je gewoon mee met de stroom, plan je niets en laat je het aan het universum over waar jullie zullen uitkomen? Als dat laatste het geval is, dan is het nu een goed moment om samen te bekijken wat je in de toekomst wil bereiken, niet enkel op relationeel vlak maar ook qua gemeenschappelijke doelen.</p><p>Deze kaart kan je ook vertellen dat er een ex-geliefde opnieuw in je leven zal opduiken. Zo’n ontmoeting kan heel fijn zijn, het kan behoorlijk bevredigend en zelfs spannend zijn om samen herinneringen op te halen en ze ook een stuk te herbeleven. Maar denk toch eens goed na voor je zou beslissen dat je de oude vlam opnieuw wil laten branden. Jullie zijn totaal andere mensen geworden en datgene wat jullie vroeger bij mekaar bracht, doet dat daarom vandaag niet. Daar bovenop waren er waarschijnlijk ook gegronde redenen waarom jullie de relatie verbroken hebben. Misschien laat je het verleden beter gewoon waar het thuishoort, in die goeie ouwe tijd.</p><p>Als je op zoek bent naar een relatie maak dan eerst even ruimte om je relationele verleden nog eens te overlopen en de lessen die je geleerd hebt op een rijtje te zetten. Hoe kunnen die lessen je helpen om de juiste partner te vinden?</p>",
                        [GuidanceFields.WORK] : "<p>Misschien voel je je op het moment behoorlijk nostalgisch. Het kan leuk zijn om terug te denken aan vorige banen en collega’s. Misschien denk je zelfs na over je jeugd en de ambities die je toen had. Wilde jij als kind astronaut worden of clown of ...? Het verleden bezoeken kan je een gevoel van veiligheid geven, van tevredenheid over het heden. Toch is het mogelijk dat je hierdoor op het werk minder aanwezig lijkt, alsof je in je hoofd ergens anders zit dan op je werkplek, je komt wat dromerig over. Is dit simpelweg een gevoel van onschuldige en tijdelijke nostalgie? Of is het een aanwijzing dat je je huidige job niet meer zo fijn vindt? Is er misschien een stuk van jou dat zich onbevredigd voelt? Heb je het idee dat je nieuwe uitdagingen nodig hebt, nieuwe kansen om bij te leren, te groeien? Als dat laatste het geval is, ga dan op zoek naar een andere baan, je zal je een stuk tevredener voelen! Als je op zoek bent naar een baan, neem dan even de tijd om te overlopen welke dingen je vroeger fijn en bevredigend vond om te doen. Als je alles op een rijtje zet kunnen dit de perfecte aanwijzingen zijn in welke richting je best kan zoeken.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Om aan je spirituele groei te werken is het op dit moment aangewezen om contact te maken met je innerlijke kind. Probeer in je geest terug te keren naar je jeugd, maak er misschien een meditatie of een ontspanningsoefening van. Herinner je je de intense vreugde, het diepe geluk dat je als kind kon ervaren? Herinner je je nog dat fantastische gevoel dat het leven één groot avontuur was en dat je elk moment nieuwe dingen ontdekte, dat je fantasie grenzeloos was?</p><p>Ga op zoek naar je innerlijke kind en geef hem/haar dan de vrijheid, geef dat kind de ruimte om open te komen en je te vertellen wat je kan doen om zo’n momenten opnieuw te beleven. Leer opnieuw te spelen en te ontdekken, rond te kijken met de ogen van een kind. En terwijl je daarmee bezig bent, kan je datzelfde kind ook helpen om te herstellen van pijnlijke ervaringen waar het misschien nog steeds mee zit. Je innerlijke kind helen en vieren is een stap vooruit zetten in je spirituele groei!</p>",
                    }
                }
            },
            {
                filename: "42",
                nl : {
                    name: "Bekers Zeven",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>De Zeven van Bekers symboliseert keuzes, ze kan er zelfs op wijzen dat je teveel keuzes hebt op dit moment. Een overweldigende hoeveelheid mogelijkheden en opties kan verwarrend werken. Het kan je het gevoel geven dat er gewoon teveel aan de hand is in je leven. Misschien heb je zelfs het gevoel dat het onmogelijk is om wat structuur in je leven te krijgen en te houden. Als je je overweldigd voelt dan loop je altijd het gevaar om door de bomen het bos niet meer te zien. Het is dan ook mogelijk dat je je wensen voor waarheid aanneemt en luchtkastelen gaat bouwen. Nu is het nochtans zaak om de realiteit onder ogen te zien en te bekijken wat er echt aan de hand is en wat enkel in je verbeelding bestaat.</p><p>Er zijn altijd bepaalde aspecten van je leven waar je geen enkele controle over hebt, maar er zijn ook altijd dingen die jij kan bepalen. Nu is het aan jou om die dingen die je niet kan bepalen te aanvaarden en om aan de slag te gaan met de rest. Acceptatie + Actie = Groei!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit moment is het belangrijk dat je een realistische kijk blijft houden op je relatie. Ook al is het heel verleidelijk om te gaan dromen en fantaseren over oneindige mogelijkheden, het is de realiteit waarmee je het moet doen. Overleg met je partner, beslis samen welke doelen jullie willen bereiken en ga dan aan de slag. Verlies je niet in oppervlakkige (misschien wel romantische) gebaren en dagdromen. Een langdurige levensvatbare relatie wordt nu eenmaal opgebouwd met toewijding en realiteitszin.</p><p>Als je single bent zit het erin dat je hals over kop verliefd wordt op iemand. Als dit het geval is, vraag je dan af of er wel iets meer is dan enkel vlinders in je buik. Kijk eens objectief naar deze persoon en stel jezelf de volgende vragen: kan je je voorstellen dat je de rest van je leven samen bent met deze persoon? Hebben jullie veel dingen gemeenschappelijk? Zouden jullie het samen kunnen maken in de ‘echte’ wereld? Laat je niet verblinden door je (tijdelijke) passie, kijk ook wat verder.</p>",
                        [GuidanceFields.WORK] : "<p>Je voelt je waarschijnlijk behoorlijk creatief en geïnspireerd in verband met je werk. Het voelt alsof je onmetelijk veel opties hebt, allerlei wegen die je kan kiezen. Voordat je die nieuwe opties uitprobeert, is het verstandig om ze te kruiden met een behoorlijke dosis realiteitszin. Zijn jouw ideeën echt levensvatbaar? Of laat je je overweldigen door je enthousiasme en je creativiteit? Zorg ervoor dat de doelen die je jezelf stelt ook haalbaar zijn, dat ze gerealiseerd kunnen worden. Anders ga je voor jezelf teleurstellingen plannen in plaats van successen. Je hebt vele kwaliteiten maar je bent niet perfect!</p><p>Als je op zoek bent naar werk dan is het belangrijk dat je nu enkel gaat solliciteren voor jobs die echt geschikt zijn voor jou. Kijk realistisch naar je kwaliteiten en talenten en kies de vacatures in functie daarvan.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op spiritueel vlak is het best mogelijk dat je je wat overweldigd voelt door alle mogelijkheden die je op dit moment ziet: er zijn zoveel theorieën om te onderzoeken, zoveel mogelijke keuzes, zoveel praktische toepassingen, de opties lijken wel oneindig. Hoe beslis je wat je uitkiest, wat pak je het eerst aan?</p><p>Dit is voor jou het juiste moment om hulp en leiding te vragen. Er zijn best veel positieve en spirituele mensen die je kunnen gidsen doorheen deze jungle van mogelijkheden. Je eigen positieve instelling zal de perfecte bagage zijn om deze zoektocht mee te ondernemen!</p>",
                    }
                }
            },
            {
                filename: "43",
                nl : {
                    name: "Bekers Acht",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Als je deze kaart trekt dan is het goed mogelijk dat je op dit moment geconfronteerd wordt met een keuze of keuzes. Je bent niet langer tevreden met een bepaalde situatie, persoon, relatie of misschien zelfs met je woonomgeving en je overweegt om er een eind aan te breien. Jij bent de enige die deze keuze kan maken omdat jij en jij alleen exact weet waarom je niet langer gelukkig bent. Jij bent je ten volle bewust van wat je mist, welke behoefte niet vervuld wordt. Het is trouwens best mogelijk dat je leven er aan de buitenkant perfect normaal en evenwichtig uitziet. Maar jij weet dat dit niet is wat je wil, je wil meer dan dit. Je bent er klaar voor om naar dat ‘meer’ op zoek te gaan, ook al weet je misschien niet nog duidelijk wat het inhoudt, waar je naar op zoek bent. En ook al ben je verdrietig omdat je bepaalde mensen, situaties, dingen achter je zal moeten laten, je weet en gelooft dat deze stap op dit moment de beste is voor jou. Soms moet je eerst een deur dichtdoen en kan je daarna pas de weg zien naar de volgende deur.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In verband met relaties wijst de Acht van Bekers over het algemeen op een soort einde, een afsluiting. Het is mogelijk dat je effectief een punt zet achter de relatie met je geliefde omdat je er niet meer kan uithalen wat je nodig hebt en waar je naar verlangt. Het is ook mogelijk dat je je partner plots in een nieuw licht ziet. Vaak is het zo dat je je geliefde blijft waarnemen zoals je dat in het begin van je relatie deed, je beeld verandert dan niet of nauwelijks. Nu komt de realiteit aankloppen en besef je dat je partner een totaal andere persoon geworden is. Als dit het geval is, doe er dan niet te lang over om de knoop door te hakken en te beslissen of je al dan niet kan leven met deze nieuwe visie op je wederhelft. Tenslotte vindt niemand het fijn om niet te weten waar hij/zij aan toe is.</p><p>Als je van plan bent om op zoek te gaan naar een partner, bekijk dan eerst voor jezelf of je echt wel over je vorige relatie heen bent. Het is heel belangrijk dat je op geen enkele manier nog verlangt naar je ex, dat je niets mist van wat er was. Anders zal dit je nieuwe relatie van in het begin op een negatieve manier kleuren.</p>",
                        [GuidanceFields.WORK] : "<p>Het kan tegenwoordig op het werk zo lijken alsof je afgehaakt hebt, alsof je je niet meer verbonden voelt met je werk en je werkomgeving. Misschien verveel je je zelfs. Je hebt nu twee opties: wil je blijven waar je bent en proberen om de situatie te veranderen? Het is best mogelijk dat je dit relatief simpel kan bereiken door bvb. een open gesprek te hebben met je meerderen over je behoefte aan waardering.</p><p>Daardoor kan je vroegere motivatie terug openbloeien. Of wil je met een schone lei starten en een andere baan gaan zoeken? Er zijn hier geen goede of foute keuzes, jij bent de enige die echt weet wat je wil doen. Nu is het belangrijk dat je je buikgevoel vertrouwt, luister naar je intuïtie.</p><p>Als je op zoek bent naar werk is het op dit moment niet gemakkelijk om gemotiveerd te blijven. Overweeg eens of het misschien verstandig zou zijn om een korte pauze in te lassen zodat je je stressniveau wat kan verlagen. Je zal merken dat je een stuk gemotiveerder bent na je ‘vakantie’!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Waarschijnlijk voel je je op spiritueel vlak een stuk onbevredigd op dit moment. Boeken, cursussen, lezingen, oefeningen ... je hebt het allemaal al gezien en meegemaakt, er is niets dat je nog echt aanspreekt, het voelt allemaal te vertrouwd. Eigenlijk komt het erop neer dat je wat jij zoekt niet zal vinden in de wereld om je heen, hoe hard je ook zoekt. Het is nu zaak om je naar binnen te keren, je vooral te wijden aan je eigen gezelschap, naar je innerlijke stem te luisteren. Je bent in de voorbije periode behoorlijk gegroeid op spiritueel vlak, als is dat bijna ongemerkt gegaan. Nu is het zaak om de vruchten te plukken van die groei en naar je eigen spirituele stem te luisteren, stel je open voor jezelf!</p>",
                    }
                }
            },
            {
                filename: "44",
                nl : {
                    name: "Bekers Negen",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>De Negen van Bekers is een van de meest positieve kaarten van de hele Tarot. Trefwoorden zijn huiselijk geluk, zorgeloosheid, plezier en vreugde. Het komt er nu op aan dat je gaat genieten, of het nu gaat om een uurtje zonnen, een wandeling, een lekkere maaltijd of meer sensuele bezigheden. Je zintuigen staan op scherp, voed ze!</p><p>Op een ander niveau vertelt deze kaart je dat, als je bepaalde wensen of verlangens hebt waarvan je hoopt dat ze vervuld zullen worden, dit waarschijnlijk zal gaan gebeuren in de nabije toekomst. Als je vragen of twijfels hebt in verband met iets, dan vertelt deze kaart je dat je mag hopen op een positieve uitkomst. Daarom wordt de Negen van Bekers vaak ook de Wenskaart genoemd.</p><p>Let er wel voor op dat je niet te zelfzeker wordt of te zorgeloos want dat houdt risico’s in. Het blijft immers zo dat alles wat je doet gevolgen heeft, niet enkel voor jezelf maar ook voor de mensen om je heen. Het feit dat jij je heel goed voelt bij bepaalde zaken of situaties betekent niet automatisch dat dit ook voor anderen geldt.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In je liefdesleven beleef je nu waarschijnlijk mooie tijden. Het zit er in dat jullie van plan zijn om jullie relatie naar het volgende niveau te tillen, en jullie beleven de liefde en betrokkenheid ook steeds intenser. Jullie gevoelens worden elke dag sterker en je vindt ook steeds weer nieuwe manieren om die emoties uit te drukken naar je partner toe. Het is best mogelijk dat je nu een intensiteit van gevoel ervaart die je nooit tevoren meegemaakt hebt. Geniet ervan!</p><p>Als je single bent dan is het nu een goede tijd om de wereld in te trekken en plezier te maken. Je straalt een sterke positiviteit uit die ervoor zorgt dat allerlei mensen zich tot je aangetrokken voelen en je willen leren kennen. Concentreer je niet teveel op het vinden van een partner maar eerder op het genieten van wat je doet. Je zal merken dat ergens in die groep nieuwe mensen zich de juiste persoon voor jou bevindt.</p>",
                        [GuidanceFields.WORK] : "<p>Deze kaart is soms symbolisch voor teamspirit, ze wijst erop dat je het gemakkelijk vindt om samen te werken, om naar een gemeenschappelijk doel toe te werken. Op dit moment ervaar je een intense harmonie in je contacten (privé en werk). Geniet hier ten volle van en zorg er ook voor dat het zo blijft: richt je op het behouden van die harmonie.</p><p>Op dit moment lijkt het alsof je geen fouten kan maken, alles wat je doet is succesvol. Misschien heb je een nieuwe techniek in je hoofd of een andere manier op bepaalde zaken te organiseren. Als dit het geval is dan is nu het juiste moment om die dingen uit te proberen. Je ideeën zullen zeker welkom zijn.</p><p>Als je op zoek bent naar een baan, plan dan nu zoveel mogelijk gesprekken. Je straalt op dit ogenblik een sterke positieve energie uit die vast indruk zal maken!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De Negen van Bekers is niet direct een heel spirituele kaart omdat ze de nadruk legt op genieten en het bevredigen van je zintuigen. Toch is het zo dat als je grondig kan genieten van alle aspecten van het dagelijkse leven, je ook makkelijker spiritueel evenwicht en harmonie zal bereiken.</p><p>Ook al ben je op dit moment druk met het bevredigen van je zintuigen, neem toch even de tijd om bij jezelf naar binnen te kijken en te visualiseren welke doelen je nog wil bereiken op spiritueel vlak. Wees niet te bescheiden, the sky is the limit!</p>",
                    }
                }
            },
            {
                filename: "45",
                nl : {
                    name: "Bekers Tien",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>De Tien van Bekers is, net zoals de Negen van Bekers, een positieve en opgewekte kaart. Het verschil met de vorige kaart is dat het hier gaat om een algemeen goed gevoel, terwijl de Negen van Bekers het vervullen van wensen en dromen belooft. De Negen vertelt je dat er zegeningen onderweg zijn naar jou, de Tien stelt vast dat je op dit moment je zegeningen aan het tellen bent en er volop van geniet. Je kan je nu veilig en vol zelfvertrouwen voelen in het bewustzijn dat je omgeven wordt door mensen die van je houden en je koesteren. Nu is het een moment om gewoon van het leven te genieten en blij te zijn met je situatie zoals die is. Ontspan je, neem de tijd om op te gaan in alle leuke dingen die je kan bedenken. Bekijk het alsof je nu even vakantie neemt van zorgen en piekeren. Je hoeft niets te plannen, geen problemen op te lossen, enkel te leven!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit moment kan je je gerust tevreden en ontspannen voelen in je relatie. Ook al heb je in het verleden pijn, frustratie en stress gekend, je hebt al die gevoelens doorleefd en verwerkt. Nu je al dat negatieve losgelaten hebt, ga je niet meer gebukt onder die oude bagage. Niets houdt je nu nog tegen, je kan ten volle genieten van de liefde en intimiteit die je deelt met je geliefde. Het is ook best mogelijk dat je nu een stap verder wil zetten, je nog meer wil engageren. Dat kan gaan van samenwonen tot trouwen of misschien samen een kind krijgen. Let er wel op dat je je partner de tijd en ruimte geeft om zelf ook tot op dit punt te geraken. Maak je niet ongerust, het juiste moment komt zeker. Het is gewoon een kwestie van mekaars tempo respecteren.</p><p>Als je op zoek bent naar een partner dan is het op dit moment beter om niet fanatiek op zoek te gaan maar integendeel de tijd te nemen om gewoon te genieten van je sociale leven. Ontspan je en maak plezier, de juiste persoon zal dan vanzelf in je leven komen.</p>",
                        [GuidanceFields.WORK] : "<p>De Tien van Bekers wijst erop dat alles op dit moment goed verloopt op het werk. Je collega’s en meerderen weten heel goed hoe hard je werkt en ze vinden het fijn om met jou samen te werken. Je bent een echte teamspeler en dat wordt zeker gewaardeerd. Maar ook al ben je populair, het is nu niet te goede tijd om je te ontspannen en het rustig aan te gaan doen. Om een positieve indruk te blijven maken is het belangrijk dat je tijd en moeite blijft investeren. Een goede sfeer op het werk is een kwestie van consequent geven en nemen.</p><p>Als je op zoek bent naar een (andere) baan dan is het nu het juiste moment om contacten te leggen. Je communiceert tegenwoordig namelijk heel positief en duidelijk, je straalt engagement uit. Deze kenmerken zorgen ervoor dat je je van je beste kant laat zien in interviews!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritueel gezien ervaar je nu waarschijnlijk een gevoel van intense vreugde en innerlijke vrede. Je voelt je in harmonie met alles en iedereen, je voelt je één met het universum. Gebruik deze gevoelens om ook in je omgeving, je relaties, familie en vrienden een gevoel van vrede en harmonie te creëren. Deel je spirituele rijkdom met iedereen die op je weg komt. Op dit moment geniet je van een overvloed aan positieve energie. Door dit te delen met anderen zal je je nog beter voelen. Tenslotte is het echt wel zo dat gedeelde vreugde dubbele vreugde betekent!</p>",
                    }
                }
            },
            {
                filename: "46",
                nl : {
                    name: "Page van Bekers",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart symboliseert speelsheid en spontaniteit maar ook verzoening en mensen nog een kans geven.</p><p>De Page van Bekers vertelt je dat het op dit moment goed is om op een eenvoudige manier van het leven te genieten. Je mag best spontaan zijn, mogelijk zelfs een beetje kinderlijk. Denk niet teveel na over de dingen maar volg je intuïtie en geniet gewoon van alles wat in je opkomt. Geef je innerlijke kind alle ruimte om te doen waar het zin in heeft. Je zal merken dat je op deze manier totaal  ontspannen en een heleboel positieve energie creëren en opnemen.</p><p>De tweede betekenis van deze kaart wijst je erop dat je mogelijk al enige tijd een conflict hebt met iemand. Die ander komt nu naar je toe om het goed te maken, hij/zij verlangt naar een verzoening. Misschien is je eerste reactie om afstand te houden en hier niet op in te gaan. Geef jezelf toch wat tijd om over de situatie na te denken. Best mogelijk dat je dan beseft dat je het toch goed wil maken, dat je die ander terug in je leven wil. Aanvaard dit vredesaanbod met beide handen, het zal je echt goed doen om alle negativiteit die om de situatie heen hing los te laten. De Page van Bekers trekken is ook een positief signaal als je graag zwanger wil worden.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit moment is het belangrijk dat je naar je gevoel luistert en niet naar je verstand. Als je je hart voelt overlopen van liefde voor je partner, als je een romantische bui hebt ... aarzel dan niet, ga aan de slag. Organiseer een weekend om samen te ontspannen en te genieten van mekaar, koop een geschenk waarvan enkel je partner echt weet wat je ermee bedoelt. Gebruik je fantasie en je hart om je partner te verrassen en te verwennen. Maak je niet druk of je misschien sentimenteel overkomt, laat je verstand niet in de weg komen, volg je gevoel en wees echt romantisch voor een keer!</p><p>Als je op dit moment een conflict hebt met je partner probeer het dan zo snel mogelijk bij te leggen. Het leven is te kort om ook maar een minuut te besteden aan ruzie!</p><p>Als je op zoek bent naar een geliefde bereid je er dan op voor dat je binnenkort waarschijnlijk iemand zal ontmoeten waar je smoorverliefd op wordt. Ga helemaal op in dit gevoel en geniet ervan zolang als het duurt.</p>",
                        [GuidanceFields.WORK] : "<p>Als je de Page van Bekers krijgt in verband met je werksituatie dan betekent dit dat de zon binnenkort zal tevoorschijn komen van achter de wolken, er is hoop. Zelfs al ben je op dit moment ongelukkig in je baan, heb je het gevoel dat je in een geestdodende sleur zit, dan nog vertelt deze kaart je dat het nu het juiste moment is om je dromen en verlangens eens goed te bekijken en een manier te zoeken om ze waar te maken. Het maakt niet uit hoe moeilijk je situatie nu is, je hebt altijd keuzes en mogelijkheden. Ga eens te rade bij je innerlijke kind en luister zorgvuldig naar wat het te vertellen heeft, op die manier zal je ontdekken welke richting de juiste is voor jou.</p><p>Als je op zoek bent naar werk dan kan je nu best je dromen nog eens een kans geven, ook al heb je ze misschien al lang opgegeven. Herbekijk ze, reik naar de sterren, geloof in de mogelijkheid dat jij nu het onmogelijke kan waarmaken!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Waarschijnlijk heb je doorheen de jaren een aantal spirituele gewoontes gekregen, heb je ook een bepaalde manier om naar de dingen te kijken waar je helemaal aan gewend bent. Vergeet nu even hoe comfortabel je je voelt bij het oude vertrouwde. Zet ramen en deuren open en gooi alles buiten wat met je spirituele verleden te maken heeft. De tijd is gekomen om nieuwe dingen te ontdekken, nieuwe rituelen te vinden. Luister naar je intuïtie, je innerlijke kern, laat je inspireren door je ware zelf en kijk waar je uitkomt. Je hebt een oneindig grote spirituele vijver om in te vissen, voel je vrij en ga ervoor!</p>",
                    }
                }
            },
            {
                filename: "47",
                nl : {
                    name: "Ridder van Bekers",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Als je deze kaart krijgt dan is het heel waarschijnlijk dat je binnenkort goed nieuws zal krijgen. De Ridder van Bekers vertelt je dat je binnenkort aan een periode in je leven begint waarbij je vooral simpele vreugde en genieten zal ervaren. Over het algemeen gaat alles goed voor jou en voel je je energiek en actief. Je hebt geen last van stress of conflicten, je voelt vooral vrede en evenwicht. Overal waar je komt is er een liefdevolle sfeer. Dit is een ideale tijd om nieuwe mensen in je leven uit te nodigen die mettertijd goede en betrouwbare vrienden kunnen worden. Dit zijn goede tijden, geniet ervan!</p><p>Een waarschuwing: ook al ben jij op dit moment heel gelukkig, houd er toch rekening mee dat anderen wel problemen kunnen hebben en besteed daar voldoende aandacht aan. Complicaties en conflicten in de levens van je vrienden en familie verdwijnen niet gewoon omdat jij je momenteel heel goed voelt.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je met twijfels of vragen zit in verband met je relatie dan is de Ridder van Bekers een heel positieve kaart die je vertelt dat jij en je partner helemaal aan mekaar toegewijd zijn en dat jullie er mogelijk zelfs klaar voor zijn om een volgende stap te zetten die kan gaan van samenwonen over een verloving tot een huwelijk. En zelfs als je nog niet helemaal klaar bent om zo’n stap te zetten dan zal je toch merken dan dit een liefdevolle tijd is, je krijgt veel warmte en aandacht van je partner. Beantwoord dit met je eigen liefdevolle gebaren, je zal je geliefde heel gelukkig maken!</p><p>Als je alleen bent dan is het goed mogelijk dat je nu iemand zal ontmoeten die je echt van je sokken blaast en een diepe en blijvende indruk op je maakt. Aarzel niet, ga ervoor, de timing is perfect!</p>",
                        [GuidanceFields.WORK] : "<p>Op het werk verloopt alles waarschijnlijk heel goed als je deze kaart krijgt. Dit is deels een gevolg van het feit dat jij er behoorlijk ontspannen en positief bij loopt. Je voelt je ook heel creatief, je hoofd zit vol ideeën en mogelijkheden. Ook al is creativiteit een positieve eigenschap, toch waarschuwt deze kaart je ervoor dat echt zinvolle creativiteit ook een basis in de realiteit heeft. Het maakt niet uit hoe fantastisch een idee kan lijken als je erover aan het mijmeren bent, je moet het nog altijd eerst uitproberen èn het moet ook nog lukken. Zorg ervoor dat je het grootste deel van je energie gebruikt om je dromen in realiteit om te zetten, niet enkel om ze te dromen. Hetzelfde geldt als je op zoek bent naar een baan: het is zeker zinvol om erover na te denken wat de ideale job voor jou zou zijn, maar je moet er natuurlijk ook op uittrekken om die baan te vinden.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De Ridder van Bekers kan gezien worden als een spirituele boodschapper die je een uitnodiging brengt. De kaart vraagt je om nu open te staan voor alle mogelijke signalen en berichten. Misschien ontmoet je iemand die je meer vertelt over een bepaald spiritueel concept, maar het bericht kan ook minder opvallend zijn. Houd je geest open, wees alert op alle mogelijke signalen want de boodschap die je krijgt, kondigt de volgende stap van je spirituele reis aan. Eens je het signaal ontvangen hebt, sta er dan niet te lang bij stil maar ga er actief mee aan de slag. Het is immers belangrijk dat je spirituele groei stevig gegrond is in de realiteit.</p>",
                    }
                }
            },
            {
                filename: "48",
                nl : {
                    name: "Koningin van Bekers",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart heeft het over liefde en mededogen. De boodschap is ook om goed voor jezelf en de mensen om je heen te zorgen. Op dit moment bent je sterk empathisch, intuïtief en gevoelig. Deze eigenschappen zullen steeds sterker en intenser worden. Je zal merken dat je heel goed contact kan maken met anderen op het emotionele vlak. Daardoor wordt het ook gemakkelijk voor je om hen te begrijpen en te helpen. Toch blijft het even belangrijk om ook jezelf te benaderen met liefde en mededogen, je verdient dit net zoveel als anderen.</p><p>Deze kaart wijst er ook op dat het belangrijk is om op je gevoel te vertrouwen, je intuïtie. Als iets of iemand niet goed voelt, dan klopt je gevoel waarschijnlijk. De Koningin van Bekers vertelt je soms ook dat er een wijze vrouw in je omgeving is waarop je kan vertrouwen, die je door een moeilijke situatie heen kan helpen. Aarzel niet om haar steun te aanvaarden, je zal er sterker en geaarder door worden.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>De Koningin van Bekers vertelt je dat je op dit moment heel sterk en ondersteunend bent op emotioneel vlak. Je staat open voor de gevoelens van anderen en je kan ook echt intens meeleven. Je eigen emoties zijn op dit moment ook diep en hevig. Dit kan betekenen dat je je heel dicht bij je partner voelt, dat je echt kan genieten van de gevoelens die je hebt voor die ander. Genieten is natuurlijk positief maar let ervoor op dat je je niet laat overspoelen door je emoties, het leven is meer dan enkel voelen.</p><p>Als je alleen bent is het een goed idee om erop uit te trekken en nieuwe mensen te ontmoeten want je zal waarschijnlijk een potentiële partner vinden. Als dat gebeurt, let er dan wel voor op dat je je niet teveel laat meeslepen door je nieuwe geluk. Besteed zeker voldoende aandacht aan alle andere aspecten van je leven.</p>",
                        [GuidanceFields.WORK] : "<p>Op het gebied van werk is het nogal rustig op dit moment, je laat je meevoeren door de stroom. Maar misschien geldt dat niet voor sommige van je collega’s. De Koningin van Bekers nodigt je uit om je gevoelig op te stellen voor de emoties van de mensen om je heen. Neem eens de tijd om te luisteren naar wat die stille collega te vertellen heeft, vraag tijdens je koffiepauze eens aan iemand hoe het ermee gaat. Stel je algemeen open voor de zorgen van anderen, luister en leef mee. Probeer een veilige haven te creëren voor je medemensen door open en ontvankelijk te zijn. Deze kaart kan er ook op wijzen dat je misschien wat in een sleur zit. Als dat het geval is, stel je dan alert op want er kan zomaar een “Koningin” opduiken die je de helpende hand reikt.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De Koningin van Bekers nodigt je uit om contact te zoeken met je emoties en je empathie. Als je jezelf dit toestaat dan zal je merken dat het ook gemakkelijker wordt om in verbinding te treden met het universum, om je één te voelen met alles wat leeft. Maak gebruik van dit gevoel van eenheid om allerlei spirituele vaardigheden bij jezelf te ontdekken en te ontwikkelen. Stel je open voor de universele levensenergie, laat die door je heen stromen en je zintuigen wakker schudden. Deel je ervaringen met anderen, laat hen meegenieten van je overvloed. Nu is het een goed moment om een Reikibehandeling te boeken. Mogelijk word je er zelfs door geïnspireerd om zelf met energieën aan de slag te gaan.</p>",
                    }
                }
            },
            {
                filename: "49",
                nl : {
                    name: "Koning van Bekers",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>De Koning van Bekers is geen typisch mannelijke of vrouwelijke kaart, ze vertegenwoordigt vooral een persoon in evenwicht. Deze kaart symboliseert iemand die zich (ge)heel voelt, onderdeel van de universele energie. De belangrijkste kwaliteiten hierbij zijn medeleven en vriendelijkheid.</p><p>Er wordt je gevraagd om je te concentreren op je innerlijke kern, om jezelf open te stellen voor je ware essentie, je ware Zijn. Streef ernaar om je innerlijke waarheden en inzichten te ontwikkelen en er ook naar te handelen. Door dit te doen heb je een grote veranderende invloed op je eigen leven en ook op dat van de mensen om je heen. Toon aan anderen wie je bent door middel van je gedrag, niet enkel door wat je zegt. Daden spreken op dit moment veel luider dan woorden dat kunnen.</p><p>Aanvaard in de eerste plaats jezelf zoals je bent. Pas als je jezelf helemaal accepteert kan je anderen ook aanvaarden zoals ze zijn en kan je echt met hen communiceren.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit moment vind je het gemakkelijk om empathisch te zijn, je voelt en begrijpt wat in anderen omgaat en je hebt echt sympathie voor hen. Door deze houding voel je je eigenlijk dicht bij iedereen om je heen. Gevoelens en ervaringen uitwisselen en mensen op die manier verder helpen voelt heel natuurlijk aan. In je relatie voel je je nu één met je partner, jullie ervaren een niveau van intimiteit dat je nooit eerder gekend hebt.</p><p>Als je op dit moment op zoek bent naar een geliefde dan zal je waarschijnlijk iemand ontmoeten die op emotioneel vlak perfect bij je aansluit. Je zal je heel snel eigen voelen bij deze persoon, het zal mogelijk zelfs aanvoelen alsof jullie mekaar al veel langer kennen. Maak je niet ongerust, het zit goed!</p>",
                        [GuidanceFields.WORK] : "<p>Wat werk betreft voel je op dit moment niets voor de intellectuele aanpak. Je bent meer open voor sferen en gevoelens, je voelt de noden en frustraties van anderen. Als er problemen of conflicten zijn dan gebruik je je intuïtie om de dingen duidelijker te maken en mensen te helpen om het probleem op te lossen.</p><p>Je bent je er misschien niet van bewust maar je collega’s waarderen je inspanningen en je manier van zijn echt wel. Op dit moment komen ze naar jou toe als er iets op te lossen valt.</p><p>De Koning van Bekers kan erop wijzen dat je op dit moment veel steun mag verwachten van een mannelijke figuur. Hij is degene die je moet benaderen als je een probleem of een vraag hebt, als je steun nodig hebt. Hij zal je zonder aarzelen te hulp komen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De Koning van Bekers gaat over evenwicht. Om spiritueel in evenwicht te komen en te blijven is het nodig dat je je bewust bent van grenzen. Hoe ver kan je gaan in een bepaalde richting? En nog belangrijker: hoe ver wil je gaan?</p><p>Deze kaart symboliseert een actief meelevende persoon die luistert naar en geeft om de gevoelens van anderen, die hen wil helpen om te groeien. En net als je veel positieve energie in anderen investeert, komt er ook een boel positieve energie terug naar jou toe. Helpen is eigenlijk een wisselwerking, al merk je dat soms niet meteen.</p><p>Toch is het belangrijk om jezelf en anderen grenzen te stellen zodat je niet gaat overdrijven in je helpersrol. Mediteer geregeld over deze onderwerpen en je zal merken dat je een diep spiritueel evenwicht gaat ervaren.</p>",
                    }
                }
            },            
            {
                filename: "50",
                nl : {
                    name: "Zwaarden Aas",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>De Aas van Zwaarden vertegenwoordigt macht, moed, overwinning, vertrouwen. Jij alleen kan beslissen of je een nieuwe start zal maken, een start die zich op meer dan één niveau kan afspelen. Het nieuwe hoofdstuk dat je zo begint te schrijven, kan met werk te maken hebben, met veranderingen in je relatie of misschien zelfs met allebei tegelijk. De kennis en de moed om deze beslissingen te nemen kan je diep binnenin jezelf vinden. Het is nu aan jou om je bron te ontdekken en er volop gebruik van te maken. Zoals bij alles in dit leven is de eerste stap de moeilijkste, je kan zelfs het gevoel hebben dat je met je ogen dicht in het diepe duikt. Maar als je naar het grotere plaatje kijkt, dan besef je dat deze stap(pen) die je zet je in de juiste richting zullen voeren.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op dit ogenblik een vaste relatie hebt dan vertelt de Aas van Zwaarden je dat er iets in deze relatie aan de hand is waar jij je niet goed bij voelt. Op de één of andere manier voel je je niet comfortabel bij de dingen zoals ze zijn, misschien ben je zelfs ongelukkig. Het is mogelijk dat je al een tijdje met deze gevoelens zit maar dat je tot op heden de moed nog niet had om er iets aan te doen. Misschien was je te bang om je partner te verliezen of wilde je zijn/haar kant van het verhaal liever niet horen. Maar nu is het moment gekomen om een gesprek aan te gaan waarin je openlijk praat over alle dingen die voor jou niet goed zitten. Tenslotte is eerlijk zijn, hoe moeilijk het ook is, toch een stuk beter dan ongelukkig zijn en blijven. Als je op zoek bent naar liefde is het belangrijk dat je heel open en duidelijk bent over je verlangens en wensen. Op dit moment is het beter om één diepgaand en zinvol contact te hebben dan tien oppervlakkige.</p>",
                        [GuidanceFields.WORK] : "<p>Op het werk ben je er klaar voor om oude patronen en vertrouwde gewoontes los te laten. Je bent al een tijdje aan het nadenken over nieuwe en totaal andere manieren om de dingen aan te pakken. Je voelt je geïnspireerd en klaar om de dingen aan te pakken. Nu is het juiste moment gekomen om soms ingewikkelde problemen, waarvan je al een tijdje weet, aan te pakken. Het kan zinvol zijn om met collega’s over deze dingen te praten, misschien staan ze ook wel open voor veranderingen. Zelfs als je niet onmiddellijk waardering krijgt voor je acties, vertrouw erop dat je meerderen zien wat je doet en je ervoor zullen belonen, geef hen de tijd om erover na te denken.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Deze kaart vertelt je dat het mogelijk is dat je spiritueel gezien op een dood spoor zit. Je houdt nu al een tijdje vast aan dezelfde overtuigingen, hetzelfde geloof. Je begint het gevoel te krijgen alsof ze je niet meer passen, je lijkt er wel uit gegroeid te zijn. Neem je tijd, onderzoek andere manieren van denken, andere overtuigingen, praat uitgebreid met mensen die op een ander spiritueel pad zitten dan jij. Sta jezelf toe om geïnspireerd te worden door zaken die je tot nu toe niet interesseerden. Er is helemaal niets fout met veranderen van mening. Het betekent enkel dat jijzelf ook grondig veranderd bent.</p>",
                    }
                }
            },
            {
                filename: "51",
                nl : {
                    name: "Zwaarden Twee",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Op deze kaart zie je een geblinddoekte persoon die twee zwaarden vasthoudt die perfect in evenwicht zijn. Deze zwaarden wijzen je op het belang van evenwicht in je innerlijk en in je leven. Ze symboliseren ook hoe belangrijk het is om naar beide kanten van een situatie, een probleem te kijken. Deze kaart gaat over twijfelen en aarzelen èn over de behoefte aan evenwicht. Op dit moment is het belangrijk dat je je afvraagt of alle situaties en relaties in je leven helemaal in evenwicht zijn. Zie je de dingen echt zoals ze zijn? Of is je oordeel en visie troebel, ben je mogelijk blind op bepaalde vlakken? Behandelen mensen je als hun gelijke? Of ben je de sterkere of zwakkere persoon in het contact? Alvorens je “je zwaarden trekt” en de confrontatie gaat opzoeken, is het misschien verstandig om eerst binnenin jezelf op zoek te gaan naar antwoorden, om te luisteren naar je innerlijke stem. Eens je voor jezelf vastgesteld hebt hoe belangrijk het is om meer evenwicht in je leven te krijgen, kan je met anderen gaan communiceren om je doelen te bereiken.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op dit moment een relatie hebt dan is Zwaarden Twee een teken dat je een moeilijke periode eindelijk achter je kan laten. Je staat aan het begin van een ontspannener en evenwichtiger hoofdstuk. Denk hier niet teveel over na, twijfel niet, wees niet sceptisch ... nu is het tijd om naar je hart te luisteren en het te volgen, ga voor je gevoel. Geniet volop van deze evenwichtige relatie die jullie samen waargemaakt hebben!</p><p>Als je op zoek bent naar een relatie, gun jezelf dan nu de tijd om aan wat zelfonderzoek te doen. Heb je je vorige relatie echt helemaal verwerkt? Of zijn er nog problemen en vragen die je beter zou doornemen om ze daarna te kunnen loslaten? Als dat laatste het geval is, pak deze dingen dan allereerst aan. Het zou te gek zijn om een potentiële partner te ontmoeten terwijl je nog half in het verleden zit. Bedenk ook dat een partner er niet is om jou gelukkig te maken. De ideale situatie is dat jij al gelukkig bent en dat je dan iemand vindt waarmee je dat geluk kan delen.</p>",
                        [GuidanceFields.WORK] : "<p>Als het over werk gaat dan vertelt Zwaarden Twee je dat je met wat twijfels zit in verband met je toekomst, welke weg je best zou kiezen. Het is altijd verstandig en positief om zorgvuldig na te denken over het te volgen pad en te nemen besluiten. Zet alles op een rijtje en neem dan een beslissing. Waak ervoor om niet te lang te twijfelen, blijf niet hangen in het nadenken. Je loopt het risico dat je dan apathisch wordt of zelfs onverschillig.</p><p>Het is ook mogelijk dat je op dit moment aan het wachten bent op een beslissing van je meerderen. Probeer rustig te blijven en geef hen alle tijd die ze nodig hebben. Als je ongeduldig aan het worden bent, toon dit dan zeker niet!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op spiritueel vlak stel je jezelf een aantal vragen en zit je ook met twijfels. Je vraagt je aan de ene kant af of je je teveel hebt laten beïnvloeden door de gedachten en meningen van andere mensen, aan de andere kant heb je het gevoel dat je je geloofssysteem misschien moet gaan herbekijken. Stop met piekeren!</p><p>Je bent spiritueel veel beter in evenwicht dan je van jezelf denkt. Je bent gegroeid, geëvolueerd op manieren die je zelf niet doorhebt. De overtuigingen die je hebt, passen perfect bij je en dat is het enige wat telt op dit moment. Ga je spirituele zelf niet vergelijken met anderen, je bent uniek!</p>",
                    }
                }
            },
            {
                filename: "52",
                nl : {
                    name: "Zwaarden Drie",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>De afbeelding bij Zwaarden Drie is een hart dat doorboord wordt door drie zwaarden. Dit beeld maakt duidelijk dat er op een of andere manier pijn komt kijken bij de betekenis van deze kaart. Maar maak je niet ongerust, het gaat niet enkel over pijn maar ook over groeien.</p><p>Als je deze kaart krijgt dan is het mogelijk dat je een verstandige, intellectuele beslissing genomen hebt maar dat je hart het er niet mee eens kan zijn. Als dit het geval is, dan kan je weliswaar tijdelijk een gevoel van opluchting ervaren omdat je je niet langer in een moeilijke situatie bevindt. Maar op een dag zullen je gevoelens je inhalen, word je opnieuw geconfronteerd met je (verstandelijke) beslissing en zal je deze emoties moeten verwerken. Als je dat niet doet dan zal je merken dat je telkens weer dezelfde beslissing neemt omdat je hart je telkens weer in dezelfde situatie terecht doet komen.</p><p>Het is ook mogelijk dat je deze kaart krijgt omdat je diep gewortelde emotionele problemen hebt die je niet durft aan te pakken. Denk eraan dat deze problemen niet verdwijnen als je ze verstopt en negeert. Ga de confrontatie aan met jezelf zodat je alles een plek kan geven en dan loslaten.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op dit moment een liefdesrelatie hebt dan heb je of zal je binnenkort verdriet hebben. Dit betekent niet automatisch dat er een breuk zal volgen. Het is ook mogelijk dat jullie het samen gewoon even moeilijk hebben. Misschien ben je teleurgesteld in je partner of je relatie omdat waar je op hoopte niet uitgekomen is. De realiteit kan soms minder fijn of intens zijn dan je fantasie. Probeer voor ogen te houden dat je iemand niet kan dwingen om van je te houden of je op een bepaalde manier te behandelen, al probeer je nog zo hard. Als je partner niet spontaan van je houdt dan kan je dat niet veranderen, gelijk hoeveel je investeert in de relatie. Misschien is de tijd gekomen om je conclusies te trekken en ernaar te handelen.</p>",
                        [GuidanceFields.WORK] : "<p>Het is best mogelijk dat je de situatie op je werk op dit moment behoorlijk onaangenaam vindt. Misschien zijn er zelfs dingen gaande die je kwetsen in je ego en je eigenwaarde. Denk eraan dat jouw waarde, wie jij bent als mens, niet enkel en zelfs niet vooral afhangt van je werk. Je bent veel gevarieerder en gecompliceerder dan enkel dat ene stukje.</p><p>Wat ook kan is dat je op het punt staat een beslissing te nemen in verband met je werk die eigenlijk in je nadeel zou kunnen uitdraaien. Bekijk al je opties zorgvuldig en denk goed na voor je iets doet dat je niet meer kan terugdraaien.</p><p>Als je op zoek bent naar werk dan ben je mogelijk niet in de juiste richting aan het zoeken. Het zou kunnen dat je jezelf onder- of overschat. En er zijn ook tal van gebieden die je nog niet onderzocht hebt maar die best interessant voor je kunnen zijn. Verbreed je blikveld een beetje.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op spiritueel gebied is dit een goed moment om wat tijd voor jezelf te nemen en je af te vragen hoe je je voelt: voel je je compleet en evenwichtig, ben je gelukkig met alles en iedereen in je leven? Of zijn er toch wat dingen die je beter zou loslaten? Misschien gaat het om mensen die je liever niet meer om je heen wil, misschien gaat het om gewoontes die je wil veranderen. Wat het ook is dat je niet zint, pak het aan. Eens je klaar bent met je “voorjaarsschoonmaak” kan je je gaan afvragen welke nieuwe mogelijkheden je wil gaan onderzoeken. Wie en wat wil je verwelkomen in je leven? Ook al kan je de antwoorden op deze vragen enkel binnenin jezelf vinden, het is toch mogelijk dat je wel wat ondersteuning kan gebruiken bij je zoektocht. Aarzel niet om hulp te vragen!</p>",
                    }
                }
            },
            {
                filename: "53",
                nl : {
                    name: "Zwaarden Vier",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Zwaarden Vier kondigt in de eerste plaats een (al dan niet vrijwillige) rustperiode aan maar heeft het ook over grenzen. Misschien heb je behoefte aan een pauze maar het kan ook zijn dat de omstandigheden je ertoe dwingen. Een andere mogelijkheid is dat iemand die belangrijk voor je is behoefte heeft aan wat tijd voor zichzelf.</p><p>Als jij degene bent die behoefte heeft aan een onderbreking dan zal je merken dat het heel belangrijk voor je is dat de mensen om je heen je grenzen respecteren en je niet proberen te overhalen om het anders aan te pakken.</p><p>Denk hieraan als mensen in je omgeving ook een pauze willen en respecteer hun grenzen. Ze hebben vast goede redenen om tijdelijk wat afstand te willen. Zwaarden Vier vertelt je dat het nu de goede tijd is voor jou om neen te leren zeggen en om het ook te respecteren als anderen neen tegen jou zeggen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op dit ogenblik een relatie hebt dan is het mogelijk dat je een zekere afstand voelt. Je vindt het moeilijk om te communiceren met je partner, om je echt ‘samen’ te voelen en je voelt je daar helemaal niet goed bij. Alhoewel het heel belangrijk is om open te communiceren met je partner, denk er ook aan dat het niet de taak van jouw partner is om ervoor te zorgen dat jij je beter voelt bij hem/haar en bij de relatie. Het is jouw taak om je gevoelens te onderzoeken en er vervolgens iets mee te doen. Misschien heb je gewoon wat tijd voor jezelf nodig om dingen op een rijtje te zetten.</p><p>Als je op dit moment alleen bent dan is het niet de juiste tijd om op zoek te gaan naar een partner. Geef jezelf wat ruimte om erover na te denken waar je juist naar op zoek bent, welke eigenschappen wil je dat je toekomstige partner heeft? Je zal achteraf blij zijn dat je hiervoor de tijd genomen hebt.</p>",
                        [GuidanceFields.WORK] : "<p>In je werksituatie heb je mogelijk het gevoel dat de dingen helemaal niet gaan zoals jij zou willen dat ze gaan. En toch is het nu het verkeerde moment om veranderingen door te voeren of mensen in een bepaalde richting te duwen. Sommige veranderingsprocessen hebben tijd nodig. Op dit moment zou het beter zijn om wat vrijaf te nemen als dat mogelijk is, zodat je wat kan rusten en ontspannen. Je hebt het verdiend!</p><p>Als je op zoek bent naar werk dan is het een goed idee om de komende tijd geen sollicitatiegesprekken te plannen. Je bent op dit moment niet echt geduldig en dat kan je kansen om aangenomen te worden erg negatief beïnvloeden. Neem wat tijd voor jezelf, je hebt het nodig.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op spiritueel vlak is het op dit ogenblik erg belangrijk dat je de tijd neemt om eens helemaal alleen met jezelf te zijn. Het is best mogelijk dat de mensen om je heen op zoek zijn naar inzichten en antwoorden en dat ze denken die bij jou te vinden, maar op dit moment is dat een slecht idee. Je hebt het nu echt nodig om op je Zelf te focussen, tot rust te komen en je te concentreren op de kosmische energieën en vibraties om je heen. Laat hun helende kracht op je inwerken. Ontspan je, genees, word weer geheel, één met het universum.</p>",
                    }
                }
            },
            {
                filename: "54",
                nl : {
                    name: "Zwaarden Vijf",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Als je Zwaarden Vijf trekt dan is het goed mogelijk dat je je helemaal niet positief voelt. Het lijkt erop dat er nogal wat dingen gaande zijn in je leven waar je niet gelukkig mee bent. Mogelijk zijn er ook mensen in je omgeving die de dingen op zo’n manier aanpakken dat je er nerveus van wordt. Alhoewel het belangrijk is dat je deze gevoelens onderkent en dat je ze probeert aan te pakken, is het op dit moment waarschijnlijk het slimste om even geen confrontaties aan te gaan. Je bent tegenwoordig niet de meest redelijke persoon en als je mensen op hun gedrag aanspreekt is het mogelijk dat je brokken maakt die niet gauw gelijmd kunnen worden. Als je iemand ergens op wil aanspreken, haal dan eerst diep adem en tel tot 10, en dan nog eens. Denk er goed en diep over na en voer dat gesprek enkel en alleen als je zeker bent dat het onvermijdelijk is.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart vertelt je dat er in je relatie belangrijke knelpunten zijn die aangepakt moeten worden. Het is mogelijk dat je partner op zo’n manier over je grenzen heengaat dat je het niet meer kan accepteren. Hierdoor komen er bij jou misschien bepaalde negatieve gevoelens boven zoals boosheid, tegenzin, mogelijk zelfs een behoefte om wraak te nemen. Probeer je te concentreren op de problemen zelf en vraag je af wat nu de knelpunten zijn. Als je die eenmaal duidelijk hebt, kan je de volgende stap zetten en met je partner gaan praten. Het is best mogelijk dat je deze problemen samen kan oplossen.</p><p>Als je op dit moment single bent, dan is het waarschijnlijk niet de juiste tijd om iemand te vinden. Je voelt je nu immers gefrustreerd en misschien zelfs boos omdat je alleen bent. Pak deze gevoelens eerst aan, verwerk ze, geef ze een plek. Pas als je beter in je vel zit, als je jezelf en je situatie kan aanvaarden, pas dan is de tijd gekomen om op zoek te gaan naar een partner.</p>",
                        [GuidanceFields.WORK] : "<p>Zwaarden Vijf wijst je erop dat je niet gelukkig bent in je werksituatie, misschien ben je zelfs behoorlijk gefrustreerd. De dingen verlopen helemaal niet zoals jij het liefst zou willen. Misschien is het nu het juiste moment om er even bij stil te staan welke rol jij speelt in deze situatie. Misschien sijpelt je frustratie wel door in de commentaren die je geeft, mogelijk ben je op dit ogenblik iets te bevooroordeeld om echt helder en objectief te denken. Voor je de eerste stap zet in de richting van een conflict, is het misschien verstandig om te bedenken dat er in deze situatie waarschijnlijk geen winnaars zullen zijn, iedereen zal er iets bij inschieten. Pak je negatieve gevoelens aan, probeer ze te verwerken en los te laten. Eens je dat gedaan hebt, zal je merken dat je opnieuw opbouwend kan gaan denken.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Deze kaart vertelt je dat je op dit moment misschien wat te zelfverzekerd bent. Je bent ervan overtuigd dat jouw spirituele weg, je ontdekkingen en de antwoorden die je gevonden hebt de enige juiste zijn. Misschien probeer je zelfs de mensen om je heen ervan te overtuigen dat ze fout zitten en dat jij de goede kant aan het opgaan bent. Denk eraan dat wat past voor jou misschien niet past voor anderen, we zijn immers allemaal uniek en hebben daarom ook unieke antwoorden en inzichten.</p><p>Wees blij dat jij je antwoorden gevonden hebt en geef anderen de ruimte om die van hen te vinden.</p><p>Het is ook mogelijk dat je tegenwoordig het gevoel hebt dat je geen voeling meer hebt met de spirituele inzichten die zo lang heel belangrijk voor je waren. Dit kan je erg onzeker maken, je uit balans halen. Probeer ernaar te kijken als een uitdaging: trek erop uit en zoek nieuwe antwoorden en inzichten!</p>",
                    }
                }
            },
            {
                filename: "55",
                nl : {
                    name: "Zwaarden Zes",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Zwaarden Zes wijst je erop dat er binnenkort in je leven een (misschien kleine) verandering ten goede zal komen. Deze verbetering heeft altijd iets te maken met beweging, verplaatsing, al kan dit zowel letterlijk als figuurlijk zijn. Dit betekent niet automatisch dat je situatie en hoe jij je voelt ineens perfect zullen zijn, maar het zal wel een stukje beter gaan dan voorheen.</p><p>Deze kaart geeft ook een aanwijzing dat het nu een goede tijd is om op reis te gaan of te verhuizen, je te verplaatsen, je omstandigheden te veranderen. Nu is het moment gekomen om je buiten je comfortzone te begeven en ergens anders een plek te gaan zoeken waar je je goed kan voelen. Let op: dit kan nog steeds zowel letterlijk als figuurlijk zijn!</p><p>Op een bepaalde manier ben je je aan het voorbereiden om een moedige stap vooruit te zetten, om een aantal vertrouwde zaken achter je te laten. Dit betekent niet noodzakelijk dat je al het bekende definitief achterlaat. Het is ook mogelijk dat je gewoon een pauze neemt om dichter bij jezelf te komen en te ontdekken wat je echt wil en nodig hebt.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart vertelt je dat je klaar bent voor verandering, misschien heb je die zelfs echt nodig op dit moment. Als je in een relatie zit, dan is het nu een goede tijd om een aantal patronen te doorbreken waar jullie aan gewend zijn. Zoek nieuwe plekken op, praat over andere onderwerpen, kies een andere soort films of boeken. Verandering is op dit ogenblik gewoon erg goed voor jullie, je zal er positieve resultaten mee boeken.</p><p>Als je single bent en op zoek naar een partner dan is ook voor jou de tijd rijp om oude gewoontes los te gaan laten. Ga de liefde niet meer zoeken op de bekende plekken en de bekende manieren. Wees avontuurlijk en sla wegen in die je totaal onbekend zijn. Was internetdaten nooit iets voor jou? Probeer het dan nu eens uit. Je zal merken dat je meer succes hebt dan je verwachtte met je nieuwe methodes!</p>",
                        [GuidanceFields.WORK] : "<p>Op het gebied van werk en carrière begin je nu aan een nieuwe fase, een nieuw hoofdstuk. Misschien krijg je nieuwe taken toegewezen, zit er een promotie in of verander je daadwerkelijk van baan. Het is ook mogelijk dat je volop nieuwe manieren aan het ontdekken bent om naar je ‘oude’ job te kijken. Eén ding is zeker, je laat een aantal vertrouwde zaken achter je en je gaat nu voor nieuwe uitdagingen en ervaringen, je bent volop aan het groeien. Vraag je nu af waar je heen wil, wat je wil bereiken, welke doelen je jezelf wil stellen. En vertrouw erop dat de mensen om je heen je willen ondersteunen bij deze evolutie.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>JMogelijk besef je op dit moment dat je in je spirituele zoektocht alsmaar dezelfde vragen aan het stellen bent en dat je er maar niet in slaagt om bevredigende antwoorden te vinden. Als dit het geval is, hou dan op met vragen stellen en ga actief aan de slag. Verander je gewoontes, stop met het raadplegen van alsmaar dezelfde boeken en sites, hou op met die gesprekken waarvan je het resultaat toch al kent. Verander ook eens je manier van mediteren en visualiseren. Het is een goed idee om nu aan het experimenteren te gaan, om onontgonnen terrein te verkennen, om eens nieuwe continenten te gaan bezoeken in de spirituele wereld! Besteed ook extra aandacht aan je dromen, je kan er boodschappen in vinden, ze kunnen je inzicht geven in zaken die je niet onderkent als je wakker bent.</p>",
                    }
                }
            },
            {
                filename: "56",
                nl : {
                    name: "Zwaarden Zeven",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart heeft het over oneerlijkheid in alle mogelijke vormen en verschijningen. Als je Zwaarden Zeven trekt dan is het goed mogelijk dat je op dit moment heel slecht behandeld wordt of dat jij dit aan het doen bent met een ander. De oneerlijkheid waarvan sprake kan gaan van liegen over bedrog tot sabotage en manipulatie. Deze kaart vertelt je dat het belangrijk is om aandachtig en alert te zijn en je altijd vragen te stellen in verband met de motieven van anderen. Het is ook belangrijk dat je aandacht besteedt aan jezelf en jouw motieven want misschien ben jij zelf ook niet echt eerlijk bezig op dit moment.</p><p>In het allerslechtste geval kan het zo zijn dat jij zelf oneerlijk bent en dat iemand anders ook oneerlijk is ten opzichte van jou.</p><p>Maar eigenlijk maakt het niet uit hoe ingewikkeld of eenvoudig de situatie in mekaar zit, het gaat erom dat je er nu, op dit moment, voor moet zorgen dat er geen oneerlijkheid, onduidelijkheid of geheimen meer zijn in je leven.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op dit moment een relatie hebt dan is de tijd gekomen om jezelf wat moeilijke vragen te stellen: vertrouw jij je partner, heb je twijfels of vragen, ben je zelf betrouwbaar bezig of wijk je af van het rechte pad? Het is belangrijk om aan jezelf toe te geven dat deze en andere vragen echt gesteld en beantwoord moeten worden. Ga een open en eerlijk gesprek aan met je partner, stel die moeilijke vragen en beantwoord ze ook eerlijk als je partner ze aan jou stelt. Nu komt het erop aan om open kaart te spelen, het kan je relatie redden!</p><p>Als je alleen bent en op zoek naar een relatie, zorg er dan voor dat je dit open en eerlijk doet. Speel geen spelletjes, doe niet alsof iemand je niet interesseert als het omgekeerde net waar is. Je zou de ideale partner kunnen missen omdat je niet oprecht overkomt.</p>",
                        [GuidanceFields.WORK] : "<p>In je werksituatie is het op dit moment verstandig om je voor te bereiden op mogelijk zware tijden. Het zit erin dat je meerdere van plan is om je werkmethodes en resultaten van heel nabij te onderzoeken. Zorg ervoor dat alles in orde is, dat zij/hij zo weinig mogelijk op- of aanmerkingen kan maken. Houd zoveel mogelijk afstand van roddel en conflicten, kies niemands kant, houd je bij jezelf. Wees op alle vlakken zo neutraal mogelijk.</p><p>Als je op zoek bent naar werk is het nu een goede tijd om vroegere collega’s en oude contacten te benaderen, je zou er behoorlijk wat kansen mee kunnen creëren.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Waarschijnlijk ben je op dit moment zoekende, voel je een soort spirituele rusteloosheid. Als je op zoek bent naar antwoorden zorg er dan voor dat je dit op de juiste plekken doet. Laat je niet verleiden tot het luisteren naar zogenaamde spirituele leiders, laat die dure cursus of workshop links liggen, koop die boeken die je ontdekt hebt niet. Besteed je tijd nu aan het focussen op jezelf, keer je naar je binnenste, je diepste kern en besef ten volle hoeveel kennis je al vergaard hebt. Verwerk al deze wijsheid, laat ze groeien en bloeien en pluk de vruchten van het harde werk dat je in het verleden verzet hebt. Op dit ogenblik kan je jezelf vele waardevolle lessen leren!</p>",
                    }
                }
            },
            {
                filename: "57",
                nl : {
                    name: "Zwaarden Acht",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>De Acht van Zwaarden heeft het over vastzitten. Over het algemeen word je geblokkeerd door je eigen twijfels en angsten. Je staat jezelf niet toe om te groeien, te evolueren, te veranderen. De mogelijkheden en kansen lachen je toe en jij blijft stilstaan. Je bent bang om ze te grijpen, je wordt overheerst door “Ja maar ...”.</p><p>De tijd is nu rijp om jezelf te confronteren met je angsten en twijfels en je af te vragen waar ze vandaan komen. Het is heel waarschijnlijk dat je ontdekt dat ze nergens voor nodig zijn, dat je jezelf tegenhoudt vanwege een fantasie, een illusie. Het is net als toen je klein was en je echt geloofde dat er monsters onder je bed of in de kast zaten.</p><p>Probeer de volgende vraag te beantwoorden: waarom sta jij jezelf niet toe om helemaal die persoon te zijn die je zou kunnen en moeten zijn? Waarom sta jij jezelf toe om verlamd te zijn door angsten en onzekerheden?</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je een partner hebt, stel jezelf dan de volgende vraag: waarom ben ik hier, met deze persoon? Misschien blijf je in deze relatie zitten omdat je bang bent voor eenzaamheid. Misschien voel je je onzeker, vrees je ervoor dat je niet sterk genoeg bent om het alleen te redden. Of misschien denk je dat je niets beters kan krijgen, dat dit het best mogelijke is, ook al is het niet echt fantastisch. Als je één van deze motieven herkent bij jezelf, evalueer je relatie dan eens grondig, beslis nu voor eens en altijd of dit het is wat je echt wil. Alleen zijn is helemaal niet zo erg, en het is zeker beter dan blijven hangen in een relatie die je eigenlijk niet wil en waar je niet in gelooft.</p><p>Als je alleen bent, neem dan eens de tijd om je huidige leven grondig te evalueren: vind jij jezelf echt ok, ben je gelukkig met wie je bent? Als het antwoord ja is, dan ben je er klaar voor om op zoek te gaan naar een partner.</p>",
                        [GuidanceFields.WORK] : "<p>Neem nu even de tijd om jezelf, je baan en je carrière open en eerlijk te bekijken en je dan de vraag te stellen of je echt wel op deze plek wil zijn en ook blijven. Houd je van je baan? Word je ‘s ochtends vrolijk wakker en ben je blij dat je aan de slag kan gaan? Of sleep je jezelf naar het werk, en zou je eigenlijk liever in bed blijven liggen, vraag je je elke dag af waarom je deze job ook alweer gekozen hebt? Als je niet gelukkig bent met je baan dan is het belangrijk dat je beseft en ook aanvaardt dat jij de enige bent die de macht heeft om deze situatie te veranderen. Het is jouw beslissing: of je blijft waar je nu bent en je aanvaardt het zoals het is met alles erop en eraan, of je gaat op zoek naar een andere baan? Wat is jouw keuze?</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Het is best mogelijk dat je je wat onzeker voelt, dat je twijfels hebt, je afvraagt wie je wil zijn en wat je wil voelen. Je zit misschien vol met vragen en je weet niet goed waar je de juiste antwoorden moet gaan zoeken. En net deze antwoorden kan je enkel binnenin jezelf vinden. <strong>Jij</strong> hebt alle sleutels in handen. Probeer een verbinding tot stand te brengen met de universele energie, ga mediteren, wees alleen met jezelf, concentreer je op je diepste innerlijke kern. Sta open voor tekens en signalen en denk eraan dat deze tekens zo simpel kunnen zijn als een bepaald liedje horen op de radio of hetzelfde cijfer overal zien. Concentreer je op het gegeven dat jij zelf de enige bent die je tegenhoudt. Laat dat inzicht binnenstromen, geef het de ruimte!</p>",
                    }
                }
            },
            {
                filename: "58",
                nl : {
                    name: "Zwaarden Negen",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>De Negen van Zwaarden kan wijzen op slapeloze nachten vol gepieker en angsten. Het is mogelijk dat je op dit ogenblik veel aan je hoofd hebt en dat je je gedeprimeerd voelt. Misschien heb je zelfs een stukje een schuldig geweten dat je ’s nachts wakker houdt.</p><p>Toch is het best mogelijk dat je vooral op zoek bent naar mensen en omstandigheden die de oorzaak kunnen zijn van je huidige gevoelens. Probeer de rest van de wereld los te laten en concentreer je op jezelf: wat kan jij doen om je gevoelens te veranderen? Zelfs als het zo is dat je verdriet hebt over een bepaalde relatie of een bepaalde situatie, dan nog kan jij gaan uitzoeken wat er exact aan de hand is en wat jij eraan kan doen om je gevoel erbij te veranderen.</p><p>Misschien voel je je op dit moment zo dat je denkt:”Is dit echt alles wat er is? Zou er niet meer moeten zijn?”. Als dat het geval is, probeer je dan te concentreren op de kleine dingen die wèl goed zitten en waar je vreugde en rust uit kan halen. De schoonheid van het leven zit ‘m dikwijls in de kleine dingen, er schuilt goddelijkheid in vaak onopvallende details.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Zwaarden Negen gaat over twijfels en aarzelen. Ben je bang om achtergelaten te worden? Of ben je aan het overwegen om de relatie te verbreken voor je partner ertoe overgaat? Of misschien heb je het gevoel dat er iets helemaal niet goed zit maar ben je te bang om erover te beginnen? Op dit moment mag je je intuïtie echt niet negeren. Praat met je partner, stel de moeilijke vragen, sta erop dat je ook antwoorden krijgt. Maar wees niet meer wantrouwig eens je die antwoorden gekregen hebt. Als je er niet op kan vertrouwen dat je partner je eerlijk antwoordt, dan horen jullie misschien niet (meer) samen te zijn.</p><p>Als je net aan een nieuwe relatie begonnen bent, dan is het belangrijk dat je aanvaardt dat het tijd kost om vertrouwen op te bouwen. Geef jezelf en je partner de tijd en de ruimte om het vertrouwen èn de relatie langzaam maar zeker te laten groeien.</p>",
                        [GuidanceFields.WORK] : "<p>Best mogelijk dat je je op dit moment een beetje down voelt, gedeprimeerd. Vraag je eens af of deze gevoelens veroorzaakt worden door je job zelf of door problemen met je baas of een collega. Of misschien ben je op het ogenblik verlamd door angst om te falen? In verband met je werksituatie zijn er vele aspecten die je niet onder controle hebt: je kan je collega’s niet veranderen, je kan geen nieuwe baas aanstellen, je hebt je taken niet voor het uitkiezen. Maar toch zijn er een aantal dingen waar je wel invloed op hebt en het is belangrijk dat je nu op die dingen gaat focussen. Laat je zorgen en angsten los en concentreer je op alle aspecten die je wel kan veranderen.</p><p>Als je op zoek bent naar werk dan is het nu de juiste tijd om hulp in te schakelen. Praat met vrienden, buren en kennissen want het is best mogelijk dat er iemand tussen zit die de perfecte contactpersoon heeft voor jou, die de ideale vacature aan je kan doorgeven.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op dit moment kan het voelen alsof je dag en nacht aan het nadenken en zelfs piekeren bent. Misschien heb je de indruk dat je niet in staat bent om je brein stil te zetten. Je denkt niet enkel na over echte conflicten en uitdagingen maar je piekert ook over ingebeelde dingen. Het is best mogelijk dat je een aantal problemen zelf aan het creëren bent. Probeer je spiritualiteit te gebruiken om een einde te maken aan dit negatieve patroon. Meditatie kan een goede manier zijn om je tot rust te brengen en je nooit eindigende gedachtenstroom te stoppen. Als je het te moeilijk hebt om te kunnen mediteren, ga dan op zoek naar een goed boek, bv. eentje met citaten. Je kan ook een yogales volgen of een reikibehandeling nemen. Probeer allerlei dingen uit tot je erin slaagt om je brein tot positieve gedachten te brengen, het is dringend nodig!</p>",
                    }
                }
            },
            {
                filename: "59",
                nl : {
                    name: "Zwaarden Tien",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>In de eerste plaats wijst deze kaart op een krachtig, mogelijk zelfs een agressief eind en een nieuwe start. Een situatie of een relatie in je leven is net gestopt of stopt binnenkort. Het is mogelijk dat dit einde niet jouw keuze was. Misschien ben je zelfs diep ongelukkig over deze verandering. Toch is het belangrijk voor je om eraan te denken dat het einde van een hoofdstuk iets totaal anders is dan het einde van jouw wereld. Probeer je te ontspannen, los te laten, en vertrouw erop dat het universum je de weg zal wijzen naar een betere toekomst.</p><p>In de tweede plaats kan Zwaarden Tien ook wijzen op oneerlijkheid en mogelijk zelfs verraad. Als je twijfels hebt over iemands betrouwbaarheid, denk er dan aan dat het beter is om op veilig te spelen dan dat je achteraf spijt krijgt. Als een aanbod of een voorstel te goed klinkt om waar te zijn, dan is het meestal ook zo. Als iemand te vriendelijk of te gedienstig is, dan heeft die persoon vaak andere motieven dan je op het eerste zicht zou denken.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je Zwaarden Tien krijgt dan is het goed mogelijk dat je op dit moment een relatiebreuk doormaakt of dat er eentje zit aan te komen. Misschien waren de problemen in je relatie zich stilletjes aan het opstapelen terwijl jij met iets anders bezig was? En mogelijk werd je op een dag wakker en besefte je dat de situatie zo ingewikkeld, zo moeilijk geworden was dat geloven in een oplossing misschien niet meer realistisch was. Aanvaard het gegeven dat je een relatie niet in je eentje kan repareren, daar moet je met zijn tweeën voor zijn. Leg je neer bij je verlies en laat los.</p><p>Als je op zoek bent naar een relatie dan is dit er echt niet de beste tijd voor. En als je iemand zou ontmoeten die een goede kandidaat lijkt, denk er dan goed over na en nog eens en nog eens. De persoon die je ontmoet kan zich namelijk totaal anders voordoen dan ‘ie in werkelijkheid is.</p>",
                        [GuidanceFields.WORK] : "<p>Wat je werksituatie betreft, is het belangrijk dat je op dit moment aandachtig en alert bent. Het is goed mogelijk dat je binnenkort misschien zal moeten gaan uitkijken naar een andere baan. Dit betekent niet noodzakelijk dat je je werk niet goed deed, daar gaat het hier waarschijnlijk niet om. Het feit dat men mogelijk niet langer beroep op je wil doen, heeft vaak niets te maken met de kwaliteit van je werk. Er zijn nog tal van andere factoren die meespelen.</p><p>Maar gelijk hoe de situatie in mekaar zit of wat de oorzaken zijn, het is nu erg belangrijk dat je jezelf niet toestaat om “arme ik” te zijn. Zelfs al vind je dat je onrecht is aangedaan, ook al heeft iemand je een mes in de rug gestoken, probeer je geest open te houden. De toekomst behoort jou toe, het is nu aan jou om haar vorm te geven. Doe dus je uiterste best om er een positieve en schitterende toekomst van te maken!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op dit moment zou het best kunnen dat je spirituele overtuigingen, wat je gelooft en misschien zelfs zeker denkt te weten, aan het afbrokkelen zijn. Mogelijk blijken bepaalde dingen waar je echt van overtuigd was toch onwaar te zijn. Goeroes, gidsen, raadgevers ... mensen waar je echt in geloofde blijken eerder geïnteresseerd in je geld dan in je spirituele groei. De positieve kant van dit verhaal is dat je de dingen kan gaan loslaten die toch al niet goed voor je waren. Je kan beginnen met een gloednieuwe schone lei. Dit is echt de kans van je leven: trek erop uit en ga op zoek naar wijsheid en inzichten. En vertrouw deze keer op je intuïtie, die zal je vertellen wie je wel en niet kan vertrouwen!</p>",
                    }
                }
            },
            {
                filename: "60",
                nl : {
                    name: "Page van Zwaarden",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>In de Tarot betekenen de Pagekaarten altijd nieuwe mogelijkheden en kansen. De Page van Zwaarden vertelt je dat het op dit moment belangrijk is om open te staan voor nieuwe inzichten en helderheid. Als je een conflict hebt met iemand, dan zal je nu ineens begrijpen wat er fout gegaan is en waarom dat gebeurd is. De tijd is nu echt rijp om te leren van je ervaringen.</p><p>Als je deze kaart krijgt, is het ook mogelijk dat je heel veel energie hebt op dit moment. Over het algemeen is dit een goede zaak maar het is altijd mogelijk dat je deze energie op zo'n manier gebruikt dat de mensen om je heen er last van hebben. Vraag je eens af of je sommige dingen niet te hard aan het doordrukken bent of misschien ben je te duidelijk, te openhartig. En laten we eerlijk zijn, je vindt het zelf ook niet altijd gemakkelijk om de kritiek die je krijgt ook te aanvaarden.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Er hangt duidelijk nogal wat spanning in de lucht in je persoonlijke relaties. Onopgeloste conflicten komen boven, lichte wrijvingen die je niet aangepakt hebt, vragen nu je aandacht. De keuze is aan jou: besluit je om geen slapende honden wakker te maken terwijl ze eigenlijk al één oog open hebben? Of ga je de confrontatie aan ook al loop je het risico dat je de problemen mogelijk niet kan oplossen? De Page van Zwaarden vertelt je dat elke keuze die je maakt ook gevolgen zal hebben. Zelfs als je zou besluiten om helemaal niets te doen, dan nog zal de situatie veranderen. En er bestaat nu eenmaal geen manier om iedereen tevreden te houden. Op dit moment is het verstandigst om de weg te volgen die je energie je toont, doe datgene wat het beste aanvoelt.</p><p>Als je single bent en op zoek naar een relatie dan is nu de tijd gekomen om uit je schelp te kruipen. De liefde zal niet komen aankloppen, je zal de wereld in moeten en ernaar op zoek gaan!</p>",
                        [GuidanceFields.WORK] : "<p>Als je op dit moment aan het werk bent dan houd je er best rekening mee dat er wat conflicten of problemen kunnen komen. Denk eraan dat sommige mensen de waarheid nu eenmaal niet aankunnen en gedraag je daarnaar. Je hoeft niet altijd je mening te uiten, soms is het beter om anderen te observeren en zo te ontdekken vanwaar de wind komt.</p><p>Als je op zoek bent naar werk dan is het verstandig om het wat rustiger aan te doen in je sollicitatiegesprekken. Luister naar de mensen, toon hen dat je openstaat voor andere opvattingen en visies dan de jouwe. Maak hen duidelijk dat je soepel bent en je gemakkelijk kan aanpassen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Het liedje klopt: free your mind and the rest will follow - als je je geest bevrijdt, dan volgt de rest vanzelf. Ontdek de spirituele wereld met hetzelfde enthousiasme dat je gewoonlijk enkel opbrengt voor meer wereldlijke zaken. Zoek boeken die je aanspreken, mensen om mee te praten, meditaties die je wil uitproberen. Verzamel zoveel mogelijk kennis en zoek dan mensen om hierover van gedachten te wisselen. Door geconfronteerd te worden met andere informatie, inzichten en meningen zal je vanzelf groeien en ook intense spirituele ervaringen krijgen. Je zal merken dat deze spirituele groei een diepgaande invloed heft op andere aspecten van je leven.</p>",
                    }
                }
            },
            {
                filename: "61",
                nl : {
                    name: "Ridder van Zwaarden",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Als je deze kaart kiest dan is het duidelijk dat je op dit ogenblik een overvloed aan energie hebt. En meer dan dat: je staat ook te trappelen om al die energie te gebruiken! Maar voor je erop uitgaat om problemen, conflicten of mensen aan te pakken, kan je je misschien eerst afvragen of je op dit moment echt wel een gevecht wil aangaan. Je bent nu energetisch behoorlijk sterk dus het is goed mogelijk dat je die gevechten ook wint. Maar misschien zal je je doelen eerder bereiken door te gaan communiceren in plaats van mensen te overweldigen met je krachtige uitstraling. Ga het gesprek aan, vertel die ander hoe jij je voelt en vraag de ander ook hoe zij/hij zich voelt. Probeer empathie en mededogen te tonen want op dit moment is dat de meest constructieve manier om problemen aan te pakken. Denk goed na en kies zorgvuldig welke strijd je wel en welke je niet wil aangaan.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Je voelt je op dit ogenblik heel energiek. Tegelijk zit je ook meer in je verstand dan in je gevoel. Dit kan in je liefdesrelatie eventueel voor problemen zorgen omdat je conflicten en onenigheid wil oplossen door erover na te denken, door te redeneren. Maar het is waarschijnlijk dat je partner meer bezig is met de emotionele aspecten van de situatie. Je partner wil op dit moment waarschijnlijk liever <strong>voelen</strong> dat je betrokken bent in plaats van te horen hoe jij je betrokkenheid beschrijft. Probeer hierin een evenwicht te vinden, je mag best rationeel zijn maar op dit moment is het belangrijk dat je ook empathisch bent, zeker als je een toekomst wil voor deze relatie.</p><p>Als je alleen bent dan is dit een goed moment om erop uit te gaan en jezelf te tonen aan de wereld. Dof jezelf op, trek je partykleren aan en ga feesten. Je positieve energie zal een diepe indruk maken op de mensen die je ontmoet en je kan hier best een mogelijke partner aan overhouden!</p>",
                        [GuidanceFields.WORK] : "<p>Op dit moment heb je een overvloed aan energie en dat valt je collega’s en meerderen echt wel op. Ga ervan uit dat je meer taken of zelfs nieuwe projecten krijgt. Je hebt nu trouwens zoveel energie dat je dit of zelfs een hoger tempo best aankan, je straalt als een ster! Het is best mogelijk dat je met wat jaloezie van collega’s te maken krijgt omdat je op dit moment zo efficiënt en productief bent. Laat je daar niet door tegenhouden en laat je ook niet verleiden tot discussies. Verlaag jezelf niet tot dat niveau, integendeel! Probeer empathisch te zijn, zelfs begrip op te brengen voor hun gevoelens en gedachten. Tenslotte is het best mogelijk dat je op een dag in hun schoenen zal staan.</p><p>Als je op zoek bent naar werk dan is de Ridder van Zwaarden een heel positieve kaart die je vertelt dat de dingen waarschijnlijk zo zullen verlopen als jij het graag wil. Het zit er dik in dat iemand je nèt die baan aanbiedt waar je al een tijd naar op zoek bent.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>De Ridder van Zwaarden vertelt je dat je op dit moment heel veel energie hebt. Je bent er klaar voor om de wereld tegemoet te treden en in actie te schieten! Spiritueel gezien heb je twee opties. Je kan jezelf onderdompelen in boeken, cursussen en leringen en op die manier proberen om zoveel mogelijk kennis te vergaren. Maar je kan ook afstand nemen van al die energie die in je borrelt omdat die tenslotte ook deel uitmaakt van de jachtige wereld. En als je je helemaal focust op je innerlijke rijkdom kan je misschien stilte en harmonie vinden. Is het niet mogelijk dat het net dàt is wat je nodig hebt om in evenwicht te geraken?</p>",
                    }
                }
            },
            {
                filename: "62",
                nl : {
                    name: "Koningin van Zwaarden",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>De Koningin van Zwaarden draait rond intelligentie, onafhankelijk denken en alert zijn. Ze nodig je uit om je verstand te gebruiken, niet je gevoel, als je problemen en vragen wil aanpakken. En op dit moment is het verstandig om vanop een afstand naar je eigen leven te kijken. Beslis daarna welke problemen je onmiddellijk wil oplossen en welke nog even kunnen wachten. Jij weet immers het beste wat je wil en nodig hebt. Wat je nu te doen staat is een plan maken om je doelen te bereiken. Beslis welke aanpak je voorkeur geniet. Wees voorzichtig hierin, houd rekening met de gevoelens en grenzen van de mensen om je heen.</p><p>Deze kaart is symbolisch voor een sterke intellectuele energie die je er soms toe kan brengen dat je anderen kwetst of beledigt. Op dit punt heb je immer de neiging om contact te zoeken met anderen op een intellectueel niveau zonder er emoties bij te betrekken. En net deze houding kan voor sommigen moeilijk te aanvaarden en te begrijpen zijn.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Je voelt je op dit moment sterk en rationeel en deze houding is ook terug te vinden in je standpunt ten opzichte van relaties. Let hiermee op, zeker in een liefdesrelatie is het belangrijk om niet te dominant te zijn, je mening niet op te dringen. Jij hebt nu de neiging om alle problemen en vragen op een verstandelijke manier te benaderen en dat is natuurlijk je volste recht. Maar het betekent niet automatisch dat je geliefde ook in zijn of haar verstand zit. Misschien is het net het omgekeerde en is je partner zelf heel emotioneel op dit moment. Probeer vriendelijk en meevoelend te zijn, aanvaard dat de motieven en argumenten van je partner evenwaardig zijn aan de jouwe, ook al zijn ze niet rationeel. Als je wil dat deze relatie blijft duren dan is het essentieel dat je mekaar ergens in het midden vindt.</p><p>Als je op zoek bent naar een geliefde dan is het belangrijk dat je je niet te zelfverzekerd opstelt, je zou immers arrogant kunnen overkomen en dat vindt niemand aantrekkelijk.</p>",
                        [GuidanceFields.WORK] : "<p>Op dit moment is je brein sterk en snel, je vindt het gemakkelijk om de kern van een probleem of een vraagstelling te zien. Houd er toch rekening mee dat je collega’s (en misschien zelfs je superieuren) er nog niet klaar voor zijn om de naakte waarheid te horen. Probeer hun grenzen te respecteren. Probeer je inzichten aan te bieden in een aantrekkelijke verpakking. Als je het op deze manier aanpakt zal je merken dat mensen het makkelijker vinden om jouw meningen te aanvaarden.</p><p>Als je op zoek bent naar werk dan zal je intellect je helpen om jezelf voor te stellen op de best mogelijke manier. Het is bijna zeker dat je een goede indruk zal maken bij interviews. Gebruik dit voordeel en je zal vast de baan vinden waar je naar op zoek bent!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Ook al is de Koningin van Zwaarden symbolisch voor je denkvermogen, het is natuurlijk vanzelfsprekend dat onder al die gedachten ook emoties te vinden zijn. Zoek je weg voorbij het intellectuele en duik in de rivier van je emoties, laat ze over je heen spoelen en probeer bij de bron te geraken, ontdek op die manier waar al die gevoelens vandaan komen. Wat je nu ontdekt zal je de weg wijzen naar een gloednieuw spiritueel pad. Je zal merken dat je in alle mogelijke aspecten van het dagelijkse leven spirituele boodschappen kan ontdekken. Sta hiervoor open, deze boodschappen zijn je gidsen.</p>",
                    }
                }
            },
            {
                filename: "63",
                nl : {
                    name: "Koning van Zwaarden",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>De Koning van Zwaarden is intelligent en ernstig. Hij is ook intellectueel en heeft een snelle, scherpe geest. Als je deze kaart trekt dan is het mogelijk dat je slim bent en een weloverwogen inzicht hebt maar je kan ook doorslaan naar sarcasme en agressie. Misschien ben je soms zelfs een beetje een tiran. Het is nu aan jou om te beslissen of je begrijpend, analytisch en rijk aan inzichten wil zijn of dat je koppig en cynisch door het leven wil gaan.</p><p>Je bent nu op een punt gekomen waar je beseft dat al deze eigenschappen deel uitmaken van je persoonlijkheid. Wees je hiervan bewust en beslis dan wie en wat je echt wil zijn.</p><p>Deze kaart kan er ook op wijzen dat je te maken hebt met een koppig iemand die totaal niet bereid is om van mening te veranderen. Als je de tijd neemt om diep na te denken over deze situatie, kom je misschien tot de conclusie dat het voor <strong>jou</strong> het beste zou zijn om van mening te veranderen zodat de situatie draaglijk wordt.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je deze kaart trekt dan is het mogelijk dat je je op dit moment erg rationeel opstelt in je persoonlijke relaties. Je hebt de behoefte om problemen en conflicten op een intellectuele manier te benaderen zonder rekening te houden met de emoties die er deel van uitmaken. Dit standpunt zal je helpen om door te dringen tot de kern van de zaak. Maar op een gegeven moment zal je moeten aanvaarden dat je partner niet op dezelfde golflengte zit en dat je ook met een aantal emoties rekening zal moeten houden.</p><p>Deze kaart kan ook betekenen dat je een relatie hebt met iemand die behoorlijk dominant en verstandelijk is. Mogelijk gaat deze persoon daar te ver in en gaat hij/zij geregeld over je grenzen heen. Jij kan het gedrag van deze persoon niet veranderen dus is het belangrijk dat je jezelf en je grenzen zorgvuldig in het oog houdt. Zorg dat je snel genoeg aan de bel trekt!</p><p>Als je single bent en op zoek naar een partner, denk er dan aan dat je de ware niet vindt door enkel intellectuele normen te gebruiken. Je moet ook rekening houden met wat je voelt bij iemand.</p>",
                        [GuidanceFields.WORK] : "<p>Op dit moment is je werk doen voor jou een fluitje van een cent. Je stelt je tactisch en soepel op en balanceert soms op het randje van manipuleren. Ben je er zeker van dat je op deze manier te werk wil gaan?</p><p>Het is ook mogelijk dat je op je werkplek geconfronteerd wordt met iemand die er heel uitgesproken meningen op nahoudt die hij/zij ook met de nodige overtuiging verkondigt. Als je last hebt van dit gedrag vraag je dan af hoe het komt. Misschien ben je zelf een beetje te emotioneel op dit moment, hoe komt dit, waardoor wordt dit veroorzaakt?</p><p>Als je op dit moment op zoek bent naar werk, denk er dan aan dat het altijd verstandig is om bij een gesprek de leiding bij de interviewer te laten. Het maakt niet uit hoe zelfzeker je bent, dit gesprek is geen wedstrijd, je hoeft niet te winnen, je hoeft de ander enkel duidelijk te maken wat je kwaliteiten zijn.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Je kijkt op een verstandelijke manier naar spiritualiteit. Het is erg belangrijk voor jou om helemaal te begrijpen wat er gezegd wordt voor je het kan toepassen of zelfs maar aanvaarden. Op dit moment is het een goed idee om een mentor te zoeken die een sterke persoonlijkheid en vaste, duidelijke overtuigingen heeft. Enkel zo’n persoon kan je alle informatie geven die je op dit moment nodig hebt om een mening te vormen. Het is ook belangrijk dat je geregeld mediteert. Het maakt niet uit wat jouw spirituele inzichten en overtuigingen zijn, op dit moment heb je behoefte aan een actieve aanpak.</p>",
                    }
                }
            },
            {
                filename: "64",
                nl : {
                    name: "Pentakels Aas",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Azen zijn in de Tarot de kaarten die symbolisch zijn voor kansen en nieuwe mogelijkheden. Pentakels Aas staat voor geluk, innerlijke rijkdom en een nieuwe start. Dit betekent niet dat je zomaar alles gratis en voor niets gepresenteerd krijgt zonder dat je er zelf iets voor hoeft te doen. Je moet er natuurlijk zelf op uit trekken en op zoek gaan naar dat geluk, die rijkdom, die start. Toch vertelt deze kaart je dat je op weg bent naar een positieve periode in je leven waarin je je echt goed voelt. En het kan zelfs nog beter gaan worden! Het positieve kan betrekking hebben op alle elementen van je leven: relatie, werk, financiën.</p><p>Je kan Pentakels Aas vergelijken met Bekers Aas, het zijn allebei heel positieve kaarten die geluk beloven op allerlei manieren.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op dit moment een liefdesrelatie hebt dan zit je ofwel middenin een prachtige periode of je bent ernaar op weg. Je voelt je gelukkig in je relatie, je bent toegewijd aan je partner en het contact tussen jullie wordt alsmaar dieper, intenser en rijker aan betekenis. Het is zelfs mogelijk dat jullie samen op weg zijn aan een nieuw engagement. Dat kan gaan over samenwonen, je verloven, kinderen krijgen of trouwen.</p><p>Als je single bent dan wijst deze kaart erop dat je waarschijnlijk binnenkort iemand zal ontmoeten. Nu is echt een ideale tijd voor jou om erop uit te gaan en nieuwe mensen op te gaan zoeken. Geniet van het gezelschap van deze onbekenden en kijk uit naar die ene persoon waar je misschien mee samen zou willen zijn.</p>",
                        [GuidanceFields.WORK] : "<p>Pentakels Aas is over het algemeen een positieve kaart. Ze vertelt je dat je het goed doet op je werk. Het zit erin dat je binnenkort meer verantwoordelijkheid krijgt of zelfs een promotie. Natuurlijk zullen deze veranderingen er ook voor zorgen dat je het financieel beter krijgt.</p><p>Als je overweegt om van baan te veranderen dan is dit het juiste moment. Je zal merken dat er kansen op je pad komen zelfs als je er niet echt naar op zoek bent. Als je momenteel werkloos bent, ga dan nu volop op zoek. Stel je open voor alle mogelijke opties die je op je af ziet komen en zoek het ook even wat dichter bij huis zodat je niets over het hoofd ziet.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Voor jou zijn je spirituele vragen en inzichten een heel persoonlijke zaak en je vindt het absoluut niet gemakkelijk om hierover met anderen te praten. Toch vertelt deze kaart je dat het nu het juiste moment is om op zoek te gaan naar mensen met gelijkaardige spirituele overtuigingen en je ervaringen met hen te delen. Je zal merken dat dit een heel verrijkende ervaring voor je kan zijn. Door dit samenkomen met anderen zal je visie op de fundamentele menselijke eenzaamheid (die we allemaal af en toe ervaren) volledig veranderen.</p><p>Over het algemeen is het nu een goede tijd om nieuwe richtingen te bestuderen, om jezelf open te stellen voor zienswijzen die je tevoren nog nooit onderzocht had.</p>",
                    }
                }
            },
            {
                filename: "65",
                nl : {
                    name: "Pentakels Twee",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Pentakels Twee draait rond evenwicht, ofwel ben je op zoek naar evenwicht, ofwel probeer je in evenwicht te blijven. Het is best mogelijk dat je op een aantal vlakken in je leven niet in balans bent en dat je gewoon probeert om de boel draaiende te houden. Misschien weet je wel dat je een aantal beslissingen moet nemen maar ben je er nog niet uit welke kant je op wil. Als dit het geval is, weet dan dat het verstandig is om nu naar je intuïtie te luisteren. Dat is wat deze kaart je vertelt: diep vanbinnen weet je al wat je te doen staat, je moet het enkel nog onder ogen zien.</p><p>Soms wijst deze kaart er ook op dat het belangrijk voor je is om financieel wat meer evenwicht te vinden, probeer wat je verdient en wat je uitgeeft beter in balans te brengen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op dit moment een liefdesrelatie hebt dan is het best mogelijk dat je denkt dat alles in orde is, dat jullie samen een fijne tijd hebben. Maar als je de moeite doet om de situatie wat nader te bekijken dan zal je beseffen dat je niet genoeg tijd doorbrengt met je partner. Op deze manier zal je relatie niet evenwichtig zijn en blijven. Maak nu tijd voor die ander, voordat het te laat is en je hem/haar verliest. Als je op dit ogenblik alleen bent, vraag je dan af of dit wel een goede tijd is om op zoek te gaan naar liefde in je leven. Het gaat er niet om dat je de perfecte partner moet zijn maar toch, als je je wil engageren in een relatie, moet je er klaar voor zijn om een aantal dingen op te offeren, om plaats te maken in je leven voor die ander, of het werkt niet. Ben je zeker dat je hier klaar voor bent? Of heb je eerst nog wat tijd nodig om te “spelen”?</p>",
                        [GuidanceFields.WORK] : "<p>Op dit moment ben je heel soepel, je past je gemakkelijk aan op werkgebied, nieuwe taken en projecten aanpakken vind je helemaal niet moeilijk. Deze benadering houdt ook risico’s in want het is mogelijk dat je teveel hooi op je vork neemt en dat je dan het grotere plaatje uit het oog verliest. Zorg ervoor dat je geconcentreerd blijft op alles wat je doet en verspil geen energie aan heen en weer lopen van de ene taak naar de andere.</p><p>Als je twijfelt of je wel op de juiste plek zit, dan is nu het goede moment om je horizon te verbreden en op zoek te gaan naar iets anders.</p><p>Als je op zoek bent naar een baan, probeer je dan te concentreren op telkens twee of drie mogelijkheden, niet meer. Het is niet zinvol om tientallen sollicitaties te versturen want dan dreig je het overzicht te verliezen. Pak het liever wat kleinschaliger aan maar wees bij elke sollicitatie zo grondig mogelijk.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Pentakels Twee vertelt je dat het goed mogelijk is dat je niet tevreden bent met hoe de zaken verdeeld zijn in je leven. Je hebt het gevoel dat al je verantwoordelijkheden veel te veel tijd en energie van je vragen. Je hebt het idee dat je daardoor geen enkele ruimte hebt om spiritueel te groeien. Het is heel belangrijk dat je er nu grondig over nadenkt hoe je je leven en je agenda dusdanig kan veranderen dat je ook tijd kan maken om spiritueel ‘bij te tanken’. Niet enkel je lichaam moet onderhouden en gevoed worden, je geest en je ziel hebben dat ook nodig. Probeer hierin meer evenwicht te vinden, het is erg belangrijk voor je levenskwaliteit, nu en in de toekomst.</p>",
                    }
                }
            },
            {
                filename: "66",
                nl : {
                    name: "Pentakels Drie",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart is over het algemeen positief. Ze vertelt je dat je de dingen goed aanpakt en dat er geen grote veranderingen nodig zijn. Je kan natuurlijk beslissen om bepaalde zaken anders aan te pakken, maar deze kaart vertelt je dat het niet noodzakelijk is, het kan maar het moet niet.</p><p>Stel dat je bepaalde veranderingen overweegt, praat er dan over met de mensen om je heen. Volg hun advies niet blindelings, denk erover na en volg dan toch de weg die jou het beste lijkt.</p><p>Deze kaart vertelt je ook dat wie je bent en wat je doet gewaardeerd wordt door de mensen om je heen. Ze zijn blij dat je in hun leven bent ook al laten ze je dat niet altijd weten.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Pentakels Drie wijst erop dat je aan het overwegen bent om een belangrijke stap te zetten in verband met je relatie, een stap die jou en je partner dichter bij mekaar zal brengen. Jullie hebben op dit moment geen problemen maar jij voelt de behoefte aan meer intimiteit, meer samenzijn met hem/haar, vandaar die stap.</p><p>Als je op dit moment single bent en er is iemand waarin je geïnteresseerd bent, dan vertelt deze kaart je dat die persoon jou ook leuk vindt. Dit betekent natuurlijk niet dat jullie onmiddellijk smoorverliefd in mekaars armen zullen vallen maar wel dat jullie allebei denken dat de ander potentieel partnermateriaal is en dat het zinvol is om mekaar beter te leren kennen.</p>",
                        [GuidanceFields.WORK] : "<p>Op het gebied van werk en carrière brengt deze kaart een positieve boodschap. Ze vertelt je dat je goed in je vel zit op het werk, dat mensen waarderen wat je allemaal doet en hoe je het doet en dat ze zich echt wel bewust zijn van je talenten en kwaliteiten. Het is heel waarschijnlijk dat je een promotie zal krijgen of dat je binnenkort toch minstens een stap in die richting zal zetten. Je bent echt goed in wat je doet en je zal er mettertijd zelfs nog beter in worden.</p><p>Als je op zoek bent naar werk dan mag je ervan uitgaan dat je in de komende tijd vindt waar je naar op zoek bent.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritueel gezien is het nu een goed moment om naar inspiratie te gaan zoeken in het verleden, de geschiedenis. Misschien zijn er klassieke denkrichtingen of filosofen die je eens wat nader wil bestuderen, of misschien heb je interesse voor oude rituelen en gewoontes. Dit betekent zeker niet dat je je moet gaan aansluiten bij een of andere sekte of club. Op dit moment gaat het erom dat je gaat genieten van het verwerven van kennis op zichzelf, dat je daar voldoening in vindt. Studeren en onderzoeken is een fantastische manier om je geest te voeden, om ervoor te zorgen dat je de wereld om je heen op een andere, diepere manier gaat zien en begrijpen.</p>",
                    }
                }
            },
            {
                filename: "67",
                nl : {
                    name: "Pentakels Vier",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Als je naar deze kaart kijkt dan zie je een man die letterlijk op een aantal munten zit en die zich zelfs vastklampt aan eentje. Het ziet er naar uit dat hij bang is om ze los te laten. En net daar gaat het om bij deze kaart: je vastklampen aan dingen, bang zijn om mensen, bezittingen, gewoontes of gevoelens los te laten. En als logisch vervolg daarop gaat deze kaart natuurlijk ook over angst voor verandering en een bijna wanhopige behoefte aan veiligheid, je beschermd voelen.</p><p>Eén ding is duidelijk: als je vasthoudt aan mensen, gevoelens, gewoontes en bezittingen betekent dit absoluut niet dat je ook veilig zal zijn. Het zorgt er enkel voor dat je star en angstig wordt, dat je je nauwelijks nog durft te bewegen. Starheid en angst maken het onmogelijk voor je om nog te groeien, te bewegen, van het leven te genieten. Pentakels Vier vertelt je dat je dringend zal moeten veranderen als je iets aan het leven wil hebben, als je je leven echt wil gaan beleven.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart wijst op angst. Je bent op dit ogenblik bang om je partner te verliezen, om achtergelaten te worden. Daarom klamp je je vast aan hem/haar, houd je de ander in zo’n stevige greep dat ze het moeilijk krijgen om zelfs maar te ademen. Als je blijft doorgaan met deze houding dan zal je exact datgene veroorzaken wat je het meeste vreest: je partner zal je verlaten, al is het maar uit zelfbehoud. Als je echt van iemand houdt dan moet je hen vrijlaten, vrij om te doen wat zij willen. En je moet hen vertrouwen, erin geloven dat ze niets zullen doen om je te kwetsen of te verraden. Bekijk je diepste angsten, ga de confrontatie ermee aan. Je zal merken dat je ze dan achter je kan laten. Het is immers net omdat je van je angst wegloopt dat die je blijft achtervolgen. Enkel als je je angsten loslaat, kan je je partner eindelijk de ruimte geven om vrijelijk voor jou te kiezen en helemaal voor jullie relatie te gaan.</p>",
                        [GuidanceFields.WORK] : "<p>Op het werk ben je nogal strak, bijna star, tegenwoordig, je houdt je vast aan oude gewoontes en vertrouwde manieren om bepaalde dingen aan te pakken. Je vindt het erg moeilijk om je aan te passen aan nieuwe regels en procedures, zelf als je dat uitdrukkelijk gevraagd wordt. Het wordt echt tijd dat je gaat beseffen dat er een vorm van vrijheid schuilt in het loslaten van oude dingen, de vrijheid om te leren en te groeien en misschien zelfs de ‘nieuwe’ aanpak te gaan waarderen.</p><p>Misschien vind je je baan niet meer fijn, blijf je er enkel dag na dag mee doorgaan vanwege de veiligheid die het je biedt. Misschien haal je geen vreugde meer uit je werk, geen voldoening. Als dat het geval is, sta jezelf dan eens toe om na te denken over een andere baan, een nieuwe uitdaging, nieuwe kansen. Als je andere mogelijkheden gaat onderzoeken is het mogelijk dat je blijft waar je bent, maar dan zal het een bewuste keuze zijn, geen verplichting.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Pentakels Vier vertelt je dat het nu een goede tijd is om in jezelf op zoek te gaan naar een aantal antwoorden: wat zijn je grootste angsten? Aan welke mensen klamp je je echt vast? Welke voorwerpen, gevoelens of mensen kan je moeilijk loslaten? En tenslotte: waarom vind je het nodig om bepaalde dingen, mensen, gevoelens zo stevig vast te houden? Wat denk je dat er zal gebeuren als je ze zou loslaten? Kijk open en eerlijk naar jezelf en probeer deze vragen te beantwoorden. Eens je alle antwoorden op een rijtje hebt, zal je gaan beseffen dat de ware vrijheid er net in ligt dat je loslaat, dat je ruimte creëert tussen jezelf en alle andere mensen en dingen. En als je dan uiteindelijk begint los te laten en echt ervaart hoeveel ruimte je hierdoor krijgt, zal je merken dat je voor het eerst sinds lange tijd weer diep kan ademhalen. En je zal de spanningen uit je weg voelen vloeien naarmate je beter en dieper ademhaalt. Leven is veranderen!</p>",
                    }
                }
            },
            {
                filename: "68",
                nl : {
                    name: "Pentakels Vijf",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart wijst op verlies, moeilijke tijden, crisissen en onzekerheid. Het leven is op dit moment niet zo fijn voor jou. Misschien heb je zelfs het gevoel dat er niets of niemand is waar je op kan rekenen. Mogelijk voel je je verlaten, eenzaam, intens ongelukkig. En het kan best zo zijn dat je geen idee hebt welke koers je nu moet gaan varen. Toch zijn er ook positieve kanten aan deze kaart want ze geeft je de boodschap dat er altijd mensen zijn en zullen zijn die je willen helpen, ook al vind je het op dit moment moeilijk om dat te zien. Blijf rondkijken, doe dat zonder vooroordelen en je zal ontdekken wie er voor je wil zijn. Wat deze kaart je ook vertelt, is dat de situatie waarin je je nu bevindt een leermoment is, ook al gaat het hier om een harde les. Eens je al je problemen overwonnen hebt, zal je je sterk en positief voelen, je zal weer openbloeien. En je zal een duidelijk zicht hebben op wat je met je leven wil aanvangen, welke kant je nu op wil!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart vertelt dat je je op dit moment waarschijnlijk behoorlijk eenzaam voelt in je relatie, je krijgt niet de aandacht en liefde die je nodig hebt, het voelt alsof je partner afstandelijk is tegenwoordig. Misschien kan je niet helemaal duiden wat er precies aan de hand is maar je voelt dat de dingen niet kloppen zoals ze nu zijn. Als je geen mogelijkheid kan bedenken om deze situatie te veranderen of als je partner niet bereid is om mee te werken aan een oplossing, dan moet je misschien overwegen om de relatie te beëindigen. Houd jezelf voor ogen dat er meer dan één persoon op deze wereld is waarmee je gelukkig kan zijn.</p><p>Als je op dit moment alleen bent dan heb je misschien het gevoel dat niemand je wil, dat niemand je echt “ziet”. Je kan zelfs gaan denken dat er niemand bestaat die een liefdesrelatie met jou zou willen aangaan. En toch is het echt wel zo dat je op een dag iemand zal ontmoeten die echt om je geeft en met jou het leven wil delen. Geef de hoop niet op!</p>",
                        [GuidanceFields.WORK] : "<p>Pentakels Vijf wijst erop dat je je onzeker en bang voelt. Misschien ben je ongerust om je baan kwijt te raken of mogelijk is er een project of taak waarvan je denkt dat je het niet aankan, dat je het fout zal aanpakken. Of misschien heb je op dit ogenblik geen werk en vrees je ervoor om nog ooit iets te vinden dat bij jou past. Ook al zit je in een ontzettend moeilijke situatie op dit moment, waar het om gaat is dat je er nu aan denkt dat er altijd een uitweg is. Misschien zie je die uitweg, die oplossing nu even niet, maar ze is er wel. Zoek naar hulp in je omgeving, praat met mensen die begrijpen waar je mee bezig bent. Deze gesprekken zullen voor helderheid en inzichten zorgen en zo ben je voor je het weet op weg naar de juiste oplossing voor jouw problemen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Je zit nu even in een zware en moeilijke periode en dat heeft ook invloed op je spirituele leven en bewustzijn. Als je geregeld mediteert of andere spirituele oefeningen doet, zal je merken dat het tegenwoordig erg moeilijk voor je is om je echt te focussen, je dwaalt makkelijk af, allerlei negatieve gedachten gaan door je hoofd ‘spoken’. Misschien ervaar je ook een diepe, intense eenzaamheid alsof je spirituele backup er plots niet meer is. De moeilijke weg die je nu te gaan hebt, leg je best niet alleen af. Richt je daarom op de mensen om je heen en je zal merken dat velen er voor je willen zijn, je willen ondersteunen bij je moeilijkheden. Denk eraan, helpen is een tweerichtingsweg, zowel de helper als de geholpene voelen zich er beter door, ze hebben allebei het gevoel dat ze iets bereikt hebben. Door je te laten helpen, help jij die ander dus ook!</p>",
                    }
                }
            },
            {
                filename: "69",
                nl : {
                    name: "Pentakels Zes",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Deze kaart gaat over behulpzaam en grootmoedig zijn maar ook over verdraagzaamheid. Of je nu de helper of de geholpene bent, hier gaat het om mensen die mekaar helpen op allerlei vlak: materieel, emotioneel, financieel, spiritueel. De ene dag help je iemand uit de nood, de volgende dag is er iemand die jou een helpende hand toesteekt. Deze kaart vertelt je dat het heel belangrijk is om te delen, om vrijgevig te zijn. Hoe goed je leven ook is, er komt altijd een dag waarop je iets of iemand nodig hebt om je te helpen overleven, iemand om je te ondersteunen in je zoektocht naar geluk.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Op dit ogenblik is je relatie goed in evenwicht. Toch is het belangrijk dat je er alles aan doet om het zo te houden. Zorg ervoor dat je niet enkel geeft aan je partner, maar dat je ook steun aanvaardt van de ander, dat je je door je partner laat helpen. In moeilijke tijden is het belangrijk dat je er bent voor mekaar, dat je elkaar inspireert en moed inspreekt. Je wordt er als koppel sterker en meer één van als je samen moeilijkheden het hoofd biedt.</p><p>Als je op dit moment op zoek bent naar een geliefde dan is het belangrijk dat je iedereen in je omgeving laat weten dat je openstaat voor een ontmoeting. Het is immers heel waarschijnlijk dat je iemand zal leren kennen via vrienden of kennissen.</p>",
                        [GuidanceFields.WORK] : "<p>Op werkgebied begint het er beter uit te zien, je wordt niet enkel ondersteund door je collega’s maar ook door je meerderen. Iedereen lijkt samen te werken om ervoor te zorgen dat jij succesvol bent. Dit is dus echt een goed moment om een promotie of opslag te vragen. Of misschien ben je er al lang van aan het dromen om op zoek te gaan naar de ideale baan voor jou? Daarvoor is het nu absoluut een goede tijd, ga ervoor!</p><p>Als je overweegt om je eigen bedrijf op te starten dan is het nu het juiste moment om dit te doen.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Spiritueel gezien heb je een boel kennis en inzichten vergaard, je spirituele ‘begrip’ is grondig en diep. Toch is het mogelijk dat je je hier niet helemaal van bewust bent want je hebt soms de neiging om jezelf te onderschatten. Omdat je nu al die kennis hebt, is het belangrijk dat je die ook gaat delen met de mensen om je heen. Laat hen meegenieten met de vruchten van al je inspanningen. Gun anderen een blik op de resultaten van de spirituele reis die je afgelegd hebt. En terwijl je je spirituele rijkdom aan het delen bent met anderen zal je gaan inzien dat jij ook veel kan hebben aan de kennis van anderen. Deze wederzijdse spirituele uitwisseling zal een positieve stap zijn voor iedereen die eraan meedoet!</p>",
                    }
                }
            },
            {
                filename: "70",
                nl : {
                    name: "Pentakels Zeven",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Pentakels Zeven draait eigenlijk om geduld en oogsten wat je gezaaid hebt. Veel dingen in het leven hebben tijd nodig om te groeien en zich te ontwikkelen. Tijdens dat groeiproces is het jouw taak om deze dingen te voeden en te verzorgen, om tijd en energie te investeren. En net omdat je dat gedaan hebt is nu waarschijnlijk het moment gekomen waarop je investering opbrengsten zal voortbrengen, of het nu emotioneel, financieel of praktisch is.</p><p>Over het algemeen voel je je veilig en rustig, je hebt het gevoel dat het leven goed voor je is. Je hebt goede hoop voor je toekomst. Dit is geen tijd om te gaan twijfelen. Als dat wel het geval is, als je met twijfels zit, dan is het belangrijk dat je grondig onderzoekt waar ze vandaan komen. Je zal waarschijnlijk ontdekken dat er geen enkele objectieve reden voor is.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart vertelt je dat je relatie langzaam maar zeker aan het groeien is. Het harde werk en de energie die je in het verleden geïnvesteerd hebt werpen nu hun vruchten af. Geniet hier zoveel mogelijk van, je hebt het verdiend!</p><p>Als je net begonnen bent aan een nieuwe relatie dan is het belangrijk dat je hiervoor rustig de tijd neemt. Geniet volop van alle nieuwe en ongewone aspecten, neem uitgebreid de tijd om je partner te leren kennen. Het is nu eenmaal zo dat een nieuwe relatie pas haar volle potentieel kan bereiken als je aan een rustig tempo volop liefde en aandacht investeert en je nieuwe partner koestert op alle mogelijke manieren.</p>",
                        [GuidanceFields.WORK] : "<p>Op het werk verlopen de zaken waarschijnlijk behoorlijk goed. Je bent aan een rustig maar gelijkmatig tempo aan het groeien en evolueren en je plukt volop de vruchten van de aandacht en energie die je in het verleden geïnvesteerd hebt.</p><p>Houd er rekening mee dat het huidige hoofdstuk van je leven er niet om gaat dat je snelle vorderingen maakt of oppervlakkige oplossingen overweegt, het is net het omgekeerde. En je zal merken dat alle inspanningen die je geleverd hebt ook op de juiste manier beloond zullen worden. Je bent op dit moment financieel veilig en tevreden en dit zal nog enige tijd zo door blijven gaan.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Pentakels Zeven vertelt je dat het belangrijk is om je erop te focussen dat het leven om cyclussen draait, groeiprocessen. De ene periode investeer je volop en de komende periode geniet je van de vruchten die jouw investeringen je brengen. Wat je erin stopt komt in veelvoud naar je terug. Als je elke dag van je leven baseert op deze eenvoudige waarheid dan weet je dat, als je in mensen investeert (of het nu materieel, emotioneel, financieel of spiritueel is), je dan eigenlijk in jezelf aan het investeren bent. Wat je vandaag weggeeft, dat zal je op een dag terugkrijgen, net op het moment dat je dat het meeste nodig hebt. Geven is ontvangen.</p>",
                    }
                }
            },
            {
                filename: "71",
                nl : {
                    name: "Pentakels Acht",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Bij deze kaart gaat het vaak om werken. Ze wijst erop dat je nu in een fase komt waarin je waarschijnlijk harder zal moeten werken dan je normaal gezien doet. Het werk waarom het gaat kan zich situeren op carrièrevlak maar ook in je privéleven. Op dit moment zijn er drie dingen belangrijk: zorg ervoor dat je gefocust blijft op het doel dat je wil bereiken en niet enkel aandacht besteedt aan de inspanning zelf, besteed aandacht aan het evenwicht in je leven, en als je denkt dat je hulp nodig hebt, vraag er dan om zonder aarzelen.</p><p>Soms symboliseert deze kaart ook letterlijk werken met je handen, dingen creëren. Als dat het geval is, dan begint er nu voor jou een heel creatieve en vruchtbare periode, geniet ervan!</p><p>Tenslotte kan deze kaart er ook op wijzen dat je beginnersgeluk zal hebben.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Pentakels Acht vertelt je dat werk teveel ruimte inneemt in je relatie. Het is mogelijk dat jij te hard werkt maar het kan ook om de baan van je partner gaan die teveel ruimte inneemt. In elk geval is het absoluut noodzakelijk dat jullie meer tijd maken voor mekaar en ervoor zorgen dat je geregeld de ruimte hebt om in mekaar op te gaan. Als je dit niet doet is het mogelijk dat je relatie niet zal blijven duren. Als je op dit moment single bent, stel jezelf dan eens de vraag of je in je huidige situatie wel de ruimte hebt voor een liefdespartner en –relatie. Ben je hier absoluut zeker van? Misschien is op dit moment je werk wel belangrijker voor je dan de liefde. Als dat zo is dan is dat geen probleem, het is een kwestie van prioriteiten stellen, jij bepaalt wat je wel en niet wil. Maar misschien is het dan ook zinvol om even niet meer op zoek te gaan naar de liefde.</p>",
                        [GuidanceFields.WORK] : "<p>Het is op dit moment waarschijnlijk zo dat je veel nieuwe taken of opdrachten krijgt op het werk. Misschien is je takenpakket dusdanig groot geworden dat het je een stuk overweldigt en dat het niet meer mogelijk is om alle taken op een goede manier uit te voeren. De kwaliteit van je werk lijdt mogelijk onder je overvolle agenda. Als dit het geval is, aarzel dan niet en schakel je meerderen in om je te helpen met prioriteiten stellen. Misschien kan je tijdelijk ook een aantal taken doorgeven aan een collega.</p><p>Als je op zoek bent naar werk dan is het mogelijk dat er nu behoorlijk hoge eisen gesteld worden bij het solliciteren en dat je vaardigheden grondig getest worden. Probeer rustig te blijven, laat je niet overweldigen, je bent sterker dan je denkt en je kan deze uitdagingen zeker aan!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Het maakt niet uit of je een traditionele of alternatieve baan hebt, op dit moment is het belangrijk dat je je eens afvraagt waarom je op je huidige plek zit. Doe je echt de dingen die je het beste kan en die je het meeste aanspreken? Ben je nog steeds aan het groeien en evolueren? Of heb je je door je onzekerheid en je omgeving laten beïnvloeden bij het kiezen van je huidige baan? Als dat laatste het geval is, als je nu niet (meer) op je plek zit, vraag jezelf dan of je er klaar voor bent om op zoek te gaan naar een baan waarin je vaardigheden en eigenschappen volop tot hun recht kunnen komen, een plek waar jijzelf en je kwaliteiten echt welkom zijn. Misschien ben je er wel klaar voor om ook in je werksituatie helemaal en ook spiritueel jezelf te zijn.</p>",
                    }
                }
            },
            {
                filename: "72",
                nl : {
                    name: "Pentakels Negen",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Pentakels Negen is een positieve, sterk energetische kaart. Ze wijst er vaak op dat je op financieel en materieel vlak goed zit of dat er zelfs een verbetering komt op dat vlak. Over het algemeen kondigt deze kaart goede tijden aan. De positieve dingen die je zal ervaren kunnen zich situeren op elk gebied: werk, liefde, spiritualiteit, ... Je zal in elk geval merken dat alles beter verloopt dan je verwacht had. Probeer niet enkel te focussen op je materiële welzijn maar ook op het emotionele en spirituele, ook al is dat een stuk minder tastbaar of opvallend. Denk er ook aan om je rijkdommen te delen met de mensen om je heen, gedeelde vreugd is dubbele vreugd!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op dit moment een liefdesrelatie hebt dan zal je merken dat het contact met je partner beter en intenser is dan ooit tevoren. Jullie genieten samen van romantische en liefdevolle tijden en jullie begrijpen mekaar ook beter dan ooit, vaak zonder dat er woorden voor nodig zijn, Jullie hebben op dit moment genoeg aan een blik of een gebaar.</p><p>Als je alleen bent dan is nu het ideale moment om iemand te ontmoeten met veel potentieel, iemand die een grote kans maakt om je levenspartner te worden. Het zal je zelfs weinig moeite kosten om deze persoon te vinden. Zorg er gewoon voor dat je openstaat en geïnteresseerd bent naar mensen toe en de rest zal vanzelf naar je toe komen.</p>",
                        [GuidanceFields.WORK] : "<p>Als je een baan hebt dan is het nu een goede tijd om opslag of een promotie te krijgen, het ziet ernaar uit dat er in elk geval veranderingen ten goede komen op werkvlak. Stel je hier zeker geen vragen bij, het is duidelijk dat je deze positieve evolutie ten volle verdiend hebt!</p><p>Als je op zoek bent naar werk dan zal je merken dat er veel kansen en mogelijkheden op je weg komen. Besteed je energie eraan om deze kansen te benutten, niet om je af te vragen waar ze vandaan komen. Maak er ten volle gebruik van en je zal binnen de kortste keren een passende job vinden.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Op dit moment voel je je blij en tevreden, je zelfrespect is sterk en je hebt veel vertrouwen in jezelf. Het voelt alsof alles net zo loopt als jij het graag wil. Je geniet ook ten volle van het besef dat je in het verleden veel spirituele kennis vergaard hebt en daardoor duidelijke inzichten gekregen hebt. Je bent op spiritueel vlak dusdanig gegroeid dat je je zelfs kan opstellen als inspiratie voor anderen. Je kan mensen die nog zoekende zijn helpen met de antwoorden die jij al gevonden hebt. Deel je spirituele rijkdom vrijelijk, help anderen om de spirituele kracht te vinden in zichzelf die jij al verworven hebt.</p>",
                    }
                }
            },
            {
                filename: "73",
                nl : {
                    name: "Pentakels Tien",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Pentakels Tien is een heel positieve kaart die wijst op rijkdom, veiligheid en stabiliteit. Ze wijst erop dat je over het algemeen vrij van zorgen bent, niet enkel op materieel vlak maar ook in andere aspecten van je leven. Als je deze kaart trekt is het belangrijk erbij stil te staan dat je best niet enkel focust op materieel welzijn maar ook op je emotionele en spirituele levenskwaliteit.</p><p>Sta even bewust stil bij de weg die je tot dit punt gebracht heeft en deel je rijkdom met anderen. Het is immers best mogelijk dat je op een dag zelf iets te kort zal komen en dat je op dat moment blij zal zijn als een ander dan bereid is om zijn rijkdommen met jou te delen. Het leven is een spiegel, een echo: we krijgen wat we geven.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>In bijna al je relaties ervaar je op dit moment in je leven een liefdevolle vreugde en waardering. In verband met je liefdespartner is het best mogelijk dat je aan het overwegen bent om een volgende stap te zetten. Misschien denk je aan samenwonen, kinderen krijgen, trouwen, ... Je voelt nu immers ten volle wat het betekent om onvoorwaardelijk van iemand te houden.</p><p>Als je op dit moment single bent dan sta je waarschijnlijk op het punt om een partner te vinden. Er kan zo snel een goede kandidaat op je pad komen dat het je verrast. Ook al evolueren de dingen heel snel, probeer er toch ten volle van te genieten. Het prille begin van een liefdesrelatie is de basis van jullie verdere leven samen, beleef het bewust, ga onvoorwaardelijk voor dit avontuur samen met je nieuwe partner.</p>",
                        [GuidanceFields.WORK] : "<p>Je presteert op dit moment erg goed in je werk en het is duidelijk dat de mensen om je heen zich daarvan bewust zijn en het ook echt waarderen. Net als Pentakels Negen wijst ook Pentakels Tien erop dat er mogelijk een promotie of opslag zit aan te komen. Je voelt je ook tevreden en waardevol en je geniet echt van het werk dat je doet.</p><p>Als je op zoek bent naar werk dan is het op dit moment belangrijk dat je niet enkel volhoudt maar dat je zelfs je inspanningen verdubbelt want je bent op weg naar positievere tijden waarin je een baan zal vinden die nog fijner is dan je verwacht had!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Wat je spiritualiteit betreft ben je op dit ogenblik gelukkig, tevreden en goed in evenwicht. Het is nu zaak om intens van deze gevoelens te genieten en ze ook te delen. Vertel je inzichten aan iedereen om je heen, praat over je spiritualiteit, schrijf erover. Gebruik elke mogelijke manier om dat gevoel dat je nu ervaart vast te leggen zodat je het kan bewaren. Het leven is nu eenmaal een reeks van pieken en dalen en je weet dat er ook weer een tijd zal komen waarin je je minder goed en ontspannen voelt. Het is belangrijk dat je dan kan teruggrijpen naar je huidige gevoelens, dat je dan kan herbeleven wat je nu meemaakt. Het zal je helpen om je bewust te worden van je doel, de richting die je op wil.</p>",
                    }
                }
            },
            {
                filename: "74",
                nl : {
                    name: "Page van Pentakels",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>Op de afbeelding van deze kaart kan je zien dat de Page je een pentakel, een munt aanbiedt. Je kan dit beschouwen als een echt geschenk want de Page van Pentakels biedt je een geluksmunt die je kan inzetten waar en wanneer het nodig is. Deze kaart wijst dan ook op mogelijkheden, kansen en voorstellen.</p><p>Misschien zit je op dit moment in een fase waarin je de dingen die je doet niet meer echt boeiend vindt. Integendeel, je verveelt je misschien zelfs, je zit een stuk in een sleur. Probeer om niet toe te geven aan deze gevoelens maar je op te trekken aan het feit dat er ergens, diep binnenin, nog een bron van energie verborgen zit. Nu is het de juiste tijd om die bron aan te boren en te gebruiken!</p><p>Keer terug naar je oorspronkelijke doelen, naar de basis waar je vandaan komt en concentreer je daarop. Eens je je weer herinnert waar je naar op weg was, zal je energie terug beginnen stromen en zal je die doelen ook weer kunnen bereiken. Het is nu eenmaal zo dat je niets kan bereiken als je niet investeert, en jouw belangrijkste kapitaal is nu energie.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je een vaste partner hebt is het mogelijk dat je tegenwoordig het gevoel hebt dat er alsmaar minder avontuur en passie in je relatie te vinden is. De intense gevoelens die jullie hadden in het begin van de relatie lijken op dit moment ver weg, bijna onherkenbaar. De magie is er niet meer, zo voelt het. Ga je nu niet verbergen of je gevoelens negeren want dan maak je het alleen maar erger. Vraag je partner om samen te gaan zitten en vertel hem/haar wat je voelt. Zo maak je het begin van een oplossing mogelijk, door open communicatie. Het is trouwens best mogelijk dat je partner last heeft van dezelfde gevoelens als jij maar er niet over durfde te beginnen.</p><p>Als je op zoek bent naar een partner dan zit je waarschijnlijk een beetje vast, heb je het gevoel alsof al je inspanningen om de liefde te vinden geen resultaat boeken, je bent en komt nergens, zo voelt het. Neem nu de tijd om diep in je hart te kijken en je af te vragen of er misschien nog iets zit wat je tegenhoudt: is het mogelijk dat je nog gevoelens hebt voor je vorige partner? Ben je er helemaal zeker van dat je nu echt een relatie wil? Stel jezelf een aantal (moeilijke) vragen want de antwoorden hierop zullen je helpen om de juiste weg te vinden naar een nieuwe partner.</p>",
                        [GuidanceFields.WORK] : "<p>De Page van Pentakels vertelt je dat er iets niet helemaal goed voelt op je werk. Je zit op dit ogenblik met een pak taken die uitgevoerd moeten worden, maar op een of andere manier vind je het enthousiasme en de energie niet om er volop voor te gaan. Je vindt het moeilijk om je echt te engageren, je werk interesseert je een stuk minder dan vroeger het geval was. Ook al zou je dit probleem waarschijnlijk best wel alleen kunnen oplossen, nu is het even beter om de hulp van je omgeving in te roepen. Er zijn een pak mensen om je heen die je willen helpen en ondersteunen en die je net dat zetje kunnen geven dat je nodig hebt om hier doorheen te geraken. Laat ‘samen sterk’ je motto zijn!</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Als je denkt aan werken aan je spirituele groei dan lijkt dit niet meer zo’n fijn en uitdagend avontuur te zijn als het vroeger was. Op één of andere manier ben je ergens onderweg je motivatie, je motor kwijtgeraakt. Om dat vroegere enthousiasme terug te vinden zou je jezelf een aantal vragen kunnen stellen: wat interesseert je echt? Bij welk onderwerp of thema denk je onmiddellijk dat je er meer van wil weten? Op welke vragen zou je absoluut het antwoord willen vinden? Als je een aantal antwoorden gevonden hebt dan kan je gericht op zoek gaan naar kennis. Maak het jezelf niet te gemakkelijk, ga overal zoeken, tot in de kleinste hoekjes en gaatjes, op ongewone of misschien zelfs een beetje beangstigende plaatsen. Je zal merken dat je tijdens deze zoektocht je oude energie en vuur weer terugvindt, dat je je spirituele tank terug kan vullen.</p>",
                    }
                }
            },
            {
                filename: "75",
                nl : {
                    name: "Ridder van Pentakels",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>De Ridder van Pentakels is een aardse kaart, de ridder heeft zijn voeten stevig op de grond staan. Deze kaart gaat over realisme, vastberadenheid, betrouwbaarheid, stevigheid. Ze vertelt je ook hoe belangrijk het is en blijft om goed te zorgen voor de kleine dingen, wat niet opvalt heeft ook zorg en aandacht nodig. Als je deze houding volhoudt en je aandacht richt op de details dan zal je merken dat de grotere dingen als het ware voor zichzelf gaan zorgen. Als je deze kaart trekt dan is het de juiste tijd om je af te vragen of alle onderdelen van je leven goed georganiseerd zijn. Als dat niet het geval is, maak er dan nu werk van, breng orde in de chaos en blijf die orde dan ook onderhouden.</p><p>Natuurlijk is het belangrijk dat je hier niet te ver in gaat omdat je dan het risico loopt dat je star en te gestructureerd wordt, zonder ruimte voor improvisatie en spontaniteit.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Als je op dit moment een liefdesrelatie hebt dan is het nu een goede tijd om je af te vragen hoe verantwoordelijk jij je gedraagt in deze relatie. Neem je voldoende taken op je, draag je jouw deel van het gewicht, werk je even hard als je partner als het op het oplossen van conflicten aankomt? Kortom, zijn de inspanningen evenredig verdeeld? Of probeer je gewoon zoveel mogelijk van de leuke momenten te genieten en negeer je de rest? Eens je deze vragen beantwoord hebt, kan je beslissen of je je wil gaan aanpassen om alles in evenwicht te krijgen of dat je daar de energie en motivatie niet voor hebt en je dus eigenlijk uit deze relatie wil stappen.</p><p>Als je op zoek bent naar liefde dan vertelt deze kaart je dat je een beetje vastzit in een bepaald patroon. Probeer de manier waarop je je zoektocht aanpakt eens wat te veranderen, probeer nieuwe dingen uit, ga op andere plaatsen zoeken. En ook: kijk eens kritisch naar je verwachtingspatroon, wat voor partner wil je vinden, aan welke voorwaarden moet die persoon voldoen? Is het mogelijk dat je teveel vraagt? Zelfs een aantal kleine aanpassingen kan ervoor zorgen dat je er wel in slaagt om iemand te vinden.</p>",
                        [GuidanceFields.WORK] : "<p>Deze kaart vertelt je dat het in deze periode belangrijk is dat je betrouwbaar en voorspelbaar bent, dat is waar de mensen om je heen (een ook jij zelf) behoefte aan hebben. Verander op dit moment niets aan de manier waarop je de dingen aanpakt, stel tradities niet in vraag, houd je aan de gewoontes die de ware basis vormen van je werk.</p><p>Als je op zoek bent naar werk wees je er dan van bewust dat de mensen die je ontmoet op dit moment niet op zoek zijn naar een vernieuwer, een avontuurlijk of onvoorspelbaar type. Ze hebben eerder behoefte aan iemand die betrouwbaar is, iemand op wie ze kunnen rekenen. Stel je ook zo op en je zal merken dat je zoektocht succesvoller verloopt.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>In dit hoofdstuk van je leven investeer je veel energie in het behouden van de situatie zoals ze is, je richt je vooral op het organiseren van en zorgen voor de dingen. Daarom is het eigenlijk ook niet zo’n goede tijd om je teveel bezig te houden met het spirituele. Je hebt misschien het gevoel dat je al je tijd en energie stopt in het structureren van je dagelijkse leven maar tegelijkertijd ben je op een natuurlijke manier ook spiritueel aan het groeien, het gaat bijna vanzelf, je hoeft er geen extra aandacht aan te besteden. Het is zelfs zo dat als je je teveel zou focussen op je spirituele groei je enkel verward zou worden, misschien zelfs overweldigd door alle opties. Houd je dus niet teveel bezig met het spirituele, sta gewoon volop in de dagelijkse realiteit en richt je op positieve, constructieve doelen. De rest volgt vanzelf.</p>",
                    }
                }
            },
            {
                filename: "76",
                nl : {
                    name: "Koningin van Pentakels",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>De Koningin van Pentakels heeft als onderwerp je goed voelen bij het vrouwelijke, het aardse. De kaart symboliseert ruimhartigheid, evenwicht, stabiliteit, een positieve instelling. De vrouw op de kaart straalt zachtheid en openheid uit, ze wordt omringd door bloemen en planten, door overvloed. Ze geniet van de kleine vreugdes in het leven en ze nodigt je uit om dat ook te gaan doen. Richt je op het positieve in jezelf, bekijk de wereld met een roze bril en dompel je onder in levensvreugde. Op dit moment straal je waarschijnlijk een gevoel van welzijn uit. Daardoor zullen mensen je ook benaderen voor advies en troost. Probeer hen hierin tegemoet te komen, maar natuurlijk niet ten koste van je eigen geluk. Ga je niet onderdompelen in de problemen van anderen, jouw welzijn gaat voor.</p><p>Deze kaart nodigt je ook uit om extra aandacht te besteden aan de aardse aspecten van het leven; kook eens een uitgebreide maaltijd, ga eens aan de slag in je tuin, maak een lange wandeling, geniet hiervan met volle teugen!</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Deze kaart wijst op vreugde en voldoening in je relatie. Probeer om nu zoveel mogelijk kwaliteitstijd met je geliefden door te brengen. Ontspan je, wees je ten volle bewust van het feit dat je geliefd bent, veilig, gevoed. Zelfs al ben je een behoorlijk onafhankelijk type en stel je je niet gemakkelijk open, probeer toch om de mensen waar je van houdt net iets meer ruimte te geven dan gewoonlijk, je zal ervan genieten!</p><p>De Koningin van Pentakels symboliseert ook groei, het is een goede tijd om nieuwe mensen te leren kennen. Mogelijk komt er zelfs een zwangerschap in je nabije omgeving.</p><p>Als je op zoek bent naar een partner ga dan nu niet jagen, probeer niet om indruk te maken. Wees gewoon jezelf, sta open voor nieuwe ervaringen, toon de mensen wie je bent en wat je te bieden hebt, dat is ruim voldoende! Als je je op deze manier opstelt zal je merken dat mensen vanzelf naar je toe komen, jagen zal niet nodig zijn.</p>",
                        [GuidanceFields.WORK] : "<p>Het is verstandig om nu te gaan focussen op ‘vrouwelijke’ waarden zoals creativiteit, betrouwbaarheid, uithoudingsvermogen. Tegelijk is het ook belangrijk om van je werkplek een vertrouwde, warme plaats te gaan maken. Zet wat foto’s neer, voorwerpen die je koestert en zorg er zo voor dat je je ook op het werk helemaal thuis voelt. Je zal merken dat dit de kwaliteit van je werk een stuk verbetert. Je zal natuurlijk niet ineens spectaculaire successen boeken maar je zal merken dat je je evenwichtiger, harmonischer voelt tijdens het werken.</p><p> Neem ook de tijd om van het leven te genieten, ook al kost dat misschien wat geld. Het is best goed om geld uit te geven als je levenskwaliteit daardoor enorm verbetert en die verbetering is nu heel belangrijk.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Wat je spirituele leven betreft is het op dit moment goed om je te concentreren op jezelf, op wat jij gelooft, jouw filosofie, jouw normen en waarden. Je hebt er momenteel alle vertrouwen in dat je op de goede weg zit, dus ga niet twijfelen, je weet wat je te doen staat, ga gewoon door zoals je bezig bent. Je zit nu al enige tijd op een bepaalde spirituele weg en voorlopig is dit absoluut de juiste voor jou!</p><p>Het maakt niet uit wat anderen daarvan vinden en of ze het ermee eens zijn. Waar het om gaat is dat jij gelukkig bent met wie je bent en met de beslissingen die je neemt, het gaat hier om jou.</p>",
                    }
                }
            },
            {
                filename: "77",
                nl : {
                    name: "Koning van Pentakels",
                    description: {
                        [GuidanceFields.DEFAULT] : "<p>De Koning van Pentakels richt zich op de meer ‘mannelijke’ waarden zoals traditie, autoriteit, realiteitszin, duurzaamheid, het verwerven van bezittingen. Deze kaart heeft het niet enkel over woorden en gevoelens, maar ook over feiten en de dingen die je bereikt hebt. De Koning van Pentakels staat met beide voeten stevig in de realiteit van alledag en hij zegt dat daden luider praten dan woorden. Mensen luisteren niet zozeer naar wat je zegt, ze kijken eerder naar wat je doet.</p><p>Maar let op, er zit hier een addertje onder het gras: als je te ver doorslaat in de richting die deze kaart je aanwijst dan loop je het risico dat je verdwaalt in je passies, je hartstochten. Dan neig je naar het obsessieve en heb je enkel nog oog voor de fysieke kant van de dingen.</p>",
                        [GuidanceFields.RELATIONSHIP] : "<p>Wat relaties betreft focust deze kaart vooral op trouw en vriendschap. Als je een liefdesrelatie hebt dan heb je op dit moment waarschijnlijk een heel comfortabel gevoel, ben je ontspannen en rustig. Toch is het best mogelijk dat dit niet voldoende is voor jou, dat je verlangt, zelfs hongert naar meer passie en diepere intimiteit. Je wil je relatie ook lichamelijk ten volle beleven. Het is nu aan jou om dit duidelijk te maken aan je partner, om open te zijn over je verlangens en behoeftes.</p><p>Als je deze kaart trekt dan kan het er ook op wijzen dat je jaloers bent. Als dit het geval is, praat er dan over, laat het niet over aan je partner om te gokken wat er aan de hand is in je hoofd en je hart.</p><p>Als je alleen bent dan is het nu de perfecte tijd om iemand te ontmoeten die vol hartstocht naar je toe komt. Probeer je niet aan te passen aan die persoon maar wees gewoon jezelf. Het is net omdat jij ‘gewoon’ jezelf bent dat deze persoon zo passioneel is. Als je probeert te veranderen zou die passie zomaar kunnen verdwijnen. Ook al vind jij jezelf maar gewoontjes, voor die ander ben je blijkbaar heel speciaal!</p>",
                        [GuidanceFields.WORK] : "<p>Op het werk streef je op dit moment waarschijnlijk naar zekerheid, vastigheid. Dat zijn absoluut zinvolle doelen maar let er toch op dat je niet te streng en te strak wordt, dat je niet gaat vastzitten in je gewoontes en routines. Werken is iets dynamisch, elke dag hoor je je een beetje aan te passen aan veranderende omstandigheden, mee te groeien met behoeftes en eisen.</p><p>Waarschijnlijk zijn je meerderen behoorlijk geïnteresseerd in wat je doet en hoe je het doet, maar het is mogelijk dat ze af en toe een beetje te snel oordelen. Het is dus aan jou om ervoor te zorgen dat je werk niet bekritiseerd kan worden, dat alles altijd helemaal in orde is. Wees hierbij wel realistisch bij het inschatten van wat je wel en niet aankan.</p>",
                        [GuidanceFields.SPIRITUAL] : "<p>Waarschijnlijk was je in de voorbije periode eerder gefocust op de materiele kant van het leven, dit kan al een tijdje het geval zijn. Misschien heb je gewoon niet de tijd genomen om na te denken over spiritualiteit en wat dit zou kunnen betekenen voor jou. Breng daar nu verandering in, doe wat moeite, verdiep je in de meer spirituele, ontastbare kant van het leven. Je zal merken dat je diep vanbinnen echt wel een spirituele kern hebt. Beschouw dit gewoon als een van je projecten: vergaar kennis en informatie, bestudeer een aantal manieren om spiritualiteit te beleven en kies dan die manier die voor jou het meest natuurlijk aanvoelt. Enkel jij weet wat spiritualiteit voor jou betekent.</p>",
                    }
                }
            }
        ]
    }
}

_deckCards[DeckTypes.TAROT_MAYOR_ARCANA] = {
    cardDirectories : _deckCards[DeckTypes.TAROT].cardDirectories,
    cards: _deckCards[DeckTypes.TAROT].cards.slice(0, 21)
}

export const DeckCards = _deckCards;