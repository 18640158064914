
import update from 'immutability-helper';
import { ActionTypes } from '../Constants';

export default function ioReducer(
    state,
    action
) {
    switch (action.type) {
        case ActionTypes.SETIDENTIFIER:
        return update(state, {
            identifier: { $set: action.identifier }
        });
        default:
            return state || {};
    }
}