import React, { Component } from 'react';
import { connect } from 'react-redux';
import T from 'prop-types';

class CardSlot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMouseOver: false
        }
      }

    onMouseOver = () => { this.setState({ isMouseOver: true }) }
    onMouseOut = () => { this.setState({ isMouseOver: false }) }

    render() {
        const { isMouseOver } = this.state;
        let borderRadius = this.props.cardWidth / 30;
        return (
            <div>
                <h2 style={{
                    position : 'absolute',
                    left : this.props.x - this.props.cardWidth / 2,
                    width : this.props.cardWidth,
                    top : this.props.y - this.props.cardHeight / 2 - this.props.headerHeight,
                    margin : 0,
                    textAlign: 'center',
                    fontWeight: 500,
                    // fontFamily : 'CardFont',
                    fontSize: this.props.cardHeight / 16,
                    color : '#222222',
                    transition: '0.6s'
                }}>{this.props.title}</h2>
                <div
                onMouseOver={this.onMouseOver}
                onMouseOut={this.onMouseOut}

                style={{
                    position : 'absolute',
                    borderRadius : borderRadius, 
                    background : '#d2eaef',
                    opacity: 0.5,
                    top : this.props.y - this.props.cardHeight * 0.4,
                    left : this.props.x - this.props.cardWidth * 0.4,
                    width : this.props.cardWidth * 0.8,
                    height: this.props.cardHeight * 0.8,
                    transform: `rotate(${this.props.rotation}rad)`,
                    border : "1px solid #666",
                    boxSizing : 'border-box',
                    transition: '0.6s'
                }}            
                ></div>
            </div>
        );
    }    
}

CardSlot.propTypes = {
  title: T.string,
  x: T.number,
  y: T.number,

  rotation: T.number,

  cardWidth: T.number,
  cardHeight: T.number,
  headerHeight : T.number
};

export default CardSlot;