import { ActionTypes } from '../Constants/ActionTypes';

export const chooseCard = (card, silent) => ({
    type: ActionTypes.CHOOSE_CARD,
    silent : silent,
    card: card
});

export const turnCard = (card, silent) => ({
    type: ActionTypes.TURN_CARD,
    silent : silent,
    card: card
});

export const turnCards = (silent) => ({
    type: ActionTypes.TURN_CARDS,  
    silent : silent,  
});

export const returnCard = (card, silent) => ({
    type: ActionTypes.RETURN_CARD,
    silent : silent,
    card: card
});

export const chooseSpread = (spread, silent) => ({
    type: ActionTypes.CHOOSE_SPREAD,
    silent : silent,
    spread : spread
});

export const shuffleCards = (silent) => ({
    type: ActionTypes.SHUFFLE, 
    silent : silent,   
});

export const redraw = (silent) => ({
    type: ActionTypes.REDRAW,
    silent : silent  
});

export const showInformation = (card, autoShow, silent) => ({
    type : ActionTypes.SHOWINFO,
    silent : silent,
    card : card,
    autoShow : autoShow
});

export const setIdentifier = (id) => ({
    type : ActionTypes.SETIDENTIFIER,
    identifier : id
})