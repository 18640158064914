import React, { Component } from 'react';
import { connect } from 'react-redux';
import T from 'prop-types';

import CardSlot from '../Display/CardSlot';

class Spread extends Component {
    render() {
        let cardslots = [];

        if (this.props.spread !== null) {
            let padding = this.props.height > this.props.width ? this.props.width * 0.1 : this.props.height * 0.1;

            let spreadWidth = this.props.spread.width;
            let spreadHeight = this.props.spread.height;

            //calculate ratios
            let fullWidth = this.props.width - 2 * padding;
            let fullHeight = this.props.height - 2 * padding;

            let factorX = spreadWidth / fullWidth;
            let factorY = spreadHeight / fullHeight;
            let factor;

            let left = this.props.x + padding;
            let top = this.props.y + padding;

            if (factorX < factorY) {
                //horizontal bars
                factor = factorY;
                left += (fullWidth - spreadWidth / factor) / 2;
            } else {
                //vertical bars
                factor = factorX;
                top += (fullHeight - spreadHeight / factor) / 2;
            }

            this.props.spread.positions.forEach((p, i) => {
                cardslots.push(<CardSlot
                    title={this.props.hideTitle ? "" : p.title.nl}
                    key={i}
                    x={left + p.centerX / factor}
                    y={top + p.centerY / factor}
                    rotation={p.rotation}
                    headerHeight={50 / factor}
                    cardWidth={this.props.spread.cardWidth / factor}
                    cardHeight={this.props.spread.cardHeight / factor} />)
            });
        }

        return (
            <div>
                {cardslots}
            </div>
        );
    }
}

Spread.propTypes = {
    x: T.number,
    y: T.number,
    width: T.number,
    height: T.number,
    hideTitle : T.bool,
    spread: T.any
}

export default Spread;